import styled from 'styled-components';
import css from '@styled-system/css';

export const Card = styled.div({
	backgroundColor: '#fff',
	overflow: 'hidden',
	borderRadius: 6,
	border: '1px solid #f3f3f3',
	display: 'flex',
	flexDirection: 'column',
	transition: '0.3s ease-in-out',
	cursor: 'pointer',

	':hover': {
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.08)',
		transform: 'translateY(-5px)',
	},
});
export const ImageWrapper = styled.div({
	// height: 290,
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexGrow: 1,
	overflow: 'hidden',

	'@media screen and (max-width: 1280px)': {
		height: 250,
	},

	'@media screen and (max-width: 560px)': {
		height: 180,
	},
});
export const Image = styled.img({
	maxWidth: '100%',
	maxHeight: '100%',
	height: 'auto',
});
export const Discount = styled.div(
	css({
		position: 'absolute',
		top: '1rem',
		right: '1rem',
		backgroundColor: 'primary.regular',
		color: '#fff',
		overflow: 'hidden',
		padding: '0.25rem 0.5rem',
		fontSize: 12,
		borderRadius: 6,
		pointerEvents: 'none',
	}),
);
export const Title = styled.h2({
	marginBottom: 10,
	color: '#999',
	fontSize: 14,
	fontWeight: 'normal',
});

export const PriceWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginBottom: 10,
});

export const Price = styled.span(
	css({
		color: 'text.bold',
		fontSize: 18,
		fontWeight: 'semiBold',
		lineHeight: 1,
	}),
);

export const SalePrice = styled.span(
	css({
		color: 'text.regular',
		fontSize: 15,
		lineHeight: 1,
		fontWeight: 'regular',
		padding: '0 5px',
		overflow: 'hidden',
		position: 'relative',
		marginLeft: 10,
		display: 'flex',
		alignItems: 'center',

		':before': {
			content: '""',
			width: '100%',
			height: 1,
			display: 'inline-block',
			backgroundColor: 'text.regular',
			position: 'absolute',
			top: '50%',
			left: 0,
		},
	}),
);
