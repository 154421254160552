/************ CONSTANTS ***********/
export const CURRENCY = '₺';
export const PHONE_NUMBER_MASK = [
	'(',
	/[1-9]/,
	/\d/,
	/\d/,
	')',
	' ',
	/\d/,
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];
