import styled from 'styled-components';
export const ContactUsSection = styled.section`
	background-color: #fff;
	overflow-x: hidden;
	@media (max-width: 992px) {
		margin-bottom: 30px;
	}
	@media (min-width: 768px) {
		padding: 90px 0;
	}
	@media (min-width: 992px) {
		padding: 120px 0;
	}
`;
export const ContactUsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 80rem;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 2rem;
	@media (min-width: 576px) {
		flex-direction: row;
	}
`;
export const ContactInformationWrapper = styled.div`
	width: 100%;
	order: 1;
	padding: 1.25rem;
	flex-shrink: 0;
	background-color: #f3f4f6;
	@media (min-width: 768px) {
		width: 18rem;
	}
	@media (min-width: 1024px) {
		width: 24rem;
	}
`;
export const ContactInformationImageContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	margin-bottom: 2rem;
	img {
		width: 100%;
		height: auto;
	}
`;
export const ContactInformationCard = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
	span:first-child {
		font-weight: 600;
		margin-bottom: 0.75rem;
	}
	span:last-child {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}
	.social-links {
		display: flex;
		align-items: center;
		justify-content: start;
		a {
			color: rgba(156, 163, 175, 1);
			margin-right: 2rem;
		}
		a:last-child {
			margin-right: 0;
		}
	}
`;

export const ContactFormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	order: 1;
	width: 100%;
	padding: 2rem;
	background-color: #f3f4f6;
	@media (min-width: 768px) {
		order: 2;
		margin-left: 1.75rem;
	}
	@media (min-width: 768px) {
		margin-left: 2.25rem;
	}
	h1 {
		color: rgba(31, 41, 55, 1);
		font-size: 1.25rem;
		line-height: 1.75rem;
		margin-bottom: 1.75rem;
		@media (min-width: 768px) {
			font-size: 1.5rem;
			line-height: 2rem;
		}
	}
	.field-wrapper {
		display: flex;
		flex-direction: column;
	}
`;
export const ContactFromGrid = styled.div`
	display: grid;
	gap: 1.5rem;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	@media (min-width: 640px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
`;
export const ContactUsStyledError = styled.div`
	color: red;
	padding-bottom: 10px;
	margin-top: -5px;
`;
