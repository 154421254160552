import {AboutCard} from '../../components/homepage/about-card/about-card';
import {TripleCard} from '../../components/homepage/triple-card/triple-card';
import {StepsCard} from '../../components/homepage/steps-card/steps-card';
import {OfferSection} from '../../assets/styles/pages.style';
import Carousel from 'components/carousel/carousel';
import OfferOne from 'assets/images/promotion/offer-1.png';
import OfferThree from 'assets/images/promotion/offer-3.png';
import OfferTwo from 'assets/images/promotion/offer-2.png';
import CategoryCarousel from '../../components/carousel/CategoryCarousel';
const siteOffers = [
	{
		id: '1',
		imgSrc: OfferOne,
		alt: 'Offer 1',
	},
	{
		id: '2',
		imgSrc: OfferTwo,
		alt: 'Offer 2',
	},
	{
		id: '3',
		imgSrc: OfferThree,
		alt: 'Offer 3',
	},
];

export const HomePage = ({deviceType}) => {
	return (
		<>
			<CategoryCarousel />
			<OfferSection>
				<div style={{margin: '0 -10px'}}>
					<Carousel deviceType={deviceType} data={siteOffers} />
				</div>
			</OfferSection>
			<AboutCard />
			<TripleCard />
			<StepsCard />
		</>
	);
};
