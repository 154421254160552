import React from 'react';
import ReactSelect from 'react-select';
import SelectStyle, {InputFeedback} from './select.style';
// type SelectProps = {
//     className?: string;
//     labelText?: string;
//     as?: string;
//     name?: string;
//     value?: any;
//     labelPosition?: 'top' | 'bottom' | 'left' | 'right';
//     props?: any;
// };

const Select = ({className, labelText, labelPosition, error, ...props}) => {
	// Add all classes to an array
	const addAllClasses = ['yikamayeri__select'];

	// Add label position class
	if (labelPosition) {
		addAllClasses.push(`label_${labelPosition}`);
	}

	// className prop checking
	if (className) {
		addAllClasses.push(className);
	}

	const LabelField = labelText && (
		<span className="yikamayeri__field-label">{labelText}</span>
	);
	const position = labelPosition || 'top';
	return (
		<SelectStyle className={addAllClasses.join(' ')}>
			{position === 'left' || position === 'right' || position === 'top'
				? LabelField
				: ''}

			<ReactSelect
				className="select-field__wrapper"
				classNamePrefix="select"
				{...props}
			/>
			{position === 'bottom' && LabelField}
			{error && <InputFeedback>{error}</InputFeedback>}
		</SelectStyle>
	);
};

Select.defaultProps = {
	as: 'div',
	labelPosition: 'top',
};

export default Select;
