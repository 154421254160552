import React, {useContext, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {ErrorMessage, Form, Formik} from 'formik';
import {closeModal} from '@redq/reuse-modal';
import {ProfileContext} from '../../contexts/profile/profile.context';
import {FieldWrapper, Heading} from './address-card.style';
import TextField from '../forms/text-field';
import {Button} from '../button/button';
import useUser from '../../services/use-user';
import {toast} from 'react-toastify';
import {Phone} from '../../models/Phone';
import useAddress from '../../services/use-address';
import Select from '../select/select';
import {StyledError} from '../contact-card/contact-card.style';

const AddressValidationSchema = Yup.object().shape({
	title: Yup.string().required('Başlık gereklidir'),
	province: Yup.object().nullable().required('İl seçimi gereklidir'),
	district: Yup.object().nullable().required('İlçe seçimi gereklidir.'),
	neighbourhood: Yup.object().nullable().required('Mahalle seçimi gereklidir.'),
	address: Yup.string().required('Adres gereklidir.'),
});

const UpdateAddress = ({
	isValid,
	item,
	values,
	touched,
	errors,
	dirty,
	handleChange,
	handleBlur,
	handleReset,
	isSubmitting,
}) => {
	const initialValues = {
		id: item.id || null,
		title: item.title || '',
		province:
			item.province && item.province.key
				? {value: item.province.key, label: item.province.value}
				: null,
		district:
			item.district && item.district.key
				? {value: item.district.key, label: item.district.value}
				: null,
		neighbourhood:
			item.neighbourhood && item.neighbourhood.key
				? {value: item.neighbourhood.key, label: item.neighbourhood.value}
				: null,
		address: item.address || '',
		zip: item.zip || '',
	};

	const [districtDisabled, setDistrictDisabled] = useState(true);
	const [neighbourhoodDisabled, setNeighbourhoodDisabled] = useState(true);

	const {state, dispatch} = useContext(ProfileContext);

	const userService = useUser();
	const addressService = useAddress();

	useEffect(() => {
		dispatch({
			type: 'SET_PROVINCE_LOADING',
			payload: true,
		});
		addressService
			.provinces()
			.then(
				res => {
					if (res.data && res.data.length > 0) {
						dispatch({
							type: 'SET_PROVINCE_DATA',
							payload: res.data,
						});
					} else {
						dispatch({
							type: 'SET_PROVINCE_DATA',
							payload: [],
						});
					}
				},
				() => {
					dispatch({
						type: 'SET_PROVINCE_DATA',
						payload: [],
					});
				},
			)
			.then(() => {
				dispatch({
					type: 'SET_PROVINCE_LOADING',
					payload: false,
				});
			});
		if (
			initialValues.id ||
			initialValues.province ||
			initialValues.district ||
			initialValues.district ||
			initialValues.neighbourhood
		) {
			fetchDistricts(initialValues.province);
			fetchNeighbourhoods(initialValues.district);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchDistricts = province => {
		setDistrictDisabled(true);
		dispatch({
			type: 'SET_DISTRICT_LOADING',
			payload: true,
		});
		addressService
			.districts(province.value)
			.then(
				res => {
					if (res.data && res.data.length > 0) {
						dispatch({
							type: 'SET_DISTRICT_DATA',
							payload: res.data,
						});
						setDistrictDisabled(false);
					} else {
						dispatch({
							type: 'SET_DISTRICT_DATA',
							payload: [],
						});
						setDistrictDisabled(true);
					}
				},
				() => {
					dispatch({
						type: 'SET_DISTRICT_DATA',
						payload: [],
					});
					setDistrictDisabled(true);
				},
			)
			.then(() => {
				dispatch({
					type: 'SET_DISTRICT_LOADING',
					payload: false,
				});
			});
	};
	const fetchNeighbourhoods = district => {
		setNeighbourhoodDisabled(true);
		dispatch({
			type: 'SET_NEIGHBOURHOOD_LOADING',
			payload: true,
		});
		addressService
			.neighbourhoods(district.value)
			.then(
				res => {
					if (res.data && res.data.length > 0) {
						dispatch({
							type: 'SET_NEIGHBOURHOOD_DATA',
							payload: res.data,
						});
						setNeighbourhoodDisabled(false);
					} else {
						dispatch({
							type: 'SET_NEIGHBOURHOOD_DATA',
							payload: [],
						});
						setNeighbourhoodDisabled(true);
					}
				},
				() => {
					dispatch({
						type: 'SET_NEIGHBOURHOOD_DATA',
						payload: [],
					});
					setNeighbourhoodDisabled(true);
				},
			)
			.then(() => {
				dispatch({
					type: 'SET_NEIGHBOURHOOD_LOADING',
					payload: false,
				});
			});
	};
	const handleSubmit = async (values, {setSubmitting}) => {
		setSubmitting(true);
		if (initialValues.id) {
			userService
				.updateAddress(initialValues.id, {
					title: values.title,
					neighbourhood: values.neighbourhood.value
						? values.neighbourhood.value
						: '',
					address: values.address,
					zip: values.zip,
				})
				.then(
					res => {
						if (res.success) {
							dispatch({
								type: 'UPDATE_ADDRESS',
								payload: res.data,
							});
							if (item.onDone) {
								item.onDone();
							}
							toast(res.message, {type: 'info'});
						} else {
							toast(res.message, {type: 'error'});
						}
					},
					err => {
						toast(err.response.data.message, {type: 'error'});
					},
				);
		} else {
			userService
				.addAddress({
					title: values.title,
					neighbourhood: values.neighbourhood ? values.neighbourhood.value : '',
					address: values.address,
					zip: values.zip,
				})
				.then(
					res => {
						if (res.success) {
							const phoneItem = new Phone(res.data);
							dispatch({
								type: 'ADD_ADDRESS',
								payload: phoneItem,
							});
							if (res.data.default) {
								dispatch({
									type: 'SET_PRIMARY_ADDRESS',
									payload: res.data.id,
								});
							}
							closeModal();
							if (item.onDone) {
								item.onDone();
							}
							toast(res.message, {type: 'info'});
						} else {
							toast(res.message, {type: 'error'});
						}
					},
					err => {
						toast(err.response.data.message, {type: 'error'});
					},
				)
				.then(() => {
					setSubmitting(false);
				});
		}
	};
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={AddressValidationSchema}
		>
			{({
				values,
				handleChange,
				handleBlur,
				isSubmitting,
				setFieldValue,
				setFieldTouched,
			}) => (
				<Form>
					<Heading>
						{item && item.id ? 'Adresi Düzenle' : 'Yeni Adres Ekle'}
					</Heading>
					<FieldWrapper>
						<TextField
							name="title"
							guide={false}
							id="title"
							type="text"
							placeholder="Lütfen başlık giriniz"
							value={values.title}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</FieldWrapper>
					<ErrorMessage name="title" component={StyledError} />
					<FieldWrapper>
						<Select
							name="province"
							guide={false}
							id="province"
							type="select"
							value={values.province}
							placeholder="Lütfen il seçiniz"
							onChange={param => {
								setFieldValue('province', param);
								setFieldValue('district', null);
								setFieldValue('neighbourhood', null);
								fetchDistricts(param);
							}}
							onBlur={() => setFieldTouched('province', true)}
							options={state.address_provinces.data.map(item => ({
								value: item.id,
								label: item.title,
							}))}
						/>
					</FieldWrapper>
					<ErrorMessage name="province" component={StyledError} />
					<FieldWrapper>
						<Select
							name="district"
							guide={false}
							id="district"
							type="select"
							value={values.district}
							isDisabled={districtDisabled}
							placeholder="Lütfen ilçe seçiniz"
							onChange={param => {
								setFieldValue('district', param);
								setFieldValue('neighbourhood', null);
								fetchNeighbourhoods(param);
							}}
							onBlur={() => setFieldTouched('district', true)}
							options={state.address_districts.data.map(item => ({
								value: item.id,
								label: item.title,
							}))}
						/>
					</FieldWrapper>
					<ErrorMessage name="district" component={StyledError} />
					<FieldWrapper>
						<Select
							name="neighbourhood"
							guide={false}
							type="select"
							value={values.neighbourhood}
							isDisabled={neighbourhoodDisabled}
							id="neighbourhoods"
							placeholder="Lütfen mahalle seçiniz"
							onChange={param => setFieldValue('neighbourhood', param)}
							onBlur={() => setFieldTouched('neighbourhood', true)}
							options={state.address_neighbourhoods.data.map(item => ({
								value: item.id,
								label: item.title,
							}))}
						/>
					</FieldWrapper>
					<ErrorMessage name="neighbourhood" component={StyledError} />
					<FieldWrapper>
						<TextField
							name="address"
							id="address"
							as="textarea"
							placeholder="Lütfen adresinizi giriniz"
							value={values.address}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</FieldWrapper>
					<ErrorMessage name="address" component={StyledError} />

					<Button
						disabled={isSubmitting}
						type="submit"
						style={{width: '100%', height: '44px'}}
					>
						Kaydet
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default UpdateAddress;
