import React, {useContext} from 'react';
import * as Yup from 'yup';
import {closeModal} from '@redq/reuse-modal';
import {ErrorMessage, Formik, Form} from 'formik';
import MaskedInput from 'react-text-mask';
import {ProfileContext} from 'contexts/profile/profile.context';
import {Button} from 'components/button/button';
import {
	FieldWrapper,
	Heading,
	StyledInput,
	StyledError,
} from './contact-card.style';
import useUser from 'services/use-user';
import {Phone} from 'models/Phone';
import {Input} from '../forms/input';
import {toast} from 'react-toastify';
import {handleModal} from '../../features/checkouts/checkout-modal';
import {PhoneVerification} from '../../features/phone-verification/phone-verification';
import {PHONE_NUMBER_MASK} from '../../utils/constant';

const CreatePhoneValidationSchema = Yup.object().shape({
	title: Yup.string().required('Başlık gereklidir'),
	phone: Yup.string().required('Telefon numarası gereklidir.'),
});
const UpdatePhoneValidationSchema = Yup.object().shape({
	title: Yup.string().required('Başlık gereklidir'),
});
const CreateOrUpdateContact = ({item}) => {
	const initialValues = {
		id: item.id || null,
		title: item.title || '',
		phone: item.phone || '',
	};

	const {dispatch} = useContext(ProfileContext);

	const userService = useUser();
	const handleSubmit = async (values, {setSubmitting}) => {
		setSubmitting(true);
		let phone = values.phone
			.replace('(', '')
			.replace(')', '')
			.replace(' ', '')
			.replace('-', '');
		if (initialValues.id) {
			userService
				.updatePhone(initialValues.id, {
					title: values.title,
				})
				.then(
					res => {
						if (res.success) {
							dispatch({
								type: 'UPDATE_PHONE',
								payload: res.data,
							});
							toast(res.message, {type: 'info'});
						} else {
							toast(res.message, {type: 'error'});
						}
					},
					err => {
						toast(err.response.data.message, {type: 'error'});
					},
				);
		} else {
			userService
				.addPhone({
					title: values.title,
					phone: phone,
				})
				.then(
					res => {
						if (res.success) {
							const item = new Phone(res.data);
							dispatch({
								type: 'ADD_PHONE',
								payload: item,
							});
							if (res.data.default) {
								dispatch({
									type: 'SET_PRIMARY_PHONE',
									payload: res.data.id,
								});
							}
							closeModal();
							toast(res.message, {type: 'info'});
							handleModal(PhoneVerification, {
								phone: item.phone,
								id: item.id,
							});
						} else {
							toast(res.message, {type: 'error'});
						}
					},
					err => {
						toast(err.response.data.message, {type: 'error'});
					},
				)
				.then(() => {
					setSubmitting(false);
				});
		}
	};
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={
				initialValues.id
					? UpdatePhoneValidationSchema
					: CreatePhoneValidationSchema
			}
		>
			{({values, handleChange, handleBlur, isSubmitting}) => (
				<Form>
					<Heading>
						{item && item.id
							? 'Telefon Numarasını Düzenle'
							: 'Telefon Numarası Ekle'}
					</Heading>
					<FieldWrapper>
						<Input
							className="form-control"
							placeholder="Lütfen başlık giriniz"
							guide={false}
							id="phone-title"
							value={values.title}
							onChange={handleChange}
							onBlur={handleBlur}
							name="title"
						></Input>
					</FieldWrapper>
					<ErrorMessage name="title" component={StyledError} />
					{initialValues.id ? null : (
						<>
							<FieldWrapper>
								<MaskedInput
									mask={PHONE_NUMBER_MASK}
									className="form-control"
									placeholder="Lütfen telefon numarası giriniz"
									guide={false}
									id="phone-number"
									value={values.phone}
									onChange={handleChange}
									onBlur={handleBlur}
									name="phone"
									render={(ref, props = {}) => (
										<StyledInput ref={ref} {...props} />
									)}
								/>
							</FieldWrapper>
							<ErrorMessage name="phone" component={StyledError} />
						</>
					)}

					<Button
						disabled={isSubmitting}
						type="submit"
						style={{width: '100%', height: '44px'}}
					>
						Kaydet
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default CreateOrUpdateContact;
