import React from 'react';
import {ItemInfo, Items, Multiplier, Price, Quantity} from './checkout.style';
import {CURRENCY} from 'utils/constant';

const CheckoutOrderItem = ({product}) => {
	const {id, quantity, title, name, m2, price, salePrice} = product;
	const displayPrice = salePrice ? salePrice : price;
	return (
		<Items key={id}>
			<Quantity>{quantity}</Quantity>
			<Multiplier>x</Multiplier>
			<ItemInfo>
				{name ? name : title} {m2 ? `| ${m2.label}` : ''}
			</ItemInfo>
			<Price>
				{((m2 ? m2.value * displayPrice : displayPrice) * quantity).toFixed(2)}{' '}
				{CURRENCY}
			</Price>
		</Items>
	);
};
export default CheckoutOrderItem;
