import React, {useContext, useEffect, useState} from 'react';
import {Button} from 'components/button/button';
import CheckoutWrapper, {
	CheckoutContainer,
	CheckoutInformation,
	CheckoutSubmit,
	CouponBoxWrapper,
	CouponCode,
	CouponInputBox,
	DeliverySchedule,
	HaveCoupon,
	InformationBox,
	RemoveCoupon,
	TermConditionLink,
	TermConditionText,
} from '../checkout.style';

import {ProfileContext} from 'contexts/profile/profile.context';
import {useCart} from 'contexts/cart/use-cart';
import Coupon from 'features/coupon/coupon';
import Schedules from 'features/schedule/schedule';
import Contact from 'features/contact/contact';
import Address from 'features/address/address';
import {Link, useHistory} from 'react-router-dom';
import {CHECKOUT_PAY_PAGE, TERMS_PAGE} from 'site-settings/site-navigation';
import CheckoutCartWrapper from '../checkout-card-wrapper';
import PaymentMethod from '../../payment-method/payment-method';

const CheckoutWithSidebar = () => {
	const [hasCoupon, setHasCoupon] = useState(false);
	const {state} = useContext(ProfileContext);
	const {removeCoupon, coupon, cartItemsCount, calculatePrice} = useCart();
	const [loading, setLoading] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const {address, phone, schedules, payment_methods} = state;
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [selectedSchedule, setSelectedSchedule] = useState(null);
	useEffect(() => {
		setSelectedSchedule(schedules.find(item => item.type === 'primary'));
	}, [schedules]);
	useEffect(() => {
		setSelectedPaymentMethod(
			payment_methods.find(item => item.type === 'primary'),
		);
	}, [payment_methods]);

	const history = useHistory();
	const handleSubmit = async () => {
		setLoading(true);
		if (isValid) {
			history.push(CHECKOUT_PAY_PAGE);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (
			calculatePrice() > 0 &&
			cartItemsCount > 0 &&
			address &&
			address.data &&
			address.data.length &&
			phone &&
			phone.data &&
			phone.data.length &&
			schedules.length &&
			payment_methods.length &&
			selectedPaymentMethod &&
			selectedSchedule
		) {
			setIsValid(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, cartItemsCount, selectedPaymentMethod, selectedSchedule]);

	return (
		<form>
			<CheckoutWrapper>
				<CheckoutContainer>
					<CheckoutInformation>
						{/* DeliveryAddress */}
						<InformationBox>
							<Address
								increment={true}
								flexStart={true}
								buttonProps={{
									variant: 'text',
									type: 'button',
									className: 'addButton',
								}}
								icon={true}
								checkMissingAddress={true}
							/>
						</InformationBox>

						{/* Contact number */}
						<InformationBox>
							<Contact
								increment={true}
								flexStart={true}
								buttonProps={{
									variant: 'text',
									type: 'button',
									className: 'addButton',
								}}
								icon={true}
							/>
						</InformationBox>

						{/* DeliverySchedule */}
						<InformationBox>
							<DeliverySchedule>
								<Schedules increment={true} />
							</DeliverySchedule>
						</InformationBox>

						{/* Payment Method */}
						<InformationBox>
							<PaymentMethod
								increment={true}
								flexStart={true}
								buttonProps={{
									variant: 'text',
									type: 'button',
									className: 'addButton',
								}}
								icon={true}
							/>
						</InformationBox>
						{/* PaymentOption */}

						<InformationBox className="paymentBox">
							{/* Coupon start */}
							{coupon ? (
								<CouponBoxWrapper>
									<CouponCode>
										Kupon Uygulandı
										<span>{coupon.code}</span>
										<RemoveCoupon
											onClick={e => {
												e.preventDefault();
												removeCoupon();
												setHasCoupon(false);
											}}
										>
											Kupon Kodunu Kaldır
										</RemoveCoupon>
									</CouponCode>
								</CouponBoxWrapper>
							) : (
								<CouponBoxWrapper>
									{!hasCoupon ? (
										<HaveCoupon onClick={() => setHasCoupon(prev => !prev)}>
											Kupon Kodunuz Var Mı?
										</HaveCoupon>
									) : (
										<>
											<CouponInputBox>
												<Coupon errorMsgFixed={true} className="normalCoupon" />
											</CouponInputBox>
										</>
									)}
								</CouponBoxWrapper>
							)}

							<TermConditionText>
								Satın almayı kabül ederek
								<Link to={TERMS_PAGE}>
									<TermConditionLink>sözleşmelerimizi</TermConditionLink>
								</Link>
								onaylamış olursunuz.
							</TermConditionText>

							{/* CheckoutSubmit */}
							<CheckoutSubmit>
								<Button
									type="button"
									onClick={handleSubmit}
									disabled={!isValid}
									size="big"
									loading={loading}
									style={{width: '100%'}}
								>
									Devam Et
								</Button>
							</CheckoutSubmit>
						</InformationBox>
					</CheckoutInformation>

					<CheckoutCartWrapper />
				</CheckoutContainer>
			</CheckoutWrapper>
		</form>
	);
};

export default CheckoutWithSidebar;
