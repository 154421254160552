export const RadiatorIcon = props => {
	return (
		<svg width="14.4" height="12" viewBox="0 0 60 60" {...props}>
			<g>
				<path
					d="M42,14c-1.654,0-3,1.346-3,3v26c0,1.654,1.346,3,3,3s3-1.346,3-3V17C45,15.346,43.654,14,42,14z M43,43
                        c0,0.552-0.448,1-1,1s-1-0.448-1-1V17c0-0.552,0.448-1,1-1s1,0.448,1,1V43z"
				/>
				<path
					d="M32,14c-1.654,0-3,1.346-3,3v26c0,1.654,1.346,3,3,3s3-1.346,3-3V17C35,15.346,33.654,14,32,14z M33,43
                        c0,0.552-0.448,1-1,1s-1-0.448-1-1V17c0-0.552,0.448-1,1-1s1,0.448,1,1V43z"
				/>
				<path
					d="M12,14c-1.654,0-3,1.346-3,3v26c0,1.654,1.346,3,3,3s3-1.346,3-3V17C15,15.346,13.654,14,12,14z M13,43
                        c0,0.552-0.448,1-1,1s-1-0.448-1-1V17c0-0.552,0.448-1,1-1s1,0.448,1,1V43z"
				/>
				<path
					d="M22,14c-1.654,0-3,1.346-3,3v26c0,1.654,1.346,3,3,3s3-1.346,3-3V17C25,15.346,23.654,14,22,14z M23,43
                        c0,0.552-0.448,1-1,1s-1-0.448-1-1V17c0-0.552,0.448-1,1-1s1,0.448,1,1V43z"
				/>
				<path
					d="M57.5,17c1.379,0,2.5-1.121,2.5-2.5v-3c0-1.379-1.121-2.5-2.5-2.5S55,10.121,55,11.5V12h-1V9h-3V8c0-2.206-1.794-4-4-4
                        s-4,1.794-4,4v1c0,0.552-0.448,1-1,1s-1-0.448-1-1V8c0-2.206-1.794-4-4-4s-4,1.794-4,4v1c0,0.552-0.448,1-1,1s-1-0.448-1-1V8
                        c0-2.206-1.794-4-4-4s-4,1.794-4,4v1c0,0.552-0.448,1-1,1s-1-0.448-1-1V8c0-2.206-1.794-4-4-4s-4,1.794-4,4v1c0,0.552-0.448,1-1,1
                        s-1-0.448-1-1V8c0-2.206-1.794-4-4-4S3,5.794,3,8v2H0v7h3v26H0v7h3v2c0,2.206,1.794,4,4,4s4-1.794,4-4v-1c0-0.552,0.448-1,1-1
                        s1,0.448,1,1v1c0,2.206,1.794,4,4,4s4-1.794,4-4v-1c0-0.552,0.448-1,1-1s1,0.448,1,1v1c0,2.206,1.794,4,4,4s4-1.794,4-4v-1
                        c0-0.552,0.448-1,1-1s1,0.448,1,1v1c0,2.206,1.794,4,4,4s4-1.794,4-4v-1c0-0.552,0.448-1,1-1s1,0.448,1,1v1c0,2.206,1.794,4,4,4
                        s4-1.794,4-4v-2h3v-1h6v-5h-6v-1h-3V17h3v-3h1v0.5C55,15.879,56.121,17,57.5,17z M2,15v-3h1v3H2z M2,48v-3h1v3H2z M58,46v1h-4v-1
                        H58z M52,45v3h-1v-3H52z M49,43v7v2c0,1.103-0.897,2-2,2s-2-0.897-2-2v-1c0-1.654-1.346-3-3-3s-3,1.346-3,3v1c0,1.103-0.897,2-2,2
                        s-2-0.897-2-2v-1c0-1.654-1.346-3-3-3s-3,1.346-3,3v1c0,1.103-0.897,2-2,2s-2-0.897-2-2v-1c0-1.654-1.346-3-3-3s-3,1.346-3,3v1
                        c0,1.103-0.897,2-2,2s-2-0.897-2-2v-1c0-1.654-1.346-3-3-3s-3,1.346-3,3v1c0,1.103-0.897,2-2,2s-2-0.897-2-2v-2v-7V17v-7V8
                        c0-1.103,0.897-2,2-2s2,0.897,2,2v1c0,1.654,1.346,3,3,3s3-1.346,3-3V8c0-1.103,0.897-2,2-2s2,0.897,2,2v1c0,1.654,1.346,3,3,3
                        s3-1.346,3-3V8c0-1.103,0.897-2,2-2s2,0.897,2,2v1c0,1.654,1.346,3,3,3s3-1.346,3-3V8c0-1.103,0.897-2,2-2s2,0.897,2,2v1
                        c0,1.654,1.346,3,3,3s3-1.346,3-3V8c0-1.103,0.897-2,2-2s2,0.897,2,2v1v8V43z M52,15h-1v-4h1V15z M57,11.5
                        c0-0.275,0.225-0.5,0.5-0.5s0.5,0.225,0.5,0.5v3c0,0.275-0.225,0.5-0.5,0.5S57,14.775,57,14.5V11.5z"
				/>
			</g>
		</svg>
	);
};
