import {useContext, useMemo} from 'react';
import {Initialize} from 'utils/axios';
import {AuthContext} from '../auth/auth.context';
const WithAxios = ({children}) => {
	const {authDispatch} = useContext(AuthContext);

	useMemo(() => {
		Initialize({authDispatch});
	}, [authDispatch]);

	return children;
};

export default WithAxios;
