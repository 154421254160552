export const CarpetIcon = props => {
	return (
		<svg width="14.4" height="12" viewBox="0 0 512 512" {...props}>
			<g>
				<g>
					<path
						d="M501.333,106.667H398.108c0.453-9.73,0.005-19.512-2.379-29.063c-8.438-33.729-33.146-60.417-66.146-71.438l-2.938-0.979
                        C316.333,1.75,305.563,0,292.938,0c-33.333,0-64.479,16.396-83.313,43.792L27.186,305.402c-2.779,3.556-5.35,7.268-7.747,11.111
                        l-1.314,1.883l0.118,0.082C6.74,337.673,0,360.038,0,384c0,70.583,57.417,128,128,128h117.333h21.333
                        c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667h-2.311L290.43,448h18.904
                        c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667h-5.867L329.54,384H352
                        c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667h-9.422L368.651,320H384
                        c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667h-2.311L407.763,256h18.904
                        c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667h-5.867L446.874,192h11.793
                        c5.896,0,10.667-4.771,10.667-10.667c0-5.503-4.211-9.866-9.559-10.444L485.984,128h15.349c5.896,0,10.667-4.771,10.667-10.667
                        C512,111.438,507.229,106.667,501.333,106.667z M227.167,55.938c14.896-21.667,39.479-34.604,67.479-34.604
                        c8.625,0,17.104,1.375,25.25,4.104l2.938,0.979c26.042,8.688,45.542,29.75,52.188,56.354c5.208,20.813,1.708,43.229-9.583,61.417
                        L233.771,353.805C229.181,299.194,183.777,256,128,256c-16.435,0-32.102,3.224-46.549,8.891L227.167,55.938z M239.354,490.667H128
                        c-58.813,0-106.667-47.854-106.667-106.667c0-21.072,6.336-40.613,16.926-57.174l3.717-5.329
                        c19.413-26.634,50.62-44.163,86.023-44.163c47.063,0,85.333,38.271,85.333,85.333S175.063,448,128,448c-35.292,0-64-28.708-64-64
                        c0-35.292,28.708-64,64-64c2.875,0,5.667,0.292,8.375,0.833l-14.292,20.917c-20.729,2.875-36.75,20.729-36.75,42.25
                        c0,23.521,19.146,42.667,42.667,42.667c35.292,0,64-28.708,64-64s-28.708-64-64-64c-47.063,0-85.333,38.271-85.333,85.333
                        S80.938,469.333,128,469.333c41.405,0,76.98-23.962,94.648-58.525l0.372,0.233l160.521-255.563
                        c5.285-8.527,8.743-17.887,11.281-27.479h66.156L239.354,490.667z M136.813,358.021l18.938-27.75
                        c9.125,7.833,14.917,19.458,14.917,32.396c0,23.521-19.146,42.667-42.667,42.667c-11.771,0-21.333-9.563-21.333-21.333
                        c0-11.771,9.563-21.333,21.333-21.333C131.521,362.667,134.813,360.917,136.813,358.021z"
					/>
				</g>
			</g>
		</svg>
	);
};
