import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';
import {Link} from 'react-router-dom';

export const MarginRightLg5 = styled.div`
	@media (min-width: 992px) {
		margin-right: 3rem !important;
	}
`;
export const AboutCardContent = styled.div``;
export const AboutTextBlock = styled.div`
	:not(:last-child) {
		margin-bottom: 3rem;
	}
`;
export const AboutSubtitle = styled.h5`
	color: ${themeGet('colors.red.light')};
	margin-bottom: 0.5rem;
	letter-spacing: 0.04em;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.3;
	@media (min-width: 576px) {
		font-size: 1.125rem;
	}
`;
export const AboutTitle = styled.h2`
	color: ${themeGet('colors.black.900')};
	font-size: 2.25rem;
	font-weight: 700;
	line-height: 1.3;
	margin-bottom: 0.5rem;
	@media (min-width: 576px) {
		font-size: 2.5rem;
	}
`;
export const AboutSectionLead = styled.p`
	padding-top: 0.6875rem;
	font-size: 1.25rem;
	line-height: 1.5;
	font-weight: 300;
	@media (min-width: 576px) {
		margin-bottom: 1rem;
	}
`;
export const AboutSectionParagraph = styled.p`
	margin-bottom: 0.75rem;
	color: #2d313d;
	line-height: 1.5;
	@media (min-width: 576px) {
		margin-bottom: 1rem;
	}
`;
export const AboutSectionNavigation = styled.div``;
export const AboutSectionList = styled.ul`
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	color: ${themeGet('colors.black.regular')};
	li {
		padding: 10px;
		flex: 0 0 100%;
		max-width: 100%;
		position: relative;
		width: 100%;
		@media (min-width: 576px) {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
`;
export const AboutSectionListLink = styled(Link)`
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1;
	background: ${themeGet('colors.transparent')};
	transition: all 0.3s ease;
	white-space: nowrap;
	outline: 0 none;
	color: ${themeGet('colors.primary.regular')};
	:hover {
		color: ${themeGet('colors.primary.hover')};
		outline: 0;
	}

	span {
		display: inline-block;
		margin-right: 6px;
	}
`;
