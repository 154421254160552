import React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import * as icons from 'assets/icons/category-icons';
import IconNavCard from './type-nav-card';
import {useAppDispatch, useAppState} from '../../contexts/app/app.provider';

const CategoryWrapper = styled.div(
	css({
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		backgroundColor: 'white',
		// margin: '0 -7.5px',
	}),
);

const Col = styled.div(
	css({
		width: '50%',
		padding: '0 7.5px',
		marginBottom: 15,

		'@media screen and (min-width: 768px)': {
			width: '33.333%',
		},
	}),
);
const CategoryIcon = ({name, style}) => {
	const TagName = icons[name];
	return !!TagName ? (
		<TagName style={{...style, width: '30px', height: '30px'}} />
	) : (
		<p>Invalid icon {name}</p>
	);
};

const CategoryIconNav = ({closeRequest, onSelected, ...props}) => {
	const appDispatch = useAppDispatch();
	const categories = useAppState('categories');
	const selectedCategory = useAppState('searchCategory');

	const handleOnClick = (e, item) => {
		e.preventDefault();
		appDispatch({
			type: 'SET_SEARCH_CATEGORY',
			payload: {
				label: item.title,
				value: item.slug,
			},
		});
		if (onSelected && typeof onSelected === 'function') {
			onSelected(item);
		}
		closeRequest();
	};
	return (
		<CategoryWrapper>
			{categories
				? categories.data.map(item => (
						<Col key={item.slug}>
							<IconNavCard
								onClick={e => handleOnClick(e, item)}
								icon={<CategoryIcon name={item.icon} />}
								title={item.title}
								active={
									selectedCategory && selectedCategory.value === item.slug
								}
								{...props}
							/>
						</Col>
				  ))
				: null}
		</CategoryWrapper>
	);
};
export default CategoryIconNav;
