class Phone {
	constructor(data) {
		this.id = data.id;
		this.title = data.title;
		this.phone = data.phone;
		this.verified = data.verified;
		this.status = data.status;
		this.created_at = data.created_at;
		this.updated_at = data.updated_at;
		this.default = data.default;
		this.loading =
			data.loading !== undefined && data.loading !== null
				? data.loading
				: false;
	}
	setLoading(val) {
		this.loading = val;
	}
}

const parsePhoneNumbers = list => {
	if (list && typeof list === 'object' && list.length > 0)
		return list.map(item => {
			return new Phone(item);
		});
	else return [];
};
export {Phone, parsePhoneNumbers};
