export const RollerBlindIcon = props => {
	return (
		<svg width="14.4" height="12" viewBox="0 0 512 512" {...props}>
			<path d="m488 16h-464a8.00039 8.00039 0 0 0 -8 8v96a8.00039 8.00039 0 0 0 8 8h32a8.00039 8.00039 0 0 0 8-8v-24h16v320h-16a8.00039 8.00039 0 0 0 -8 8v8h-24a8.00039 8.00039 0 0 0 -8 8v32a8.00039 8.00039 0 0 0 8 8h24v8a8.00039 8.00039 0 0 0 8 8h384a8.00039 8.00039 0 0 0 8-8v-8h24a8.00039 8.00039 0 0 0 8-8v-32a8.00039 8.00039 0 0 0 -8-8h-24v-8a8.00039 8.00039 0 0 0 -8-8h-16v-88h-16v40h-192v16h192v32h-320v-320h304v172a44 44 0 0 0 88 0v-44h-16v44a28 28 0 0 1 -56 0v-172h32v24a8.00039 8.00039 0 0 0 8 8h16v48h16v-48a8.00039 8.00039 0 0 0 8-8v-96a8.00039 8.00039 0 0 0 -8-8zm-448 448v-16h16v16zm432-16v16h-16v-16zm-32 32h-368v-48h368zm-392-368h-16v-80h16zm16-32v-48h384v48zm400 8v-56h16v80h-16z" />
			<path d="m112 368h56v16h-56z" />
			<path d="m184 368h24v16h-24z" />
			<path d="m112 320h144v16h-144z" />
			<path d="m272 320h40v16h-40z" />
			<path d="m328 320h72v16h-72z" />
			<path d="m112 272h64v16h-64z" />
			<path d="m192 272h24v16h-24z" />
			<path d="m232 272h152v16h-152z" />
			<path d="m112 224h144v16h-144z" />
			<path d="m272 224h64v16h-64z" />
			<path d="m352 224h32v16h-32z" />
			<path d="m112 176h80v16h-80z" />
			<path d="m216 176h16v16h-16z" />
			<path d="m248 176h136v16h-136z" />
			<path d="m112 128h168v16h-168z" />
			<path d="m296 128h16v16h-16z" />
			<path d="m328 128h56v16h-56z" />
			<path d="m472 192h16v16h-16z" />
		</svg>
	);
};
