import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Carousel from './carousel';
import {useAppState} from '../../contexts/app/app.provider';
import * as CategoryIcons from '../../assets/icons/category-icons';

const CarouselWrapper = styled.div`
	border-bottom: 1px solid #f8f8f8;
	font-family: Poppins, sans-serif;
`;

const CategoryWrapper = styled.div`
	position: relative;
	background-color: #fff;
	overflow: hidden;
	box-shadow: 0 0 4px rgb(0 0 0 / 9%);
	@media (max-width: 768px) {
		padding-left: 10px;
		padding-right: 10px;
	}
`;
const CategoryWrapperInner = styled.div`
	position: relative;
	height: 100px;
	border-right: 1px solid #f8f8f8;
`;
const CategoryWrapperRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	height: 100%;
`;
const CategoryWrapperIconWrapper = styled.div`
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const CategoryNameWrapper = styled.div`
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
	display: flex;
	align-items: center;
	color: #575757;
	font-size: 10pt;
	font-weight: bold;
	opacity: 0.97;
	transition: all 200ms;
`;
const CategoryWrapperIconContainer = styled.span`
	background-color: #fff;
	padding: 14px;
	border-radius: 50%;
	border: 2px solid #f8f8f8;
	margin: 0 auto;
	text-align: center;
	color: #4cbcea;
	width: 64px;
	height: 64px;
`;
const Icon = ({name, ...props}) => {
	const TagName = CategoryIcons[name];
	return !!TagName ? <TagName {...props} /> : <p>Invalid icon {name}</p>;
};

const responsive = {
	desktop: {
		breakpoint: {max: 3000, min: 1024},
		items: 5,
	},
	tablet: {
		breakpoint: {max: 1024, min: 464},
		items: 3,
	},
	mobile: {
		breakpoint: {max: 464, min: 0},
		items: 2,
	},
};

const CategoryCarouselComponent = category => {
	return (
		<CategoryWrapper key={'category-carousel-' + category.slug}>
			<CategoryWrapperInner>
				<CategoryWrapperRow>
					<CategoryWrapperIconWrapper>
						<CategoryWrapperIconContainer>
							<Icon
								name={category.icon}
								fill="#4cbcea"
								stroke="#4cbcea"
								width={32}
								height={32}
							/>
						</CategoryWrapperIconContainer>
					</CategoryWrapperIconWrapper>
					<CategoryNameWrapper>{category.title}</CategoryNameWrapper>
				</CategoryWrapperRow>
			</CategoryWrapperInner>
		</CategoryWrapper>
	);
};

const CategoryCarousel = ({
	autoPlay = false,
	infinite = true,
	deviceType,
	itemClass,
	isRtl,
	...props
}) => {
	const categories = useAppState('categories');
	const [data, setData] = useState(null);
	useEffect(() => {
		setData(categories && categories.data ? categories.data : null);
	}, [categories]);

	return data && data.length > 0 ? (
		<CarouselWrapper>
			<Carousel
				deviceType={deviceType}
				component={CategoryCarouselComponent}
				data={data}
				autoPlay={autoPlay}
				infinite={infinite}
				itemClass={itemClass}
				isRtl={isRtl}
				responsive={responsive}
				customButtonGroup={undefined}
			/>
		</CarouselWrapper>
	) : null;
};

export default CategoryCarousel;
