import React, {useEffect, useState} from 'react';
import {SearchIcon} from 'assets/icons/SearchIcon';
import {
	CustomControlIcon,
	CustomSelectGroupContainer,
	CustomSelectGroupText,
	MinimalAsyncSelect,
	MinimalButtonsContainer,
	MinimalSearchButton,
	StyledForm,
	StyledInput,
	StyledSearchButton,
} from './search-box.style';
import AsyncSelect from 'react-select/async';
import {components} from 'react-select';
import {useAppDispatch, useAppState} from 'contexts/app/app.provider';
import useAddress from 'services/use-address';
import {MapPinIcon} from '../../assets/icons/MapPinIcon';
import useUser from '../../services/use-user';
import {parseAddresses} from '../../models/Address';

export const SearchBox = ({
	onEnter,
	onChange,
	value,
	name,
	minimal,
	categoryType,
	buttonText,
	className,
	showButtonText = true,
	shadow,
	...rest
}) => {
	const [formattedSavedAddressList, setFormattedSavedAddressList] = useState(
		[],
	);
	const selectedNeighbourhood = useAppState('searchNeighbourhood');
	const appDispatch = useAppDispatch();
	const myAddresses = useAppState('userAddresses');
	const userServices = useUser();
	useEffect(() => {
		if (myAddresses.data === null) {
			appDispatch({
				type: 'SET_USER_ADDRESSES_LOADING',
				payload: true,
			});
			userServices
				.getAddresses()
				.then(res => {
					if (res.success) {
						appDispatch({
							type: 'SET_USER_ADDRESSES_DATA',
							payload: parseAddresses(res.data),
						});
					} else {
						appDispatch({
							type: 'SET_USER_ADDRESSES_DATA',
							payload: [],
						});
					}
				})
				.then(() => {
					appDispatch({
						type: 'SET_USER_ADDRESSES_LOADING',
						payload: false,
					});
				});
		}
	}, []);
	useEffect(() => {
		if (myAddresses && myAddresses.data) {
			setFormattedSavedAddressList([
				{
					label: 'Benim Adreslerim',
					options: myAddresses.data.map(item => ({
						label: item.title,
						value: item.neighbourhood.slug,
					})),
				},
			]);
		} else {
			setFormattedSavedAddressList([]);
		}
	}, [myAddresses]);

	const addressService = useAddress();
	const handleLoadOptions = (inputValue, callback) => {
		if (inputValue.length < 2) {
			callback(formattedSavedAddressList);
			return Promise.resolve();
		}
		addressService.searchAddress(inputValue).then(res => {
			if (res.data) {
				const items = res.data.map(item => ({
					label:
						item.province + ' - ' + item.district + ' - ' + item.neighbourhood,
					value: item.neighbourhood_slug,
				}));
				const data = [
					...formattedSavedAddressList,
					{
						label: 'Lokasyon',
						options: items,
					},
				];
				callback(data);
			}
		});
	};
	const formatGroupLabel = data => {
		return (
			<CustomSelectGroupContainer>
				<CustomSelectGroupText>{data.label}</CustomSelectGroupText>
			</CustomSelectGroupContainer>
		);
	};
	const customSelectStyle = {
		container: provided => ({
			...provided,
			width: '100%',
		}),
		menuPortal: provided => ({
			...provided,
			zIndex: 99,
		}),
		control: (provided, state) => ({
			...provided,
			borderColor: 'hsl(0, 0%, 80%)!important',
			boxShadow: 'none!important',
			height: '54px',
			borderRadius: state.isFocused ? '0' : '10px',
			transition: 'all .25s ease-in-out',
		}),
		option: (provided, state) => ({
			...provided,
			padding: 12,
			backgroundColor: 'transparent!important',
			color: state.isSelected || state.isFocused ? '#ffa737' : null,
			cursor: 'pointer',
			borderTop: '1px solid #f1f1f1',
		}),
	};
	const CustomControl = ({children, ...props}) => (
		<components.Control {...props}>
			<CustomControlIcon>
				<MapPinIcon width={minimal ? 20 : 24} height={minimal ? 20 : 24} />
			</CustomControlIcon>
			{children}
		</components.Control>
	);
	return (
		<StyledForm
			onSubmit={onEnter}
			className={className}
			boxShadow={shadow}
			minimal={minimal}
		>
			{minimal ? (
				<MinimalButtonsContainer>
					<MinimalAsyncSelect
						menuPortalTarget={document.querySelector('body')}
						formatGroupLabel={formatGroupLabel}
						onChange={item =>
							appDispatch({
								type: 'SET_SEARCH_NEIGHBOURHOOD',
								payload: item,
							})
						}
						value={selectedNeighbourhood}
						placeholder="İl, ilçe ve mahalle ara.."
						cacheOptions
						components={{Control: CustomControl}}
						defaultOptions
						loadOptions={handleLoadOptions}
					/>
					<MinimalSearchButton>
						<SearchIcon style={{marginLeft: 16, marginRight: 5}} /> Ara
					</MinimalSearchButton>
				</MinimalButtonsContainer>
			) : (
				<>
					<StyledInput>
						<AsyncSelect
							styles={customSelectStyle}
							menuPortalTarget={document.querySelector('body')}
							formatGroupLabel={formatGroupLabel}
							components={{Control: CustomControl}}
							onChange={item =>
								appDispatch({
									type: 'SET_SEARCH_NEIGHBOURHOOD',
									payload: item,
								})
							}
							value={selectedNeighbourhood}
							backspaceRemovesValue={true}
							placeholder="İl, ilçe ve mahalle ara.."
							cacheOptions
							defaultOptions
							loadOptions={handleLoadOptions}
						/>
					</StyledInput>
					<StyledSearchButton>
						<SearchIcon style={{marginRight: 10}} />
						{showButtonText && buttonText}
					</StyledSearchButton>
				</>
			)}
		</StyledForm>
	);
};
