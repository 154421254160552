export const ShopIcon = props => {
	return (
		<svg width="14.4" height="12" viewBox="0 0 512 512" {...props}>
			<g>
				<g>
					<path
						d="M511.25,169.527l-0.344-1.922c-0.062-0.297-0.094-0.594-0.156-0.883l-35.906-86.719V37.34H36.562v42.664L1.25,166.715
						c-0.078,0.344-0.125,0.688-0.188,1.023l-0.328,1.789C0.25,172.629,0,175.809,0,179.051c0,23.188,12.938,43.344,32,53.648V474.66
						h448V232.699c19.047-10.305,32-30.461,32-53.648C512,175.809,511.734,172.629,511.25,169.527z M487.141,165.34h-78.375
						l-9.422-63.336h62L487.141,165.34z M57.906,58.668h395.609v21.336H57.906V58.668z M390.375,186.668l1.156,7.742l-6.312,8.398
						c-7.562,10.078-19.125,15.859-31.703,15.859s-24.141-5.781-31.703-15.859l-6.438-8.578L315,186.668H390.375z M313.953,165.34
						l-3.109-63.336h66.922l9.438,63.336H313.953z M293.641,186.667L293.641,186.667l0.375,7.735l-6.328,8.406
						c-7.562,10.078-19.125,15.859-31.688,15.859c-12.594,0-24.141-5.781-31.719-15.859l-6.469-8.633l0.375-7.508H293.641z
						 M219.266,165.34l3.203-63.336H289.5l3.094,63.336H219.266z M124.906,165.34l9.312-63.336h66.906l-3.219,63.336H124.906z
						 M196.812,186.668l-0.391,7.82l-6.25,8.32c-7.578,10.078-19.125,15.859-31.703,15.859s-24.125-5.781-31.703-15.859l-6.156-8.195
						l1.172-7.945H196.812z M50.641,102.004h62.016l-9.312,63.336h-78.5L50.641,102.004z M22.062,186.668h78.156l-1.672,8.289
						l-5.891,7.852c-7.578,10.078-19.125,15.859-31.719,15.859C41.703,218.668,25.625,204.887,22.062,186.668z M416,453.348h-85.344
						V378.66h10.672c5.891,0,10.672-4.766,10.672-10.656s-4.781-10.656-10.672-10.656h-10.672V282.66H416V453.348z M458.658,453.348
						h-0.002h-21.328v-192h-128v192h-256v-213.82c2.484,0.312,5.047,0.477,7.609,0.477c19.938,0,37.656-9.578,48.766-24.383
						c11.125,14.805,28.828,24.383,48.766,24.383s37.641-9.578,48.766-24.383c11.109,14.805,28.828,24.383,48.766,24.383
						s37.625-9.578,48.75-24.383c11.125,14.805,28.828,24.383,48.766,24.383s37.641-9.578,48.766-24.383
						c11.125,14.805,28.812,24.383,48.75,24.383c2.594,0,5.125-0.164,7.625-0.477V453.348z M451.031,218.668
						c-12.562,0-24.125-5.781-31.688-15.859l-6.203-8.234l-1.203-7.906h77.969C486.344,204.887,470.281,218.668,451.031,218.668z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path d="M74.656,261.348V410.66H288V261.348H74.656z M266.656,389.348H96V282.66h170.656V389.348z" />
				</g>
			</g>
			<g>
				<g>
					<path
						d="M245.25,336.004c-2.953,0-5.625,1.188-7.547,3.125l-21.328,21.344c-1.938,1.922-3.125,4.594-3.125,7.531
						c0,5.891,4.781,10.656,10.656,10.656c2.953,0,5.625-1.188,7.547-3.125l21.328-21.312c1.938-1.938,3.125-4.609,3.125-7.562
						C255.906,340.785,251.141,336.004,245.25,336.004z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M245.25,293.348c-2.953,0-5.625,1.188-7.547,3.125l-64,63.984c-1.922,1.922-3.125,4.609-3.125,7.547
						c0,5.891,4.781,10.656,10.672,10.656c2.938,0,5.609-1.188,7.531-3.125l64-63.984c1.938-1.922,3.125-4.609,3.125-7.547
						C255.906,298.113,251.141,293.348,245.25,293.348z"
					/>
				</g>
			</g>
		</svg>
	);
};
