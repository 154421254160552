import React from 'react';
import {RightMenuBox} from './right-menu.style';
import NavLink from 'components/nav-link/nav-link';
import {COUPONS_MENU_ITEM, HELP_MENU_ITEM} from 'site-settings/site-navigation';
import {HelpIcon} from 'assets/icons/HelpIcon';
import AuthMenu from '../auth-menu/auth-menu';

export const RightMenu = ({onLogout, avatar, isAuthenticated, onJoin}) => {
	return (
		<RightMenuBox>
			<NavLink
				className="menu-item"
				href={COUPONS_MENU_ITEM.href}
				label={COUPONS_MENU_ITEM.defaultMessage}
				intlId={COUPONS_MENU_ITEM.id}
			/>
			<NavLink
				className="menu-item"
				href={HELP_MENU_ITEM.href}
				label={HELP_MENU_ITEM.defaultMessage}
				intlId={HELP_MENU_ITEM.id}
				iconClass="menu-icon"
				icon={<HelpIcon />}
			/>
			<AuthMenu
				avatar={avatar}
				onJoin={onJoin}
				onLogout={onLogout}
				isAuthenticated={isAuthenticated}
			/>
		</RightMenuBox>
	);
};
