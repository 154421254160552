import styled from 'styled-components';
export const HomeContainer = styled.div`
	width: 100%;
	padding: 20px 15px;
	margin-right: auto;
	margin-left: auto;
	@media (min-width: 420px) {
		padding: 0 15px;
		max-width: 414px;
	}
	@media (min-width: 576px) {
		max-width: 540px;
	}
	@media (min-width: 768px) {
		max-width: 720px;
	}
	@media (min-width: 992px) {
		max-width: 960px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
`;
export const HomeSection = styled.section`
	background-color: #fff;
	overflow-x: hidden;
	@media (max-width: 992px) {
		margin-bottom: 30px;
	}
	@media (min-width: 768px) {
		padding: 90px 0;
	}
	@media (min-width: 992px) {
		padding: 120px 0;
	}
`;
export const HomeRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;
export const Col = styled.div`
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
`;
export const ColLg6 = styled(Col)`
	@media (min-width: 992px) {
		flex: 0 0 50%;
		max-width: 50%;
	}
`;
export const Col12 = styled(Col)`
	flex: 0 0 100%;
	max-width: 100%;
	padding: 10px;
`;
export const HomePageImage = styled.img`
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	border-style: none;
`;
