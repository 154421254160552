import React, {useState} from 'react';

const ReadMore = ({children, more, less, character}) => {
	const [expanded, setExpanded] = useState(false);

	const toggleLines = event => {
		event.preventDefault();
		setExpanded(!expanded);
	};

	if (!children) return null;

	return (
		<>
			{(children && children.length < character) || expanded
				? children
				: children.substring(0, character)}
			{children && children.length > character && !expanded && (
				<>
					<br />
					<span>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a
							href="#"
							onClick={toggleLines}
							style={{color: '#009e7f', fontWeight: 'bold'}}
						>
							{more}
						</a>
					</span>
				</>
			)}
			{children && children.length > character && expanded && (
				<>
					<br />
					<span>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a
							href="#"
							onClick={toggleLines}
							style={{color: '#009e7f', fontWeight: 'bold'}}
						>
							{less}
						</a>
					</span>
				</>
			)}
		</>
	);
};

ReadMore.defaultProps = {
	character: 150,
	more: 'Read more',
	less: 'less',
};

export default ReadMore;
