import api from 'utils/axios';

export default function useContactUs() {
	const contactUs = ({name, email, subject, content}) => {
		return api
			.post('contactus', {
				name,
				email,
				subject,
				content,
			})
			.then(res => {
				return res.data;
			});
	};
	return {
		contactUs,
	};
}
