export const OfficeIcon = props => {
	return (
		<svg width="14.4" height="12" viewBox="0 0 512 512" {...props}>
			<g>
				<path d="m496 488h-32v-56.79c22.67-4.46 40-27.51 40-55.21 0-30.88-21.53-56-48-56s-48 25.12-48 56c0 27.7 17.33 50.75 40 55.21v25.84l-11.56-7.71a8 8 0 1 0 -8.88 13.32l20.44 13.62v11.72h-48v-440a8 8 0 0 0 -8-8h-56v-24a8 8 0 0 0 -8-8h-144a8 8 0 0 0 -8 8v24h-56a8 8 0 0 0 -8 8v440h-48v-56.79c22.67-4.46 40-27.51 40-55.21 0-30.88-21.53-56-48-56s-48 25.12-48 56c0 27.7 17.33 50.75 40 55.21v25.84l-11.56-7.71a8 8 0 0 0 -8.88 13.32l20.44 13.62v11.72h-32a8 8 0 0 0 0 16h480a8 8 0 0 0 0-16zm-440-120a8 8 0 0 0 -8 8v38.73c-13.79-4.45-24-20.13-24-38.73 0-22.06 14.35-40 32-40s32 17.94 32 40c0 18.6-10.21 34.28-24 38.73v-38.73a8 8 0 0 0 -8-8zm136-344h128v16h-128zm56 464h-8v-64h8zm24 0h-8v-64h8zm112 0h-96v-72a8 8 0 0 0 -8-8h-48a8 8 0 0 0 -8 8v72h-96v-384h256zm0-400h-256v-32h256zm72 280a8 8 0 0 0 -8 8v38.73c-13.79-4.45-24-20.13-24-38.73 0-22.06 14.35-40 32-40s32 17.94 32 40c0 18.6-10.21 34.28-24 38.73v-38.73a8 8 0 0 0 -8-8z" />
				<path d="m280 328h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m200 328h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m360 328h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m200 408h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m360 408h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m280 256h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m200 256h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m360 256h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m280 184h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m200 184h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m360 184h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m280 112h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m200 112h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
				<path d="m360 112h-48a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8zm-8 48h-32v-32h32z" />
			</g>
		</svg>
	);
};
