class Basket {
	constructor({
		id,
		title,
		category,
		category_slug,
		description,
		discountInPercent,
		gallery,
		icon,
		image,
		m2,
		price,
		salePrice,
		slug,
		neighbourhood_slug,
	}) {
		this.id = parseInt(id);
		this.title = title;
		this.slug = slug;
		this.category = category;
		this.category_slug = category_slug;
		this.neighbourhood_slug = neighbourhood_slug;
		this.description = description;
		this.discountInPercent = parseFloat(discountInPercent);
		this.gallery = gallery;
		this.icon = icon;
		this.image = image;
		this.m2 = m2;
		this.price = parseFloat(price);
		this.salePrice = parseFloat(salePrice);
	}
}
export default Basket;
