import api from 'utils/axios';

export default function useUser() {
	const me = () => {
		return api.get('me').then(res => {
			return res && res.data
				? res.data
				: {success: false, message: 'An error has occured!'};
		});
	};
	const updateMe = ({
		firstname,
		lastname,
		email,
		default_phone,
		default_address,
	}) => {
		return api
			.put('me', {firstname, lastname, email, default_address, default_phone})
			.then(res => {
				return res.data;
			});
	};
	const getAddresses = () => {
		return api.get('address').then(res => {
			return res.data;
		});
	};
	const addAddress = ({title, neighbourhood, address, zip}) => {
		return api
			.post('address', {title, neighbourhood_id: neighbourhood, address, zip})
			.then(res => {
				return res.data;
			});
	};
	const updateAddress = (id, {title, neighbourhood, address, zip}) => {
		return api
			.put('address', {
				address_id: id,
				title,
				neighbourhood_id: neighbourhood,
				address,
				zip,
			})
			.then(res => {
				return res.data;
			});
	};
	const deleteAddress = id => {
		return api.delete('address/' + id).then(res => {
			return res.data;
		});
	};
	const setAddressDefault = id => {
		return api.post('address/default', {address_id: id}).then(res => {
			return res.data;
		});
	};
	const getPhoneNumbers = () => {
		return api.get('phone').then(res => {
			return res.data;
		});
	};
	const addPhone = ({title, phone}) => {
		return api.post('phone', {title, phone}).then(res => {
			return res.data;
		});
	};
	const updatePhone = (id, {title}) => {
		return api.put('phone', {phone_id: id, title}).then(res => {
			return res.data;
		});
	};
	const deletePhone = id => {
		return api.delete('phone/' + id).then(res => {
			return res.data;
		});
	};
	const setPhoneDefault = id => {
		return api.post('phone/default', {phone_id: id}).then(res => {
			return res.data;
		});
	};
	const sendPhoneVerification = phone => {
		return api.post('verification/phone/send', {phone}).then(res => {
			return res.data;
		});
	};
	const verifyPhoneVerification = ({phone, code}) => {
		return api.post('verification/phone/verify', {phone, code}).then(res => {
			return res.data;
		});
	};
	const sendEmailVerificationMail = () => {
		return api.post('verification/email/send').then(res => {
			return res.data;
		});
	};
	const verifyEmailVerification = ({email, code}) => {
		return api.post('verification/email/verify', {email, code}).then(res => {
			return res.data;
		});
	};
	const changePassword = ({current_password, password, password_repeat}) => {
		return api
			.put('me/password', {current_password, password, password_repeat})
			.then(res => {
				return res.data;
			});
	};
	return {
		me,
		updateMe,
		getAddresses,
		addAddress,
		updateAddress,
		deleteAddress,
		setAddressDefault,
		getPhoneNumbers,
		addPhone,
		updatePhone,
		deletePhone,
		setPhoneDefault,
		sendPhoneVerification,
		verifyPhoneVerification,
		sendEmailVerificationMail,
		verifyEmailVerification,
		changePassword,
	};
}
