import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';
export const BlogPostHeaderWrapper = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-wrap: wrap;
	background-color: ${themeGet('colors.white', '#fff')};
	padding: 100px 70px 10px;

	@media only screen and (max-width: 990px) {
		padding: 60px 0 10px;
	}

	@media only screen and (min-width: 991px) and (max-width: 1280px) {
		padding: 90px 15px 10px;
	}
`;
export const BlogPostWrapper = styled.div`
	width: 100%;
	height: auto;
	min-height: 100vh;
	display: flex;
	flex-wrap: wrap;
	background-color: ${themeGet('colors.gray', '#f7f7f7')};
	padding: 0 70px 0;

	@media only screen and (max-width: 990px) {
		padding: 0;
	}

	@media only screen and (min-width: 991px) and (max-width: 1280px) {
		padding: 0;
	}
`;
export const BlogPostImage = styled.img`
	width: 100%;
	margin-top: 30px;
	border-radius: 10px;
	border-style: none;
	vertical-align: middle;
`;
export const BlogPostDetail = styled.div`
	margin-top: 13px;
`;
export const BlogPostDateSection = styled.div`
	margin-bottom: 23px;
	font-size: 14px;
	margin-top: 30px;
	font-weight: 400;
	color: #686f7a;
	text-align: left;
	display: block;
	padding-bottom: 25px;
	border-bottom: 1px solid #e5e5e5;
`;
export const BlogPostContent = styled.div`
	margin-bottom: 30px;
	margin-top: 35px;
	h4 {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 13px;
		text-align: left;
		color: #333;
		line-height: 26px;
	}
	p {
		font-size: 14px;
		font-weight: 400;
		line-height: 26px;
		color: #686f7a;
		text-align: left;
		margin: 0 0 1em;
	}
	p:last-child {
		margin-bottom: 0;
	}
`;

export const BlogCol12 = styled.div`
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	flex: 0 0 100%;
	max-width: 100%;
`;
export const BlogHeaderNavigation = styled.div`
	width: 100%;
`;
export const BlogHeaderNavigationLeft = styled.div`
	float: left;
`;
export const BlogHeaderNavigationRight = styled.div`
	float: right;
`;
export const BlogHeaderNavigationBreadcrumb = styled.nav`
	display: block;
	-webkit-transition: all 0.4s ease-in;
	-moz-transition: all 0.4s ease-in;
	-ms-transition: all 0.4s ease-in;
	-o-transition: all 0.4s ease-in;
	transition: all 0.4s ease-in;
	ol {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-bottom: 1rem;
		list-style: none;
		border-radius: 0.25rem;
		background-color: #fff !important;
		padding: 0;
		border: 0;
		vertical-align: baseline;
		li {
			font-size: 14px;
			font-weight: 400;
			color: #333;
			line-height: 24px;
			a {
				color: #333;
				text-decoration: none;
				background-color: #0000;
				.active {
					color: #6c757d;
					padding-left: 0.5rem;
				}
				:after {
					display: inline-block;
					padding-right: 0.5rem;
					color: #6c757d;
					content: '/';
				}
			}
		}
	}
`;
