import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {Input} from '../../components/forms/input';
import {Button} from '../../components/button/button';
import {
	Divider,
	LinkButton,
	Offer,
} from '../../features/authentication-form/authentication-form.style';
import {Modal, openModal} from '@redq/reuse-modal';
import {toast} from 'react-toastify';
import {AuthContext} from '../../contexts/auth/auth.context';
import useAuth from '../../services/use-auth';
import {useHistory} from 'react-router-dom';
import AuthenticationForm from '../../features/authentication-form';
import {REGISTER_PAGE} from '../../site-settings/site-navigation';

const Wrapper = styled.div`
	text-align: center;
	background-color: #fff;
	font-family: Poppins, sans-serif;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
`;
const WrapperInner = styled.div`
	width: 300px;
	@media (min-width: 576px) {
		width: 500px;
	}
`;
const ContentWrapper = styled.div`
	margin: 0;
	text-align: center;
`;
const ContentHeader = styled.h3`
	margin-bottom: 10px;
	font-size: 21px;
	font-weight: 700;
	color: #009fe1;
	display: block;
`;
const ContentSubtitle = styled.span`
	margin-bottom: 30px;
	font-size: 15px;
	font-weight: 400;
	color: #77798c;
	display: block;
`;

const LoginPage = () => {
	const {authDispatch} = useContext(AuthContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const authModel = useAuth();
	const history = useHistory();

	const toggleSignUpForm = () => {
		history.push(REGISTER_PAGE);
	};
	const toggleForgotPassForm = () => {
		authDispatch({
			type: 'FORGOTPASS',
		});

		openModal({
			show: true,
			overlayClassName: 'quick-view-overlay',
			closeOnClickOutside: true,
			component: AuthenticationForm,
			closeComponent: '',
			config: {
				enableResizing: false,
				disableDragging: true,
				className: 'quick-view-modal',
				width: 458,
				height: 'auto',
			},
		});
	};

	const loginCallback = e => {
		e.preventDefault();
		setLoading(true);
		authModel
			.login(email, password)
			.then(
				res => {
					if (res.data.access_token) {
						localStorage.setItem('access_token', res.data.access_token);
						authDispatch({type: 'SIGNIN_SUCCESS'});
						history.push('');
					} else {
						toast(res.message, {type: 'info'});
					}
				},
				err => {
					if (err.response.data && err.response.data.message) {
						toast(err.response.data.message, {type: 'warning'});
					}
				},
			)
			.then(() => setLoading(false));
	};

	return (
		<Wrapper>
			<Modal />
			<WrapperInner>
				<ContentWrapper>
					<ContentHeader>Hoşgeldiniz</ContentHeader>
					<ContentSubtitle>Email ve Şifreniz ile giriş yapınız</ContentSubtitle>
					<form onSubmit={loginCallback}>
						<Input
							type="email"
							placeholder="Email Adresiniz"
							value={email}
							onChange={e => setEmail(e.target.value)}
							required
							height="48px"
							backgroundColor="#F7F7F7"
							mb="20px"
						/>
						<Input
							type="password"
							placeholder="Şifreniz"
							value={password}
							onChange={e => setPassword(e.target.value)}
							required
							height="48px"
							backgroundColor="#F7F7F7"
							mb="20px"
						/>
						<Button
							variant="primary"
							size="big"
							style={{width: '50%', margin: 'auto'}}
							type="submit"
							disabled={loading}
							loading={loading}
						>
							{loading ? 'Giriş Yapılıyor...' : 'Devam Et'}
						</Button>
					</form>
					<Divider>Veya</Divider>
					<Offer style={{padding: '20px 0'}}>
						Henüz hesabınız yok mu?{' '}
						<LinkButton onClick={toggleSignUpForm}>Kayıt Ol</LinkButton>
					</Offer>
					<Offer style={{marginTop: 10}}>
						Şifreni mi unuttun?{' '}
						<LinkButton onClick={toggleForgotPassForm}>
							Şifreni sıfırla
						</LinkButton>
					</Offer>
				</ContentWrapper>
			</WrapperInner>
		</Wrapper>
	);
};

export default LoginPage;
