import api from 'utils/axios';

export default function useCategory() {
	const getCategories = () => {
		return api.get('categories').then(res => {
			return res.data;
		});
	};
	const getCategoryServices = (category_slug, neighbourhood_slug) => {
		return api
			.get('category/' + category_slug + '/services/' + neighbourhood_slug)
			.then(res => {
				return res.data;
			});
	};

	return {
		getCategories,
		getCategoryServices,
	};
}
