import React from 'react';
import {LogoBox, LogoImage} from './logo.style';
import {useHistory} from 'react-router-dom';

const Logo = ({imageUrl, alt, onClick}) => {
	const router = useHistory();
	function onLogoClick() {
		router.push('/');
		if (onClick) {
			onClick();
		}
	}
	return (
		<LogoBox onClick={onLogoClick}>
			<LogoImage src={imageUrl} alt={alt} />
		</LogoBox>
	);
};

export default Logo;
