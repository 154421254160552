import React, {useContext} from 'react';
import {
	SidebarWrapper,
	SidebarTop,
	SidebarBottom,
	SidebarMenu,
	LogoutButton,
} from './sidebar.style';

import {
	PROFILE_SIDEBAR_TOP_MENU,
	PROFILE_SIDEBAR_BOTTOM_MENU,
} from 'site-settings/site-navigation';
import {AuthContext} from 'contexts/auth/auth.context';
import {useHistory} from 'react-router-dom';

const Sidebar = () => {
	const {authDispatch} = useContext(AuthContext);
	const history = useHistory();
	const handleLogout = () => {
		if (typeof window !== 'undefined') {
			localStorage.removeItem('access_token');
			authDispatch({type: 'SIGN_OUT'});
			history.push('/');
		}
	};

	return (
		<>
			<SidebarWrapper>
				<SidebarTop>
					{PROFILE_SIDEBAR_TOP_MENU.map((item, index) => (
						<SidebarMenu
							href={item.href}
							key={index}
							label={item.defaultMessage}
						/>
					))}
				</SidebarTop>
				<SidebarBottom>
					{PROFILE_SIDEBAR_BOTTOM_MENU.map((item, index) => (
						<SidebarMenu
							href={item.href}
							key={index}
							label={item.defaultMessage}
						/>
					))}
					<LogoutButton type="button" onClick={handleLogout}>
						Çıkış Yap
					</LogoutButton>
				</SidebarBottom>
			</SidebarWrapper>
		</>
	);
};

export default Sidebar;
