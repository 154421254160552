import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';

export const StepsCardSectionHead = styled.div`
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 40px;
	width: 540px;
	max-width: 100%;
	text-align: center !important;
`;
export const StepsCardSubtitle = styled.h5`
	color: ${themeGet('colors.red.light')};
	margin-bottom: 0.5rem;
	letter-spacing: 0.04em;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.3;
	@media (min-width: 576px) {
		font-size: 1.125rem;
	}
`;
export const StepsCardTitle = styled.h2`
	color: ${themeGet('colors.black.900')};
	font-size: 2.25rem;
	font-weight: 700;
	line-height: 1.3;
	margin-bottom: 0.5rem;
	@media (min-width: 576px) {
		font-size: 2.5rem;
	}
`;
export const StepsCardLead = styled.p`
	padding-top: 0.6875rem;
	font-size: 1.25rem;
	line-height: 1.5;
	font-weight: 300;
	@media (min-width: 576px) {
		margin-bottom: 1rem;
	}
`;
export const StepsCardSectionContent = styled.div`
	@media (min-width: 992px) {
		padding-left: 3rem !important;
		padding-right: 3rem !important;
		margin-left: 1.5rem !important;
		margin-right: 1.5rem !important;
	}
`;
export const StepCardBox = styled.div`
	background-color: ${themeGet('colors.background.light')};
	display: flex;
	align-items: center;
	padding: 1.5rem;
	border-radius: 4px;
	box-shadow: 0 0 5px 0 rgb(75 85 110 / 8%);
`;
export const StepCardBoxGfx = styled.div`
	position: relative;
	background: #fff;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	border: 2px solid #8cb2ff;
	flex-shrink: 0;
	margin-right: 1.5rem;
	img {
		max-width: 100%;
		height: auto;
		vertical-align: middle;
		border-style: none;
	}
`;
export const StepCardBoxContent = styled.div`
	h6 {
		color: ${themeGet('colors.text.bold')};
		margin-bottom: 0.25rem;
		font-size: 1rem;
		font-weight: 700;
	}
	p {
		@media (min-width: 576px) {
			color: ${themeGet('colors.text.medium')};
			line-height: 1.5;
			margin-bottom: 0;
		}
	}
`;
