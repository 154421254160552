import {
	AboutCardContent,
	AboutSectionLead,
	AboutSectionList,
	AboutSectionListLink,
	AboutSectionNavigation,
	AboutSectionParagraph,
	AboutSubtitle,
	AboutTextBlock,
	AboutTitle,
	MarginRightLg5,
} from './about-card.style';
import {
	ColLg6,
	HomeContainer,
	HomePageImage,
	HomeRow,
	HomeSection,
} from 'features/homepage/homepage.style';
import {ArrowNext} from '../../../assets/icons/ArrowNext';
import {
	ABOUT_US_PAGE,
	CONTACT_US_PAGE,
	COUPONS_PAGE,
	HELP_PAGE,
} from '../../../site-settings/site-navigation';

export const AboutCard = () => {
	return (
		<HomeSection>
			<HomeContainer>
				<AboutCardContent>
					<HomeRow>
						<ColLg6>
							<MarginRightLg5>
								<HomePageImage src="http://demo.themenio.com/kovid19/images/gfx/gfx-a.png" />
							</MarginRightLg5>
						</ColLg6>
						<ColLg6>
							<AboutTextBlock>
								<AboutSubtitle>About Us</AboutSubtitle>
								<AboutTitle>Yıkama Yeri</AboutTitle>
								<AboutSectionLead>
									<strong>
										COVID-19 is a new illness that can affect your lungs and
										airways.
									</strong>{' '}
									It's caused by a virus called coronavirus. It was discovered
									in December 2019 in Wuhan, Hubei, China.
								</AboutSectionLead>
								<AboutSectionParagraph>
									Common signs of infection include respiratory symptoms, fever,
									cough, shortness of breath and breathing difficulties. In more
									severe cases, infection can cause pneumonia, severe acute
									respiratory syndrome, kidney failure and even death.
								</AboutSectionParagraph>
								<AboutSectionNavigation>
									<AboutTitle>Hakkımızda</AboutTitle>
									<AboutSectionList>
										<li>
											<AboutSectionListLink to={HELP_PAGE}>
												<span>Sık Sorulan Sorular</span>
												<ArrowNext />
											</AboutSectionListLink>
										</li>
										<li>
											<AboutSectionListLink to={ABOUT_US_PAGE}>
												<span>Hakkımızda</span>
												<ArrowNext />
											</AboutSectionListLink>
										</li>
										<li>
											<AboutSectionListLink to={CONTACT_US_PAGE}>
												<span>İletişim</span>
												<ArrowNext />
											</AboutSectionListLink>
										</li>
										<li>
											<AboutSectionListLink to={COUPONS_PAGE}>
												<span>İndirim Kuponlarımız</span>
												<ArrowNext />
											</AboutSectionListLink>
										</li>
									</AboutSectionList>
								</AboutSectionNavigation>
							</AboutTextBlock>
						</ColLg6>
					</HomeRow>
				</AboutCardContent>
			</HomeContainer>
		</HomeSection>
	);
};
