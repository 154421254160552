import React, {useReducer} from 'react';
import {ServiceContext} from './service.context';

const INITIAL_STATE = {
	category: null,
	neighbourhood: null,
	categoryService: {
		loading: false,
		data: [],
	},
};
function reducer(state, action) {
	switch (action.type) {
		case 'SET_CATEGORY':
			return {
				...state,
				category: action.payload ?? 'hali-yikama',
			};
		case 'SET_NEIGHBOURHOOD':
			return {
				...state,
				neighbourhood: action.payload,
			};
		case 'SET_CATEGORY_SERVICE_LOADING':
			return {
				...state,
				categoryService: {
					loading: action.payload,
					data: state.categoryService.data,
				},
			};
		case 'SET_CATEGORY_SERVICE_DATA':
			return {
				...state,
				categoryService: {
					loading: state.categoryService.loading,
					data: action.payload,
				},
			};
		default:
			return state;
	}
}
export const ServiceProvider = ({children}) => {
	const [serviceState, serviceDispatch] = useReducer(reducer, INITIAL_STATE);
	return (
		<ServiceContext.Provider value={{serviceState, serviceDispatch}}>
			{children}
		</ServiceContext.Provider>
	);
};
