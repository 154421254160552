var days = [
	'Pazar',
	'Pazartesi',
	'Salı',
	'Çarşamba',
	'Perşembe',
	'Cuma',
	'Cumartesi',
];
const today = new Date();

const tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);

const nextDay = new Date();
nextDay.setDate(today.getDate() + 2);

// eslint-disable-next-line import/no-anonymous-default-export
export default [
	{
		id: 0,
		type: 'secondary',
		title: 'Gün İçerisinde',
		time_slot: "14.00'a kadar verilen siparişler",
		value: today.toLocaleDateString('tr-TR') + ', 14.00 dan sonra',
	},
	{
		id: 1,
		type: 'secondary',
		title: days[tomorrow.getDay()],
		time_slot: "13.00'dan önce teslimat yapılacaktır",
		value: tomorrow.toLocaleDateString('tr-TR') + ', 13.00 dan önce',
	},
	{
		id: 2,
		type: 'secondary',
		title: days[tomorrow.getDay()],
		time_slot: "13.00'dan sonra teslimat yapılacaktır",
		value: tomorrow.toLocaleDateString('tr-TR') + ', 13.00 dan sonra',
	},
	{
		id: 3,
		type: 'secondary',
		title: days[nextDay.getDay()],
		time_slot: "13.00'dan önce teslimat yapılacaktır",
		value: nextDay.toLocaleDateString('tr-TR') + ', 13.00 dan önce',
	},
	{
		id: 4,
		type: 'secondary',
		title: days[nextDay.getDay()],
		time_slot: "13.00'dan sonra teslimat yapılacaktır",
		value: nextDay.toLocaleDateString('tr-TR') + ', 13.00 dan sonra',
	},
	{
		id: 5,
		type: 'secondary',
		title: 'İletişime Geçilsin',
		time_slot: 'Ekibimiz en kısa süre içerisinde sizi arayacaktır.',
		value: 'İletişime geç',
	},
];
