export const SofaIcon = props => {
	return (
		<svg width="14.4" height="12" viewBox="0 0 512 512" {...props}>
			<path d="M460.39111,168A35.5522,35.5522,0,0,0,440,174.37036V160a40.04521,40.04521,0,0,0-40-40H112a40.04521,40.04521,0,0,0-40,40v14.37036a35.6593,35.6593,0,0,0-56,29.23853v.17773a35.56637,35.56637,0,0,0,1.62109,10.62109L49.34717,315.93115a39.81463,39.81463,0,0,0,37.2124,28.04956l-6.45068,38.70435A8.00022,8.00022,0,0,0,88,392h16a7.99943,7.99943,0,0,0,6.65625-3.5625L140.28125,344h231.4375l29.625,44.4375A7.99943,7.99943,0,0,0,408,392h16a8.00022,8.00022,0,0,0,7.89111-9.31494l-6.45068-38.70435a39.81463,39.81463,0,0,0,37.2124-28.04956l31.72608-101.52344A35.56637,35.56637,0,0,0,496,203.78662v-.17773A35.64927,35.64927,0,0,0,460.39111,168ZM112,136H400a24.0275,24.0275,0,0,1,24,24v40.79675l-13.803,44.85974A31.978,31.978,0,0,0,384,232H280a31.92485,31.92485,0,0,0-24,10.86768A31.92485,31.92485,0,0,0,232,232H128a31.978,31.978,0,0,0-26.197,13.65649L88,200.79675V160A24.0275,24.0275,0,0,1,112,136ZM248,272H112v-8a16.01833,16.01833,0,0,1,16-16H232a16.01833,16.01833,0,0,1,16,16Zm152,0H264v-8a16.01833,16.01833,0,0,1,16-16H384a16.01833,16.01833,0,0,1,16,16ZM99.71875,376h-2.2749l5.33313-32h18.275Zm312.5625,0L390.948,344h18.275l5.33313,32ZM480,203.78662a19.58885,19.58885,0,0,1-.89258,5.84863L447.38135,311.15869A23.89673,23.89673,0,0,1,424.47412,328H87.52588a23.89673,23.89673,0,0,1-22.90723-16.84131L32.89258,209.63525A19.58885,19.58885,0,0,1,32,203.78662v-.17773a19.60892,19.60892,0,0,1,38.35059-5.76709L96.354,282.35254A7.99934,7.99934,0,0,0,104,288H408a7.99934,7.99934,0,0,0,7.646-5.64746l26.00341-84.51074A19.60892,19.60892,0,0,1,480,203.60889Z" />
			<rect x="136" y="176" width="16" height="16" />
			<rect x="192" y="176" width="16" height="16" />
			<rect x="248" y="176" width="16" height="16" />
			<rect x="304" y="176" width="16" height="16" />
			<rect x="360" y="176" width="16" height="16" />
		</svg>
	);
};
