import React from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorker from './serviceWorker';
import store from './store.js';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {AuthProvider} from './contexts/auth/auth.provider';
import {AppProvider} from './contexts/app/app.provider';
import {CartProvider} from 'contexts/cart/use-cart';
import {ThemeProvider} from 'styled-components';
import {defaultTheme} from './site-settings/site-theme/default';
import Layout from './layouts/AppLayout/AppLayout';
import {Routes} from './routes/routes';
import {MainContentArea} from './assets/styles/pages.style';

//external CSS
import 'swiper/swiper-bundle.min.css';
import 'rc-drawer/assets/index.css';
import 'rc-collapse/assets/index.css';
import 'react-multi-carousel/lib/styles.css';
import 'components/multi-carousel/multi-carousel.style.css';
import 'react-spring-modal/styles.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import 'components/scrollbar/scrollbar.css';
import 'react-toastify/dist/ReactToastify.css';
import '@redq/reuse-modal/lib/index.css';

import {GlobalStyle} from './assets/styles/global.style';

import 'typeface-lato';
import 'typeface-poppins';
import Toastr from './features/toastr/toastr';
import {ProfileProvider} from './contexts/profile/profile.provider';
import WithAxios from './contexts/axios/WithAxios';
import ScrollToTop from './utils/scroll-to-top';

ReactDOM.render(
	<ThemeProvider theme={defaultTheme}>
		<AuthProvider>
			<WithAxios>
				<CartProvider>
					<AppProvider>
						<ProfileProvider>
							<Router>
								<ScrollToTop />
								<Provider store={store}>
									<Layout>
										<Routes />
									</Layout>
									<Toastr />
									<MainContentArea />
								</Provider>
							</Router>
						</ProfileProvider>
					</AppProvider>
				</CartProvider>
			</WithAxios>
		</AuthProvider>
		<GlobalStyle />
	</ThemeProvider>,

	document.getElementById('root'),
);
// serviceWorker.unregister();
