import React, {useRef, useState, useEffect} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import {
	GiftCardWrapper,
	GiftCardImageWrapper,
	CardInfo,
	CardContent,
	CopyButton,
	GiftCode,
	CopySuccess,
} from './gift-card.style';

export const GiftCard = ({
	image,
	weight,
	onClick,
	onChange,
	code,
	...props
}) => {
	const [copyText, setCopyText] = useState({
		value: code,
		copied: false,
	});
	const codeRef = useRef(null);

	useEffect(() => {
		if (copyText.copied) {
			setTimeout(() => {
				setCopyText({
					...copyText,
					copied: false,
				});
			}, 3500);
		}
	}, [copyText, copyText.copied]);

	return (
		<GiftCardWrapper {...props} className="product-card">
			<GiftCardImageWrapper>
				<img src={image} className="gift-image" alt="Yıkama Yeri Coupon" />
			</GiftCardImageWrapper>
			<CardInfo>
				<CardContent>
					<GiftCode ref={codeRef} value={copyText.value} readOnly />

					{!copyText.copied ? (
						<CopyToClipboard
							text={copyText.value}
							onCopy={() =>
								setCopyText({
									...copyText,
									copied: true,
								})
							}
						>
							<CopyButton>Kopyala</CopyButton>
						</CopyToClipboard>
					) : (
						<CopySuccess>Kopyalandı</CopySuccess>
					)}
				</CardContent>
			</CardInfo>
		</GiftCardWrapper>
	);
};
