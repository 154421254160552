import React from 'react';
import Table from 'rc-table';
import {
	DeliveryInfo,
	DeliveryAddress,
	Address,
	CostCalculation,
	PriceRow,
	Price,
	ProgressWrapper,
	OrderTableWrapper,
	OrderTable,
} from './order-details.style';
import Progress from 'components/progress-box/progress-box';
import {CURRENCY} from 'utils/constant';

const components = {
	table: OrderTable,
};

const OrderDetails = ({
	tableData,
	columns,
	address,
	progressStatus,
	progressData,
	subtotal,
	discount,
	grandTotal,
}) => {
	return (
		<>
			<DeliveryInfo>
				<DeliveryAddress>
					<h3>Teslimat Adresi</h3>
					<Address>{address}</Address>
				</DeliveryAddress>

				<CostCalculation>
					<PriceRow>
						Ara Toplam
						<Price>
							{CURRENCY}
							{subtotal}
						</Price>
					</PriceRow>
					<PriceRow>
						İndirim
						<Price>
							{CURRENCY}
							{discount?.toFixed(2)}
						</Price>
					</PriceRow>
					<PriceRow className="grandTotal">
						Toplam
						<Price>
							{CURRENCY}
							{grandTotal}
						</Price>
					</PriceRow>
				</CostCalculation>
			</DeliveryInfo>

			<ProgressWrapper>
				<Progress data={progressData} status={progressStatus} />
			</ProgressWrapper>

			<OrderTableWrapper>
				<Table
					columns={columns}
					data={tableData}
					rowKey={record => record.id}
					components={components}
					className="orderDetailsTable"
					// scroll={{ y: 350 }}
				/>
			</OrderTableWrapper>
		</>
	);
};

export default OrderDetails;
