export const initialState = {
	categories: {
		loading: false,
		data: [],
	},
	searchCategory: null,
	searchNeighbourhood: null,
	userAddresses: {
		loading: false,
		data: null,
	},
	isSticky: false,
	isContentSticky: true,
	isSidebarSticky: true,
	isDrawerOpen: false,
	loading: false,
};

export function appReducer(state, action) {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'SET_USER_ADDRESSES_DATA':
			return {
				...state,
				userAddresses: {
					loading: state.userAddresses.loading,
					data: action.payload,
				},
			};
		case 'SET_USER_ADDRESSES_LOADING':
			return {
				...state,
				userAddresses: {
					loading: action.payload,
					data: state.userAddresses.data,
				},
			};
		case 'SET_CATEGORY_DATA':
			return {
				...state,
				categories: {
					loading: state.categories.loading,
					data: action.payload,
				},
			};
		case 'SET_CATEGORY_LOADING':
			return {
				...state,
				categories: {
					loading: action.payload,
					data: state.categories.data,
				},
			};
		case 'SET_SEARCH_CATEGORY':
			return {
				...state,
				searchCategory: action.payload,
			};
		case 'SET_SEARCH_NEIGHBOURHOOD':
			return {
				...state,
				searchNeighbourhood: action.payload,
			};
		case 'SET_STICKY':
			return {
				...state,
				isSticky: true,
			};
		case 'SET_CONTENT_STICKY':
			return {
				...state,
				isContentSticky: action.payload,
			};
		case 'REMOVE_STICKY':
			return {
				...state,
				isSticky: false,
			};
		case 'SET_SIDEBAR_STICKY':
			return {
				...state,
				isSidebarSticky: true,
			};
		case 'REMOVE_SIDEBAR_STICKY':
			return {
				...state,
				isSidebarSticky: false,
			};
		case 'TOGGLE_DRAWER':
			return {
				...state,
				isDrawerOpen: !state.isDrawerOpen,
			};
		default: {
			throw new Error(`Unsupported action type at App Reducer`);
		}
	}
}
