import React, {useContext} from 'react';
import {ProfileContext} from 'contexts/profile/profile.context';
import {CardHeader} from 'components/card-header/card-header';
import {ButtonGroup} from 'components/button-group/button-group';
import RadioGroup from 'components/radio-group/radio-group';
import RadioCard from 'components/radio-card/radio-card';

const PaymentMethod = ({increment = false}) => {
	const {
		state: {payment_methods},
		dispatch,
	} = useContext(ProfileContext);
	return (
		<>
			<CardHeader increment={increment}>
				Lütfen ödeme yönteminizi seçiniz
			</CardHeader>
			<ButtonGroup>
				<RadioGroup
					items={payment_methods ?? []}
					component={item => (
						<RadioCard
							id={item.id}
							key={item.id}
							title={item.title}
							name="payment_method"
							checked={item.type === 'primary'}
							withActionButtons={false}
							onChange={() =>
								dispatch({
									type: 'SET_PRIMARY_PAYMENT_METHOD',
									payload: parseInt(item.id),
								})
							}
						/>
					)}
				/>
			</ButtonGroup>
		</>
	);
};

export default PaymentMethod;
