import {useState, useEffect} from 'react';
import {SEO} from '../../components/seo/seo';
import {BlogProvider} from '../../contexts/blog/blog.provider';
import {BlogDetail} from '../../features/blog/blog-detail';
import {useHistory, useParams} from 'react-router-dom';
import useBlog from '../../services/use-blog';
import {toast} from 'react-toastify';
import Loader from '../../components/loader/loader';
import Footer from '../../layouts/footer/footer';
import {LoaderContainer} from '../../assets/styles/pages.style';

export const BlogPostPage = () => {
	const {slug} = useParams();
	const [loading, setLoading] = useState(true);
	const [post, setPost] = useState(null);
	const blogService = useBlog();
	const router = useHistory();
	const onError = message => {
		if (message) toast(message, {type: 'warning'});
		router.push('/blog');
	};
	useEffect(() => {
		blogService
			.post(slug)
			.then(
				res => {
					if (res.success) {
						setPost(res.data);
					} else {
						onError(res.message);
					}
				},
				err => {
					onError();
				},
			)
			.then(() => setLoading(false));
		// eslint-disable-next-line
	}, [slug]);
	return (
		<>
			<SEO
				title={post ? post.title : ''}
				description={post ? post.description : ''}
			/>
			<BlogProvider>
				{loading ? (
					<LoaderContainer>
						<Loader />
					</LoaderContainer>
				) : (
					<BlogDetail post={post} />
				)}
				<Footer />
			</BlogProvider>
		</>
	);
};
