import React from 'react';
export const FruitsVegetable = ({
	color = 'currentColor',
	width = '18px',
	height = '18px',
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 18 18"
		>
			<g data-name="Layer 2">
				<g data-name="Layer 3">
					<path
						data-name="Path 16"
						d="M10.235 3.966a2.943 2.943 0 00-1.38-2.122c-.528-.243-.485-.618-.338-.854s.41-.231.832.164a5 5 0 011.368 2.87z"
						fill={color}
						stroke={color}
						strokeMiterlimit="10"
						strokeWidth=".416"
					/>
					<path
						data-name="Path 17"
						d="M6.514 7.976a4.757 4.757 0 109.513 0c0-2.627-1.5-4.976-4.757-4.73-2.619.197-4.767 1.656-4.756 4.73z"
						fill="none"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.387"
					/>
					<path
						data-name="Path 18"
						d="M6.514 7.976a4.757 4.757 0 109.513 0c0-2.627-1.5-4.976-4.757-4.73-2.619.197-4.767 1.656-4.756 4.73z"
						fill={color}
					/>
					<path
						data-name="Path 19"
						d="M14.731 5.045s1.506 1.544.714 2.993c-.287.526-1.2.192-1.234-.485s.25-1.27-.236-2.05c-.349-.566.26-.878.756-.458z"
						fill="#fff"
					/>
					<path
						data-name="Path 20"
						d="M10.834 3.413s1.161-4.315 6.469-2.048c0 0-2.459 4.074-6.469 2.048z"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.387"
					/>
					<path
						data-name="Path 21"
						d="M10.834 3.413s1.161-4.315 6.469-2.048c0 0-2.459 4.074-6.469 2.048z"
						fill="#fff"
					/>
					<path
						data-name="Path 22"
						d="M10.516 4.513a10.193 10.193 0 015.63-2.863c-2.736-.521-6.018 1.748-6.018 1.748s.289.864.388 1.115z"
						fill={color}
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth=".035"
					/>
					<path
						data-name="Path 23"
						d="M10.895 9.896h0c-.268-.877-1.969-1.65-4.234-1.72a5.286 5.286 0 00-4.515 1.858 2 2 0 00-.551 1.308h0a4.934 4.934 0 004.768 4.426c3.133.223 3.786-.96 4.225-1.9a6.363 6.363 0 00.307-3.972z"
						fill="#fff"
						stroke="#fff"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="3.19"
					/>
					<path
						data-name="Path 24"
						d="M10.895 9.896h0c-.268-.877-1.969-1.65-4.234-1.72a5.286 5.286 0 00-4.515 1.858 2 2 0 00-.551 1.308h0a4.934 4.934 0 004.768 4.426c3.133.223 3.786-.96 4.225-1.9a6.363 6.363 0 00.307-3.972z"
						fill="none"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.387"
					/>
					<path
						data-name="Path 25"
						d="M10.895 9.896h0c-.268-.877-1.969-1.65-4.234-1.72a5.286 5.286 0 00-4.515 1.858 2 2 0 00-.551 1.308h0a4.934 4.934 0 004.768 4.426c3.133.223 3.786-.96 4.225-1.9a6.363 6.363 0 00.307-3.972z"
						fill="#fff"
						stroke={color}
						strokeMiterlimit="10"
						strokeWidth=".069"
					/>
					<path
						data-name="Path 26"
						d="M10.895 9.896c-.268-.877-1.969-1.65-4.234-1.72a5.286 5.286 0 00-4.515 1.858 2 2 0 00-.551 1.308 4.934 4.934 0 004.768 4.426c3.133.223 3.786-.96 4.225-1.9a6.363 6.363 0 00.307-3.972z"
						fill={color}
					/>
					<path
						data-name="Path 27"
						d="M1.595 11.342a4.375 4.375 0 00.128.684 2.664 2.664 0 00.3.335c1.067 1.028 4.409 1.723 7.173 1 .883-.23 1.522-1.3.526-1.851-.139-.076-.243-.184-.208-.277s.089-.106.269-.1a1.2 1.2 0 001.232-.735 4.126 4.126 0 00-.121-.509c-.272-.876-1.974-1.644-4.239-1.715a5.286 5.286 0 00-4.515 1.858 2.024 2.024 0 00-.545 1.31z"
						fill="#fff"
					/>
					<path
						data-name="Path 28"
						d="M1.921 9.984a1.569 1.569 0 00.1 2.377c1.066 1.028 4.409 1.723 7.173 1 .883-.23 1.522-1.3.526-1.851-.139-.076-.243-.184-.208-.277s.089-.106.269-.1a1.183 1.183 0 001.259-.823 1.321 1.321 0 00-.785-1.413"
						fill="none"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth=".416"
					/>
					<path
						data-name="Path 29"
						d="M8.013 10.78c-.018.471-.87.791-1.9.752s-1.856-.423-1.837-.9.87-.963 1.9-.924 1.855.605 1.837 1.072z"
						fill={color}
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth=".555"
					/>
					<path
						data-name="Path 30"
						d="M9.722 12.123a2.411 2.411 0 01.467 2.08 2.759 2.759 0 01-1.5 2.427"
						fill="none"
						stroke="#fff"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth=".555"
					/>
				</g>
			</g>
		</svg>
	);
};
