import React from 'react';
import {Helmet} from 'react-helmet';

export const SEO = ({title, description, canonical, css, js, image}) => {
	if (!description) {
		description =
			"Türkiye'nin En Kapsamlı ve Online Yıkama Hizmetleri kategorisinde; alanında anlaşmalı en iyi firmalar ile müşterileri buluşturan online hizmet platformudur.";
	}
	return (
		<Helmet>
			<title>{title ? title + ' | ' : ''} Yıkama Yeri</title>
			<meta name="description" content={description} />
			<meta
				name="viewport"
				content="width=device-width,minimum-scale=1,initial-scale=1"
			/>
			<meta property="og:type" content="website" />
			<meta
				name="og:title"
				property="og:title"
				content={title ? title + ' | Yıkama Yeri' : 'Yıkama Yeri'}
			/>
			<meta
				name="og:description"
				property="og:description"
				content={description}
			/>
			<meta property="og:site_name" content="Yıkama Yeri" />
			<meta property="og:url" content={`${canonical}`} />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:site" content="@yikamayeri" />
			<meta name="twitter:creator" content="@yikamayeri" />

			{css && <link rel="stylesheet" href={`${css}`} />}
			{image ? (
				<meta property="og:image" content={`${image}`} />
			) : (
				<meta
					property="og:image"
					content="https://yikamayeri.com/app/images/favicon.png"
				/>
			)}
			{image && <meta name="twitter:image" content={`${image}`} />}
			{canonical && <link rel="canonical" href={`${canonical}`} />}
			{js && <script type="text/javascript" src={`${js}`} />}
		</Helmet>
	);
};
