import styled from 'styled-components';
import Logo from 'assets/images/logo.png';
const SplashContainer = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		max-width: 20vw;
		height: auto;
	}
	@media only screen and (max-width: 1199px) {
		img {
			max-width: 60vw;
		}
	}
`;

export const SplashScreen = () => {
	return (
		<SplashContainer>
			<img src={Logo} alt="Yıkama Yeri" />
		</SplashContainer>
	);
};
