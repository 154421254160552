import React, {useState, useEffect} from 'react';
import {SEO} from '../../components/seo/seo';
import {Modal} from '@redq/reuse-modal';
import Footer from '../../layouts/footer/footer';
import {useLocation} from 'react-router-dom';
import parseQueryString from '../../utils/parseQueryString';
import {toast} from 'react-toastify';
import {
	Col12,
	Heading,
	HomeContainer,
	HomeRow,
	HomeSection,
	SubHeading,
} from './email-verification.style';
import useUser from '../../services/use-user';
import {LoaderContainer} from '../../assets/styles/pages.style';
import Loader from '../../components/loader/loader';

export const EmailVerificationPage = () => {
	const location = useLocation();
	const params = parseQueryString(location.search);
	const [message, setMessage] = useState('Kontrol Ediliyor...');
	const [loading, setLoading] = useState(false);
	const userService = useUser();
	useEffect(() => {
		if (!params.email || !params.verification_code) {
			setMessage('Link geçerli değil!');
			toast(message, {type: 'error'});
		} else {
			setLoading(true);
			userService
				.verifyEmailVerification({
					email: params.email,
					code: params.verification_code,
				})
				.then(
					res => {
						if (!res.success) {
							toast(res.message, {type: 'warning'});
						} else {
							toast(res.message, {type: 'info'});
						}
						setMessage(res.message);
					},
					err => {
						if (err.response.data && err.response.data.message) {
							toast(err.response.data.message, {type: 'error'});
							setMessage(err.response.data.message);
						} else {
							setMessage('Bir hata oluştu!');
							toast(message, {type: 'error'});
						}
					},
				)
				.then(() => setLoading(false));
		}
		// eslint-disable-next-line
	}, []);
	return (
		<>
			<SEO title="Şifremi Sıfırla" description="Yıkama Yeri Şifremi Sıfırla" />
			<Modal>
				{loading ? (
					<LoaderContainer>
						<Loader />
					</LoaderContainer>
				) : (
					<HomeSection>
						<HomeContainer>
							<HomeRow>
								<Col12>
									<Heading>Email Doğrulaması</Heading>
									<SubHeading>{message}</SubHeading>
								</Col12>
							</HomeRow>
						</HomeContainer>
					</HomeSection>
				)}
				<Footer />
			</Modal>
		</>
	);
};
