import {Field} from 'model-react';
import {Address} from 'models/Address';
import {Phone} from './Phone';

class User {
	constructor({
		id,
		firstname,
		lastname,
		email,
		email_verified,
		logo,
		address,
		phone,
		status,
		created_at,
		updated_at,
	}) {
		this.id = new Field(parseInt(id));
		this.firstname = new Field(firstname);
		this.lastname = new Field(lastname);
		this.email = new Field(email);
		this.email_verified = new Field(email_verified);
		this.logo = new Field(logo);
		if (address && typeof address === 'object') {
			const list = [];
			address.forEach(item => list.push(new Field(new Address(item))));
			this.address = new Field(list);
		} else {
			this.address = new Field([]);
		}
		if (phone && typeof phone === 'object') {
			const list = [];
			phone.forEach(item => list.push(new Field(new Phone(item))));
			this.phone = new Field(phone);
		} else {
			this.phone = new Field([]);
		}
		this.status = new Field(status);
		this.created_at = new Field(created_at);
		this.updated_at = new Field(updated_at);
	}
	getId(h) {
		return this.id.get(h);
	}
	setId(id) {
		return this.id.set(id);
	}
	getFirstname(h) {
		return this.firstname.get(h);
	}
	setFirstname(firstname) {
		this.firstname.set(firstname);
	}
	getLastname(h) {
		return this.lastname.get(h);
	}
	setLastname(lastname) {
		this.lastname.set(lastname);
	}
	getEmail(h) {
		return this.email.get(h);
	}
	setEmail(email) {
		this.email.set(email);
	}
	getEmailVerified(h) {
		return this.email_verified.get(h);
	}
	setEmailVerified(val) {
		this.email_verified.set(val);
	}
	getLogo(h) {
		return this.logo.get(h);
	}
	setLogo(val) {
		this.logo.set(val);
	}
	getAddress(h) {
		return this.address.get(h);
	}
	setAddress(val) {
		this.address.set(val);
	}
	getPhone(h) {
		return this.phone.get(h);
	}
	setPhone(val) {
		this.phone.set(val);
	}
	getStatus(h) {
		return this.status.get(h);
	}
	setStatus(val) {
		this.status.set(val);
	}
	getCreatedAt(h) {
		return this.created_at.get(h);
	}
	getUpdatedAt(h) {
		return this.updated_at.get(h);
	}
}

export {User};
