import axios from 'axios';
import {toast} from 'react-toastify';

const baseURL =
	!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
		? 'http://localhost/clients/yikamayeri/api/'
		: 'https://api.yikamayeri.ogun.me/api/';
// const baseURL = 'http://localhost/clients/yikamayeri/api/';
// const baseURL = 'https://api.yikamayeri.ogun.me/api/';

const api = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
	withCredentials: true,
});

api.interceptors.request.use(config => {
	const access_token = localStorage.getItem('access_token');
	if (access_token) {
		config.headers['Authorization'] = 'Bearer ' + access_token;
	}
	return config;
});
export const Initialize = ({authDispatch}) => {
	// eslint-disable-next-line no-unused-vars
	api.interceptors.response.use(
		response => {
			if (response.headers.token && response.headers.token.length > 0) {
				if (response.headers.token === 'false') {
					localStorage.removeItem('access_token');
					authDispatch({
						type: 'SET_USER',
						payload: null,
					});
					toast('Çıkış yaptınız.', {type: 'info'});
				} else {
					localStorage.setItem('access_token', response.headers.token);
				}
			}
			return response;
		},
		failed => {
			return Promise.reject(failed);
		},
	);
};

export default api;
