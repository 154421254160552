export const WashingMachineIcon = props => {
	return (
		<svg width="14.4" height="12" viewBox="0 0 512 512" {...props}>
			<g>
				<path d="m122 179.999h15v167.001h-15z" />
				<path d="m477.606 0h-321.213l-34.393 34.394v130.605h15v-30h360v332.001h-67.5v15h56.894l-15 15h-274.395v15h280.607l34.394-34.394v-443.212zm-315 15h308.788l15 15h-338.788zm-25.606 104.999v-74.999h360v74.999z" />
				<path d="m184.5 315.999c0 73.061 59.439 132.5 132.5 132.5s132.499-59.439 132.499-132.5-59.438-132.499-132.499-132.499-132.5 59.438-132.5 132.499zm249.999 0c0 64.79-52.71 117.5-117.499 117.5-64.79 0-117.5-52.71-117.5-117.5s52.71-117.499 117.5-117.499c64.789 0 117.499 52.709 117.499 117.499z" />
				<path d="m214.45 315.999c0 56.546 46.004 102.55 102.55 102.55 24.397 0 48.038-8.716 66.568-24.543l-9.742-11.405c-15.816 13.509-35.998 20.948-56.826 20.948-48.275 0-87.55-39.274-87.55-87.55 0-2.527.114-5.027.325-7.5h69.119l29.999 30h72.705c-3.341 12.562-9.449 24.319-17.997 34.327l11.406 9.742c15.826-18.529 24.542-42.17 24.542-66.569 0-56.546-46.003-102.549-102.549-102.549s-102.55 46.003-102.55 102.549zm187.163-22.499h-50.741v15h53.352c.211 2.473.325 4.973.325 7.5 0 2.512-.114 5.014-.328 7.5h-69.116l-29.999-30h-72.72c9.956-37.407 44.115-65.05 84.614-65.05 40.498 0 74.657 27.643 84.613 65.05z" />
				<path d="m459.5 60c-12.406 0-22.5 10.093-22.5 22.5s10.094 22.5 22.5 22.5 22.5-10.094 22.5-22.5-10.094-22.5-22.5-22.5zm0 30c-4.136 0-7.5-3.364-7.5-7.5s3.364-7.5 7.5-7.5 7.5 3.364 7.5 7.5-3.364 7.5-7.5 7.5z" />
				<path d="m399.501 60c-12.406 0-22.5 10.093-22.5 22.5s10.094 22.5 22.5 22.5 22.5-10.093 22.5-22.5-10.094-22.5-22.5-22.5zm0 30c-4.136 0-7.5-3.364-7.5-7.5s3.364-7.5 7.5-7.5 7.5 3.364 7.5 7.5-3.364 7.5-7.5 7.5z" />
				<path d="m339.502 60c-12.406 0-22.5 10.093-22.5 22.5s10.094 22.5 22.5 22.5 22.5-10.093 22.5-22.5-10.094-22.5-22.5-22.5zm0 30c-4.136 0-7.5-3.364-7.5-7.5s3.364-7.5 7.5-7.5 7.5 3.364 7.5 7.5-3.364 7.5-7.5 7.5z" />
				<path d="m152 105h104.999v-45h-104.999zm15-30h74.999v15h-74.999z" />
				<path d="m19.394 362-19.394 19.393v21.213l11.893 11.894-11.893 11.893v21.213l11.893 11.894-11.893 11.893v21.213l19.394 19.394h143.212l19.394-19.394v-21.213l-11.894-11.893 11.894-11.894v-21.213l-11.894-11.893 11.894-11.894v-21.213l-19.394-19.393zm147.606 124.394-10.607 10.606h-130.787l-10.606-10.606v-8.788l10.606-10.606h26.894v-15h-26.894l-10.606-10.606v-8.788l10.606-10.606h130.787l10.607 10.606v8.788l-10.607 10.606h-88.893v15h88.894l10.606 10.606zm0-90-10.607 10.606h-130.787l-10.606-10.606v-8.788l10.606-10.606h130.787l10.607 10.606z" />
				<path d="m226.999 474.499h15v15h-15z" />
				<path d="m392.001 149.999h15v15h-15z" />
				<path d="m196.999 474.499h15v15h-15z" />
				<path d="m422.001 149.999h59.999v15h-59.999z" />
			</g>
		</svg>
	);
};
