import React, {useContext, useState} from 'react';
import {
	CartPopupBody,
	PopupHeader,
	PopupItemCount,
	CloseButton,
	PromoCode,
	CheckoutButtonWrapper,
	CheckoutButton,
	Title,
	PriceBox,
	NoProductMsg,
	NoProductImg,
	ItemWrapper,
	CouponBoxWrapper,
	CouponCode,
} from './cart.style';
import {CloseIcon} from 'assets/icons/CloseIcon';
import {ShoppingBagLarge} from 'assets/icons/ShoppingBagLarge';
import {NoCartBag} from 'assets/icons/NoCartBag';
import {CURRENCY} from 'utils/constant';
import {Scrollbar} from 'components/scrollbar/scrollbar';
import {useCart} from 'contexts/cart/use-cart';
import {CartItem} from 'components/cart-item/cart-item';
import Coupon from 'features/coupon/coupon';
import {Link} from 'react-router-dom';
import {AuthContext} from '../../contexts/auth/auth.context';

const Cart = ({style, className, onCloseBtnClick, scrollbarHeight}) => {
	const {
		items,
		coupon,
		addItem,
		removeItem,
		removeItemFromCart,
		cartItemsCount,
		calculatePrice,
	} = useCart();
	const [hasCoupon, setCoupon] = useState(false);
	const {authState} = useContext(AuthContext);
	return (
		<CartPopupBody className={className} style={style}>
			<PopupHeader>
				<PopupItemCount>
					<ShoppingBagLarge width="19px" height="24px" />
					<span>
						{cartItemsCount}
						&nbsp; Ürün
					</span>
				</PopupItemCount>
				<CloseButton onClick={onCloseBtnClick}>
					<CloseIcon />
				</CloseButton>
			</PopupHeader>

			<Scrollbar className="cart-scrollbar">
				<ItemWrapper className="items-wrapper">
					{!!cartItemsCount ? (
						items.map(item => (
							<CartItem
								key={`cartItem-${item.id}${item.m2 ? '-' + item.m2.value : ''}`}
								onIncrement={() => addItem(item)}
								onDecrement={() => removeItem(item)}
								onRemove={() => removeItemFromCart(item)}
								data={item}
							/>
						))
					) : (
						<>
							<NoProductImg>
								<NoCartBag />
							</NoProductImg>
							<NoProductMsg>Hiç Ürün Bulunamadı</NoProductMsg>
						</>
					)}
				</ItemWrapper>
			</Scrollbar>

			<CheckoutButtonWrapper>
				<PromoCode>
					{coupon ? (
						<CouponCode>
							Kupon Kodu Uygulandı
							<span>{coupon.code}</span>
						</CouponCode>
					) : (
						<>
							{!hasCoupon ? (
								<button onClick={() => setCoupon(prev => !prev)}>
									Kupon Kodunuz Mu Var ?
								</button>
							) : (
								<CouponBoxWrapper>
									<Coupon
										disabled={!items.length}
										style={{
											boxShadow: '0 3px 6px rgba(0, 0, 0, 0.06)',
										}}
									/>
								</CouponBoxWrapper>
							)}
						</>
					)}
				</PromoCode>

				{cartItemsCount !== 0 || !authState.user ? (
					<Link to={authState.user ? '/checkout' : '/login'}>
						<CheckoutButton onClick={onCloseBtnClick}>
							<Title className="cart-title-span">
								{authState.user ? 'Sipariş Ver' : 'Giriş Yap'}
							</Title>
							<PriceBox>
								{calculatePrice()}
								{CURRENCY}
							</PriceBox>
						</CheckoutButton>
					</Link>
				) : (
					<CheckoutButton style={{cursor: 'pointer', pointerEvents: 'none'}}>
						<Title>Lütfen Sepete Ürün Ekleyiniz</Title>
						<PriceBox>
							{calculatePrice()}
							{CURRENCY}
						</PriceBox>
					</CheckoutButton>
				)}
			</CheckoutButtonWrapper>
		</CartPopupBody>
	);
};

export default Cart;
