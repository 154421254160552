import {useState, useEffect, useContext} from 'react';
import {
	Heading,
	FieldWrapper,
	SubHeading,
	PhoneVerificationContainer,
} from './phone-verification.style';
import ReactCodeInput from 'react-code-input';
import {Button} from '../../components/button/button';
import useUser from '../../services/use-user';
import {toast} from 'react-toastify';
import {closeModal} from '@redq/reuse-modal';
import {conformToMask} from 'react-text-mask/dist/reactTextMask';
import {PHONE_NUMBER_MASK} from '../../utils/constant';
import {ProfileContext} from '../../contexts/profile/profile.context';

export const PhoneVerification = ({item: {id, phone}}) => {
	const [loading, setLoading] = useState(true);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [remainingTime, setRemainingTime] = useState(0);
	const [counterState, setCounterState] = useState(false);
	const [code, setCode] = useState('');
	const {dispatch} = useContext(ProfileContext);
	useEffect(() => {
		if (counterState) {
			if (remainingTime > 0) {
				setTimeout(() => setRemainingTime(remainingTime - 1), 1000);
			}
		}
		// eslint-disable-next-line
	}, [remainingTime]);
	const userService = useUser();
	const handleSendVerificationCode = () => {
		if (remainingTime.length <= 0) return false;
		userService
			.sendPhoneVerification(phone)
			.then(res => {
				if (res.success) {
					setCounterState(true);
					setRemainingTime(res.data.remaining_time);
					toast(res.message, {type: 'info', autoClose: 5000});
				} else {
					if (res.data && res.data.remaining_time) {
						setCounterState(true);
						setRemainingTime(res.data.remaining_time);
					} else {
						setCounterState(false);
						setRemainingTime(0);
					}
					toast(res.message, {type: 'warning', autoClose: 5000});
				}
			})
			.then(() => setLoading(false));
	};
	useEffect(() => {
		handleSendVerificationCode();
		// eslint-disable-next-line
	}, []);

	const handleSubmit = () => {
		if (loading || submitLoading) return false;
		if (code.toString().length !== 6) {
			toast('Lütfen 6 karakter uzunluğunda bir kod giriniz!', {
				type: 'warning',
			});
			return false;
		}
		setSubmitLoading(true);
		userService
			.verifyPhoneVerification({phone, code})
			.then(
				res => {
					if (res.success) {
						toast(res.message, {type: 'info', autoClose: 5000});
						dispatch({
							type: 'SET_PHONE_VERIFICATION',
							payload: {
								id: id,
								value: true,
							},
						});
						closeModal();
					} else {
						toast(res.message, {type: 'warning', autoClose: 5000});
					}
				},
				err => {
					if (err.response.data && err.response.data.message) {
						toast(err.response.data.message, {
							type: 'error',
							autoClose: 5000,
						});
					}
				},
			)
			.then(() => setSubmitLoading(false));
		return false;
	};
	const phoneMask = phone => {
		const mask = conformToMask(phone, PHONE_NUMBER_MASK, {guide: false});
		return mask.conformedValue;
	};
	return (
		<>
			<PhoneVerificationContainer>
				<Heading>Lütfen Doğrulama Kodunu Giriniz</Heading>
				<SubHeading style={{marginBottom: '1rem'}}>
					<b>{phoneMask(phone)}</b>
				</SubHeading>
				{counterState ? <Heading>{remainingTime}</Heading> : null}
				<SubHeading>
					Telefon numarasına bir doğrulama kodu gönderdik. Lütfen mesaj size
					ulaştıktan sonra görmüş olduğunuz doğrulama kodunu bu alana giriniz.
				</SubHeading>
				<FieldWrapper>
					<ReactCodeInput onChange={value => setCode(value)} fields={6} />
				</FieldWrapper>
				<FieldWrapper>
					<Button
						variant="primary"
						size="big"
						style={{width: '100%'}}
						disabled={submitLoading || loading || code.toString().length !== 6}
						loading={submitLoading}
						type="submit"
						onClick={handleSubmit}
					>
						{submitLoading ? 'Doğrulanıyor...' : 'Doğrula'}
					</Button>
				</FieldWrapper>
				{counterState && remainingTime === 0 ? (
					<SubHeading onClick={handleSendVerificationCode}>
						Tekrar Gönder
					</SubHeading>
				) : null}
			</PhoneVerificationContainer>
		</>
	);
};
