import {BlogRow, BlogCol, BlogPostTitle} from './blog.style';
import {
	BlogCol12,
	BlogHeaderNavigation,
	BlogHeaderNavigationBreadcrumb,
	BlogHeaderNavigationLeft,
	BlogHeaderNavigationRight,
	BlogPostContent,
	BlogPostDateSection,
	BlogPostDetail,
	BlogPostHeaderWrapper,
	BlogPostImage,
	BlogPostWrapper,
} from './blog-detail.style';
import {Link} from 'react-router-dom';

export const BlogDetail = ({post}) => {
	return (
		<>
			{post ? (
				<>
					<BlogPostHeaderWrapper>
						<BlogRow>
							<BlogCol12>
								<BlogHeaderNavigation>
									<BlogHeaderNavigationLeft>
										<BlogHeaderNavigationBreadcrumb>
											<ol>
												<li>
													<Link to="/">Anasayfa</Link>
												</li>
												<li>
													<Link to="/blog">Blog</Link>
												</li>
												<li className="active" aria-current="page">
													{post.title}
												</li>
											</ol>
										</BlogHeaderNavigationBreadcrumb>
									</BlogHeaderNavigationLeft>
									<BlogHeaderNavigationRight>
										<Link to="/blog">Geri Dön</Link>
									</BlogHeaderNavigationRight>
								</BlogHeaderNavigation>
								<BlogPostTitle>
									<h4>{post.title}</h4>
								</BlogPostTitle>
							</BlogCol12>
						</BlogRow>
					</BlogPostHeaderWrapper>
					<BlogPostWrapper>
						<BlogRow>
							<BlogCol>
								<div>
									<BlogPostImage src={post.image} />
								</div>
								<BlogPostDetail>
									<BlogPostDateSection>
										{/*<BlogCardDateSectionItem>*/}
										{/*    109 k views*/}
										{/*</BlogCardDateSectionItem>*/}
										<span>{post.published_at}</span>
									</BlogPostDateSection>
								</BlogPostDetail>
								<BlogPostContent>
									<h4>{post.title}</h4>
									<div dangerouslySetInnerHTML={{__html: post.content}}></div>
								</BlogPostContent>
							</BlogCol>
						</BlogRow>
					</BlogPostWrapper>
				</>
			) : null}
		</>
	);
};
