import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';
import {Col, HomeSection} from '../../../features/homepage/homepage.style';
import {Link} from 'react-router-dom';

export const TripleCardSection = styled(HomeSection)`
	width: 100%;
	background-color: ${themeGet('colors.background.light')};
`;
export const TripleCardSectionHead = styled.div`
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 40px;
	width: 540px;
	max-width: 100%;
	text-align: center !important;
`;
export const TripleCardSubtitle = styled.h5`
	color: ${themeGet('colors.red.light')};
	margin-bottom: 0.5rem;
	letter-spacing: 0.04em;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.3;
	@media (min-width: 576px) {
		font-size: 1.125rem;
	}
`;
export const TripleCardTitle = styled.h2`
	color: ${themeGet('colors.black.900')};
	font-size: 2.25rem;
	font-weight: 700;
	line-height: 1.3;
	margin-bottom: 0.5rem;
	@media (min-width: 576px) {
		font-size: 2.5rem;
	}
`;
export const TripleCardLead = styled.p`
	padding-top: 0.6875rem;
	font-size: 1.25rem;
	line-height: 1.5;
	font-weight: 300;
	@media (min-width: 576px) {
		margin-bottom: 1rem;
	}
`;
export const TripleCardSectionContent = styled.div``;
export const TripleCardResponsiveColumn = styled(Col)`
	@media (min-width: 768px) {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	@media (min-width: 992px) {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
`;
export const TripleCardBox = styled.div`
	background-color: ${themeGet('colors.white')};
	box-shadow: 0 5px 40px 0 rgb(75 85 110 / 8%);
	border-radius: 4px;
	height: 100%;
	@media (max-width: 768px) {
		margin-bottom: 10px;
		margin-top: 10px;
	}
`;
export const TripleCardBoxContent = styled.div`
	padding: 1.25rem 1.25rem 1.5rem;
	@media (min-width: 576px) {
		padding: 1.25rem 1.875rem 1.5rem;
	}
	@media (min-width: 992px) and (max-width: 1199px) {
		padding-left: 1.5em;
		padding-right: 1.5em;
	}
`;
export const TripleCardBoxTitle = styled.h4`
	margin-bottom: 0.5rem;
	font-weight: 500;
	font-size: 1.25rem;
	letter-spacing: -0.025em;
	color: #2d313d;
	@media (min-width: 576px) {
		margin-bottom: 1rem;
		font-size: 1.5rem;
	}
`;
export const TripleCardSectionActions = styled.ul`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding-top: 30px;
	margin: -0.5rem;
	list-style: none;
	@media (min-width: 576px) {
		padding-top: 40px;
		margin: -1rem;
	}
	@media (min-width: 992px) {
		padding-top: 60px;
	}

	li {
		padding: 0.5rem;
		@media (min-width: 576px) {
			padding: 1rem;
		}
	}
`;
export const TripleCardSectionActionLink = styled(Link)`
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	border: 1px solid #0000;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: ${themeGet('colors.primary.regular')};
	color: ${themeGet('colors.white')};
	font-weight: bold;
	padding: 0.5rem 1.25rem;
	border-radius: 2rem;
	line-height: 1.25rem;
	font-size: 0.8125rem;
	@media (min-width: 576px) {
		padding: 0.6875rem 1.75rem;
		line-height: 1.5rem;
		font-size: 1rem;
	}
	:hover {
		background-color: ${themeGet('colors.primary.hover')};
		color: ${themeGet('colors.white')};
		outline: 0;
	}
`;
