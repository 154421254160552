import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

const Icon = styled.span`
	min-width: 16px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const NavLink = ({
	href,
	label,
	location,
	icon,
	className,
	onClick,
	iconClass,
}) => {
	const isCurrentPath = location.pathname === href || location.asPath === href;
	return (
		<div onClick={onClick} className={className ? className : ''}>
			<Link
				to={href}
				className={isCurrentPath ? ' current-page' : ''}
				style={{display: 'flex', alignItems: 'center'}}
			>
				{icon ? <Icon className={iconClass}>{icon}</Icon> : ''}
				<span className="label">{label}</span>
			</Link>
		</div>
	);
};

export default withRouter(NavLink);
