import React, {useContext} from 'react';
import {AuthContext} from 'contexts/auth/auth.context';
import LoginModal from './login-modal';
import RegisterModal from './register-modal';
import ForgotPasswordModal from './forgot-password-modal';

export default function AuthenticationForm() {
	const {authState} = useContext(AuthContext);

	let RenderForm;

	if (authState.currentForm === 'signIn') {
		RenderForm = LoginModal;
	}
	if (authState.currentForm === 'signUp') {
		RenderForm = RegisterModal;
	}
	if (authState.currentForm === 'forgotPass') {
		RenderForm = ForgotPasswordModal;
	}

	return <RenderForm />;
}
