import React, {useContext} from 'react';
import {Modal} from '@redq/reuse-modal';
import {SEO} from 'components/seo/seo';
import Checkout from 'features/checkouts/checkout-two/checkout-two';
import ErrorMessage from 'components/error-message/error-message';
import {AuthContext} from 'contexts/auth/auth.context';
import Footer from '../../layouts/footer/footer';
import Loader from '../../components/loader/loader';
import {LoaderContainer} from '../../assets/styles/pages.style';

const CheckoutPage = ({deviceType}) => {
	const {authState} = useContext(AuthContext);
	//todo: api implementation
	if (authState.isAuthenticated === false)
		return <ErrorMessage message="Lütfen giriş yapınız!" />;
	if (!authState.user)
		return (
			<LoaderContainer>
				<Loader />
			</LoaderContainer>
		);
	return (
		<>
			<SEO title="Sepetim" description="Sepet Detayları" />
			<Modal>
				<Checkout deviceType={deviceType} />
				<Footer />
			</Modal>
		</>
	);
};

export default CheckoutPage;
