import React from 'react';
import Sticky from 'react-stickynode';
import {Scrollbar} from 'components/scrollbar/scrollbar';
import CheckoutOrderItem from './checkout-order-item';
import {NoCartBag} from 'assets/icons/NoCartBag';
import {CURRENCY} from 'utils/constant';
import {useCart} from 'contexts/cart/use-cart';
import {useWindowSize} from 'utils/useWindowSize';
import {
	Bold,
	CalculationWrapper,
	CartWrapper,
	ItemsWrapper,
	NoProductImg,
	NoProductMsg,
	OrderInfo,
	Small,
	Text,
	TextWrapper,
	Title,
} from './checkout.style';
// import {useAppState} from "../../contexts/app/app.provider";

const CheckoutCartWrapper = () => {
	const {
		items,
		cartItemsCount,
		calculatePrice,
		calculateDiscount,
		calculateSubTotalPrice,
	} = useCart();
	const size = useWindowSize();
	return (
		<CartWrapper>
			<Sticky enabled={size.width >= 768} top={120} innerZ={999}>
				<OrderInfo>
					<Title>Siparişin</Title>

					<Scrollbar className="checkout-scrollbar">
						<ItemsWrapper>
							{cartItemsCount > 0 ? (
								items.map(item => (
									<CheckoutOrderItem
										key={`cartItem-${item.id}-${
											item.m2 ? '-' + item.m2.value : ''
										}`}
										product={item}
									/>
								))
							) : (
								<>
									<NoProductImg>
										<NoCartBag />
									</NoProductImg>

									<NoProductMsg>Hiçbir ürün bulunamadı</NoProductMsg>
								</>
							)}
						</ItemsWrapper>
					</Scrollbar>

					<CalculationWrapper>
						<TextWrapper>
							<Text>Ara Toplam</Text>
							<Text>
								{calculateSubTotalPrice()} {CURRENCY}
							</Text>
						</TextWrapper>

						<TextWrapper>
							<Text>İndirim</Text>
							<Text>
								{calculateDiscount()} {CURRENCY}
							</Text>
						</TextWrapper>

						<TextWrapper style={{marginTop: 20}}>
							<Bold>
								Toplam <Small>( Vergiler dahildir. )</Small>
							</Bold>
							<Bold>
								{calculatePrice()} {CURRENCY}
							</Bold>
						</TextWrapper>
					</CalculationWrapper>
				</OrderInfo>
			</Sticky>
		</CartWrapper>
	);
};
export default CheckoutCartWrapper;
