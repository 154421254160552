import React from 'react';
import {AUTHORIZED_MENU_ITEMS} from 'site-settings/site-navigation';
import NavLink from 'components/nav-link/nav-link';

export const AuthorizedMenu = ({onLogout}) => {
	return (
		<>
			{AUTHORIZED_MENU_ITEMS.map((item, idx) => (
				<NavLink
					key={idx}
					className="menu-item"
					href={item.href}
					label={item.defaultMessage}
					intlId={item.id}
				/>
			))}
			<div className="menu-item">
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a href="#" onClick={onLogout}>
					<span>Çıkış Yap</span>
				</a>
			</div>
		</>
	);
};
