import React from 'react';
import styled from 'styled-components';
import {Modal} from '@redq/reuse-modal';
import {SEO} from '../../components/seo/seo';
import Accordion from '../../components/accordion/accordion';
import Footer from '../../layouts/footer/footer';

const accordionData = [
	{
		id: 1,
		title: 'Nulla mus suspendisse morbi.',
		content:
			'Accumsan elementum euismod nunc leo a mi inceptos tristique est elementum ut scelerisque orci fringilla praesent arcu vitae magnis fermentum a a diam ut eleifend.Elementum quam cursus a convallis porttitor quam enim scelerisque purus dui orci hac vestibulum tempus odio condimentum hendrerit commodo torquent.',
		values: {number1: 7, number2: 2},
	},
	{
		id: 2,
		title: 'Non sem at posuere class.',
		content:
			'Adipiscing mauris dictum cras metus ligula hendrerit rhoncus a tristique sociosqu nisi nam conubia a hac ante diam mi ullamcorper semper at malesuada ridiculus rhoncus.',
	},
	{
		id: 3,
		title: 'faqNo3Title',
		content: 'faqNo3Desc',
	},
	{
		id: 4,
		title: 'faqNo4Title',
		content: 'faqNo4Desc',
	},
];

const Heading = styled.h3`
	font-size: 21px;
	font-weight: 700;
	color: #0d1136;
	line-height: 1.2;
	margin-bottom: 25px;
	width: 100%;
	text-align: center;
`;

const HelpPageWrapper = styled.div`
	background-color: #f7f7f7;
	position: relative;
	padding: 130px 0 60px 0;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: 989px) {
		padding-top: 70px;
	}
`;

export const HelpPageContainer = styled.div`
	background-color: transparent;
	padding: 0;
	border-radius: 6px;
	overflow: hidden;
	position: relative;
	@media (min-width: 990px) {
		width: 870px;
		margin-left: auto;
		margin-right: auto;
	}

	@media (max-width: 989px) {
		padding: 30px;
	}
`;

export default function FAQPage() {
	return (
		<Modal>
			<SEO
				title="Sık Sorulan Sorular"
				description="Yıkama Yeri Sık Sorulan Sorular"
			/>
			<HelpPageWrapper>
				<HelpPageContainer>
					<Heading>Sık Sorulan Sorular</Heading>
					<Accordion items={accordionData} />
				</HelpPageContainer>
			</HelpPageWrapper>
			<Footer />
		</Modal>
	);
}
