import api from 'utils/axios';

export default function useBlog() {
	const posts = page => {
		let url = 'blog';
		if (page && parseInt(page) > 0) url = url + '/' + page;
		return api.get(url).then(res => {
			return res.data;
		});
	};
	const post = slug => {
		return api.get('post/' + slug).then(res => {
			return res.data;
		});
	};
	return {
		posts,
		post,
	};
}
