export const BlanketIcon = props => {
	return (
		<svg width="14.4" height="12" viewBox="0 0 60 45" {...props}>
			<g fill="none">
				<g fill="rgb(0,0,0)">
					<path d="m59.813 24.064-2.974-14.572c-1.1990886-5.52357163-6.0768132-9.47099783-11.729-9.492h-29.22c-5.6517777.02061462-10.52926721 3.96777126-11.728 9.491l-3.862 18.07c-.00149367.0239768-.00149367.0480232 0 .072-.89155926 4.3305429.24739883 8.8309822 3.09161174 12.2160867 2.84421292 3.3851046 7.08098686 5.2827149 11.50038826 5.1509133h39.608c1.5521606.0000785 3.0320928-.6556938 4.0747092-1.8055432 1.0426164-1.1498493 1.5508381-2.6867122 1.3992908-4.2314568-.2694497-2.3221968-1.9571772-4.2306179-4.229-4.782 3.2351253-2.2608816 4.8302342-6.2186499 4.067-10.091-.001-.009.002-.017.001-.026zm-53.695-14.156c.99885555-4.60202029 5.0628591-7.89081891 9.772-7.908h29.22c4.7064855.0164478 8.7693234 3.30131322 9.771 7.9l1.767 8.651c-1.8252421-1.6416276-4.1931173-2.5502392-6.648-2.551h-35.5c-3.972893-.0124555-7.77373859 1.6200982-10.5 4.51-.07.073-.128.153-.2.227zm43.882 24.092h-35.5c-.9891231-.0013491-1.9316296-.420567-2.5950217-1.1542409-.663392-.7336739-.9859039-1.7134944-.8879783-2.6977591.2500148-1.8324115 1.8340079-3.1863106 3.683-3.148h35.3c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1h-35.3c-2.8754286-.0304579-5.31636433 2.1007362-5.674 4.954-.15233826 1.5457899.35532531 3.084015 1.3978393 4.2354654 1.0425139 1.1514504 2.5228832 1.8089956 4.0761607 1.8105346h39.807c1.8493255-.0380567 3.4320028 1.3194465 3.676 3.153.1012165.9861905-.2237209 1.9686438-.893 2.7-.6638785.7291855-1.6038743 1.1454694-2.59 1.147h-39.608c-6.77114281.1123688-12.43780392-5.1111168-12.876-11.869-.16908517-3.4193446 1.07020768-6.7586449 3.42910538-9.2397957 2.3588977-2.4811507 5.63137402-3.887448 9.05489462-3.8912043h35.5c4.4182779.0000001 7.9999998 3.5817221 7.9999998 8s-3.5817219 7.9999999-7.9999998 8z" />
					<path d="m8.844 11.51c.06868879.0147278.1387501.0221027.209.022.47170071-.0005325.87888649-.3306156.977-.792.5995717-2.75901835 3.0366024-4.73026846 5.86-4.74h8.11c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1h-8.11c-3.7664435.01355263-7.01719529 2.64343035-7.817 6.324-.05510408.2595207-.00482075.530302.1397813.7527379.14460204.2224358.37166948.3782926.6312187.4332621z" />
					<circle id="Oval" cx="28" cy="5" r="1" />
				</g>
			</g>
		</svg>
	);
};
