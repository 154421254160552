import {KeyValuePair} from './KeyValuePair';

class Address {
	constructor(data) {
		this.id = parseInt(data.id);
		this.title = data.title;
		data.province = new KeyValuePair({
			value: data.province,
			key: parseInt(data.province_id),
			slug: data.province_slug,
		});
		this.province = data.province;
		data.district = new KeyValuePair({
			value: data.district,
			key: parseInt(data.district_id),
			slug: data.district_slug,
		});
		this.district = data.district;
		data.neighbourhood = new KeyValuePair({
			value: data.neighbourhood,
			key: parseInt(data.neighbourhood_id),
			slug: data.neighbourhood_slug,
		});
		this.neighbourhood = data.neighbourhood;
		this.address = data.address;
		this.zip = data.zip;
		this.default = data.default ?? false;
		this.loading =
			data.loading !== undefined && data.loading !== null
				? data.loading
				: false;
	}
}
const parseAddresses = list => {
	if (list && typeof list === 'object' && list.length > 0)
		return list.map(item => {
			return new Address(item);
		});
	else return [];
};

export {Address, parseAddresses};
