import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';
import {Button} from 'components/button/button';
export {Button};

export const IconWrapper = styled.div`
	display: flex;
	margin-right: 6px;
`;

export const Wrapper = styled.div`
	text-align: center;
	background-color: ${themeGet('colors.white', '#ffffff')};
`;

export const Container = styled.div`
	padding: 40px 60px 0;

	@media (max-width: 768px) {
		padding: 40px 30px 0;
	}
`;

export const LogoWrapper = styled.div`
	margin-bottom: 30px;

	img {
		max-width: 160px;
	}
`;

export const Heading = styled.h3`
	margin-bottom: 10px;
	font-family: ${themeGet('fonts.heading', 'sans-serif')};
	font-size: ${themeGet('fontSizes.lg', '21')}px;
	font-weight: ${themeGet('fontWeights.bold', '700')};
	color: ${themeGet('colors.primary.regular', '#009e7f')};
`;

export const SubHeading = styled.span`
	margin-bottom: 30px;
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.base', '15')}px;
	font-weight: ${themeGet('fontWeights.regular', '400')};
	color: ${themeGet('colors.text.regular', '#77798c')};
	display: block;
`;

export const OfferSection = styled.div`
	padding: 20px;
	background-color: ${themeGet('colors.gray.200', '#F7F7F7')};
	color: ${themeGet('colors.primary.regular', '#009e7f')};
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Offer = styled.p`
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.base', '15')}px;
	font-weight: ${themeGet('fontWeights.regular', '400')};
	color: ${themeGet('colors.text.regular', '#77798c')};
	margin: 0;
`;

export const HelperText = styled.p`
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.sm', '13')}px;
	font-weight: ${themeGet('fontWeights.regular', '400')};
	color: ${themeGet('colors.text.regular', '#77798c')};
	margin: 0;
	text-align: center;
	width: 100%;

	a {
		font-weight: ${themeGet('fontWeights.bold', '700')};
		color: ${themeGet('colors.blue.link', '#4285f4')};
		text-decoration: underline;
	}
`;

// export const Input = styled.input`
//   width: 100%;
//   height: 48px;
//   border-radius: ${themeGet('radii.base', '6px')};
//   background-color: ${themeGet('colors.gray.200', '#f7f7f7')};
//   border: 1px solid ${themeGet('colors.gray.700', '#e6e6e6')};
//   font-family: ${themeGet('fonts.body', 'Lato')};
//   font-size: ${themeGet('fontSizes.base', '15')}px;
//   font-weight: ${themeGet('fontWeights.regular', '400')};
//   color: ${themeGet('colors.text.bold', '#0D1136')};
//   line-height: 19px;
//   padding: 0 18px;
//   box-sizing: border-box;
//   transition: border-color 0.25s ease;
//   margin-bottom: 10px;

//   &:hover,
//   &:focus {
//     outline: 0;
//   }

//   &:focus {
//     border-color: ${themeGet('colors.primary.regular', '#009e7f')};
//   }

//   &::placeholder {
//     color: ${themeGet('colors.text.regular', '#77798c')};
//     font-size: calc(${themeGet('fontSizes.base', '15')}px + 1px);
//   }

//   &::-webkit-inner-spin-button,
//   &::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }

//   &.disabled {
//     .inner-wrap {
//       cursor: not-allowed;
//       opacity: 0.6;
//     }
//   }
// `;

export const Divider = styled.div`
	padding: 15px 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 0 1em;
	font-family: ${themeGet('fonts.body', 'Lato')};
	line-height: 1;
	z-index: 1;
	color: ${themeGet('colors.text.bold', '#0D1136')};
	position: relative;
	&::before {
		content: '';
		height: 2px;
		background-color: ${themeGet('colors.gray.500', '#f1f1f1')};
		flex: 1;
		margin: 0 0.75em 0 0;
	}
	&::after {
		content: '';
		height: 2px;
		background-color: ${themeGet('colors.gray.500', '#f1f1f1')};
		flex: 1;
		margin: 0 0 0 0.75em;
	}
`;

export const LinkButton = styled.button`
	background-color: transparent;
	border: 0;
	outline: 0;
	box-shadow: none;
	padding: 0;
	font-size: calc(${themeGet('fontSizes.base', '15')}px - 1px);
	font-weight: ${themeGet('fontWeights.bold', '700')};
	color: ${themeGet('colors.primary.regular', '#009e7f')};
	text-decoration: underline;
	cursor: pointer;
`;
