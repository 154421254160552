import styled from 'styled-components';
import css from '@styled-system/css';
import {shadow} from 'styled-system';
import AsyncSelect from 'react-select/async';
import {Button} from '../button/button';
export const StyledForm = styled.form(
	props =>
		props.minimal
			? css({
					display: 'flex',
					alignItems: 'center',
					borderRadius: 'base',
					overflow: 'hidden',
					zIndex: '0',
					width: '100%',
					color: 'text.regular',
					backgroundColor: 'transparent',
			  })
			: css({
					display: 'flex',
					alignItems: 'center',
					overflow: 'hidden',
					zIndex: '0',
					width: 700,
					color: 'text.regular',
					backgroundColor: '#e6e6e6',
					padding: '10px 15px 10px 15px',
					borderRadius: '30px',
			  }),
	shadow,
);

export const StyledInput = styled.div(
	css({
		width: '100%',
		fontSize: 'base',
		paddingLeft: '5px',
		paddingRight: '20px',
		height: '100%',
		color: 'text.regular',
		backgroundColor: 'inherit',
		display: 'flex',
		alignItems: 'center',
	}),
	{
		border: 0,
		'&:focus': {
			outline: 0,
		},
	},
);

export const StyledCategorySelect = styled.div(
	css({
		color: 'primary.regular',
		borderRadius: 'base',
		width: '100%',
		maxWidth: '20%',
	}),
	{
		margin: '5px',
		whiteSpace: 'nowrap',
	},
);

export const StyledSearchButton = styled.button(
	css({
		backgroundColor: 'primary.regular',
		color: 'white',
		fontSize: 'base',
		fontWeight: 'bold',
	}),
	{
		display: 'flex',
		height: 54,
		alignItems: 'center',
		border: 0,
		outline: 0,
		paddingLeft: 30,
		paddingRight: 30,
		cursor: 'pointer',
		flexShrink: 0,
		borderRadius: '10px',
	},
);
export const MinimalButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;
export const MinimalAsyncSelect = styled(AsyncSelect)`
	width: 100%;
`;
export const MinimalSearchButton = styled(Button)`
	width: 100%;
	margin-top: 10px;
`;
export const CustomControlIcon = styled.div`
	margin-left: 10px;
	margin-right: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const CustomSelectGroupContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const CustomSelectGroupText = styled.span``;
