import {SEO} from 'components/seo/seo';
import {Modal} from '@redq/reuse-modal';
import {
	ContentBox,
	PageWrapper,
	SidebarSection,
} from 'features/user-profile/user-profile.style';
import Sidebar from 'features/user-profile/sidebar/sidebar';
import Footer from 'layouts/footer/footer';
import React from 'react';
import {ChangePassword} from 'features/user-profile/change-password/change-password';

export const ChangePasswordPage = ({deviceType}) => {
	return (
		<>
			<SEO title="Şifremi Değiştir" description="Şifremi değiştir" />
			<Modal>
				<PageWrapper>
					<SidebarSection>
						<Sidebar />
					</SidebarSection>
					<ContentBox>
						<ChangePassword deviceType={deviceType} />
					</ContentBox>
				</PageWrapper>
				<Footer />
			</Modal>
		</>
	);
};
