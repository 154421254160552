import React, {useReducer, useEffect} from 'react';
import {AuthContext} from './auth.context';
import useUser from 'services/use-user';
import {User} from 'models/User';
import {SplashScreen} from '../../components/SplashScreen/splash-screen';

const INITIAL_STATE = {
	user: null,
	isAuthenticated: null,
	currentForm: 'signIn',
	initial: true,
};
function reducer(state, action) {
	switch (action.type) {
		case 'SET_INITIAL':
			return {
				...state,
				initial: action.payload.initial,
			};
		case 'SET_USER':
			return {
				...state,
				user: action.payload.user,
				isAuthenticated: !!action.payload.user,
			};
		case 'SIGNIN':
			return {
				...state,
				currentForm: 'signIn',
			};
		case 'SIGNIN_SUCCESS':
			return {
				...state,
				isAuthenticated: true,
			};
		case 'SIGN_OUT':
			return {
				...state,
				isAuthenticated: false,
			};
		case 'SIGNUP':
			return {
				...state,
				currentForm: 'signUp',
			};
		case 'FORGOTPASS':
			return {
				...state,
				currentForm: 'forgotPass',
			};
		default:
			return state;
	}
}
export const AuthProvider = ({children}) => {
	const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);
	useEffect(() => {
		if (authState && authDispatch) {
			const token = localStorage.getItem('access_token');
			if (token) {
				// eslint-disable-next-line react-hooks/rules-of-hooks
				const userService = useUser();
				userService.me().then(
					res => {
						if (res.success) {
							authDispatch({
								type: 'SET_USER',
								payload: {
									user: new User(res.data.user),
								},
							});
						} else {
							authDispatch({
								type: 'SET_USER',
								payload: {
									user: null,
								},
							});
						}
					},
					err => {
						localStorage.removeItem('access_token');
						authDispatch({
							type: 'SET_USER',
							payload: {
								user: null,
							},
						});
					},
				);
			} else {
				authDispatch({
					type: 'SET_USER',
					payload: {
						user: null,
					},
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState.isAuthenticated]);
	return (
		<AuthContext.Provider value={{authState, authDispatch}}>
			{!authState || (authState && authState.isAuthenticated === null) ? (
				<SplashScreen />
			) : (
				children
			)}
		</AuthContext.Provider>
	);
};
