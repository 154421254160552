import {
	FooterColLarge,
	FooterColMd6,
	FooterColMedium,
	FooterContainer,
	FooterCopyright,
	FooterCopyrightLinkContainer,
	FooterCopyrightRow,
	FooterCopyrightSection,
	FooterHeadLogoLink,
	FooterLogoDescription,
	FooterMenuLink,
	FooterMenuList,
	FooterMenuTitle,
	FooterRow,
	FooterSection,
	FooterSocialMediaLink,
	FooterSocialMediaList,
} from './footer.style';
import LogoImage from 'assets/images/logo.png';
import {FacebookCircleIcon} from '../../assets/icons/social-media/FacebookCircleIcon';
import {InstagramIcon} from '../../assets/icons/social-media/InstagramIcon';
import {YoutubeIcon} from '../../assets/icons/social-media/YoutubeIcon';
import {
	ABOUT_US_PAGE,
	CONTACT_US_PAGE,
	HELP_PAGE,
	TERMS_PAGE,
} from '../../site-settings/site-navigation';
import SocialMediaConfig from 'site-settings/site-social-media';

const Footer = () => {
	return (
		<footer id="page-footer">
			<FooterSection>
				<FooterContainer>
					<FooterRow>
						<FooterColLarge>
							<div>
								<FooterHeadLogoLink to="/">
									<img src={LogoImage} alt="Yıkama Yeri" />
								</FooterHeadLogoLink>
							</div>
							<FooterLogoDescription>
								<p>
									This website is for health information and advice about
									coronavirus (COVID-19), how to prevent and protect yourself
									from disease.
								</p>
								<p>
									Learn about the government response to coronavirus on GOV.UK.
								</p>
							</FooterLogoDescription>
							<FooterSocialMediaList>
								<li>
									<FooterSocialMediaLink
										target="_blank"
										href={SocialMediaConfig.facebook}
									>
										<FacebookCircleIcon />
									</FooterSocialMediaLink>
								</li>
								<li>
									<FooterSocialMediaLink
										target="_blank"
										href={SocialMediaConfig.instagram}
									>
										<InstagramIcon />
									</FooterSocialMediaLink>
								</li>
								<li>
									<FooterSocialMediaLink
										style={{backgroundColor: 'transparent'}}
										target="_blank"
										href={SocialMediaConfig.youtube}
									>
										<YoutubeIcon />
									</FooterSocialMediaLink>
								</li>
							</FooterSocialMediaList>
						</FooterColLarge>
						<FooterColMedium>
							<FooterMenuTitle>Hakkımızda</FooterMenuTitle>
							<FooterMenuList>
								<ul>
									<FooterMenuLink to={ABOUT_US_PAGE}>Hakkımızda</FooterMenuLink>
								</ul>
								<ul>
									<FooterMenuLink to={HELP_PAGE}>
										Sık Sorulan Sorular
									</FooterMenuLink>
								</ul>
								<ul>
									<FooterMenuLink to={TERMS_PAGE}>
										Sözleşmelerimiz
									</FooterMenuLink>
								</ul>
								<ul>
									<FooterMenuLink to="/blog">Blog</FooterMenuLink>
								</ul>
								<ul>
									<FooterMenuLink to={CONTACT_US_PAGE}>İletişim</FooterMenuLink>
								</ul>
							</FooterMenuList>
						</FooterColMedium>
						<FooterColMedium>
							<FooterMenuTitle>Popüler Kategoriler</FooterMenuTitle>
							<FooterMenuList>
								<ul>
									<FooterMenuLink to="/hali-yikama">Halı Yıkama</FooterMenuLink>
								</ul>
								<ul>
									<FooterMenuLink to="/koltuk-yikama">
										Koltuk Yıkama
									</FooterMenuLink>
								</ul>
								<ul>
									<FooterMenuLink to="/kuru-temizleme">
										Kuru Temizleme
									</FooterMenuLink>
								</ul>
								<ul>
									<FooterMenuLink to="/yorgan-battaniye-temizleme">
										Yorgan Battaniye Temizleme
									</FooterMenuLink>
								</ul>
							</FooterMenuList>
						</FooterColMedium>
					</FooterRow>
				</FooterContainer>
			</FooterSection>
			<FooterCopyrightSection>
				<FooterContainer>
					<FooterCopyrightRow>
						<FooterColMd6>
							<FooterCopyright>
								© {new Date().getFullYear()} yikamayeri.com Tüm hakları Saklıdır
							</FooterCopyright>
						</FooterColMd6>
						<FooterColMd6>
							<FooterCopyrightLinkContainer>
								<FooterMenuLink to={TERMS_PAGE}>
									Gizlilik Sözleşmesi
								</FooterMenuLink>
							</FooterCopyrightLinkContainer>
						</FooterColMd6>
					</FooterCopyrightRow>
				</FooterContainer>
			</FooterCopyrightSection>
		</footer>
	);
};
export default Footer;
