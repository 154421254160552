export const BoatIcon = props => {
	return (
		<svg width="14.4" height="12" viewBox="0 0 512.027 512.027" {...props}>
			<g>
				<g>
					<g>
						<path
							d="M503.467,324.263c-23.301-0.547-46.251,5.755-66.005,18.125c-3.746,2.065-7.441,4.105-11.324,5.973l81.792-98.338
                                c4.333-5.066,5.32-12.192,2.526-18.245c-2.794-6.053-8.856-9.925-15.523-9.915h-74.027l-89.933-65.408l-4.352-8.695
                                c-8.624-17.387-26.382-28.36-45.79-28.297h-87.825l-8.533-17.067H230.4c4.713,0,8.533-3.821,8.533-8.533s-3.82-8.533-8.533-8.533
                                h-93.867c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533h28.86l8.533,17.067h-37.393c-14.138,0-25.6,11.461-25.6,25.6
                                s11.461,25.6,25.6,25.6h11.793l-25.6,51.2H115.2c-5.369,0.011-10.423,2.538-13.653,6.827l-20.48,27.307H66.389
                                c-2.907-0.001-5.615,1.478-7.185,3.925l-41.506,64.666c-2.944-0.171-5.914-0.324-9.165-0.324c-4.713,0-8.533,3.82-8.533,8.533
                                s3.82,8.533,8.533,8.533c20.405-0.491,40.498,5.08,57.737,16.009c19.75,12.373,42.698,18.675,65.997,18.125
                                c18.26,0.529,36.382-3.325,52.847-11.238l0.12-0.06c4.548-2.193,8.798-4.523,12.996-6.827
                                c17.228-10.924,37.309-16.495,57.702-16.009c20.413-0.493,40.515,5.078,57.762,16.009c19.764,12.376,42.727,18.678,66.039,18.125
                                c23.301,0.547,46.251-5.755,66.005-18.125c17.238-10.924,37.326-16.494,57.728-16.009c4.713,0,8.533-3.82,8.533-8.533
                                S508.18,324.263,503.467,324.263z M321.493,170.663l23.467,17.067h-97.493c-4.713,0-8.533-3.82-8.533-8.533v-8.533H321.493z
                                 M136.533,153.597c-4.713,0-8.533-3.82-8.533-8.533s3.82-8.533,8.533-8.533h144.299c12.175-0.021,23.425,6.495,29.466,17.067
                                H136.533z M167.407,170.663h54.46v8.533c0,14.138,11.461,25.6,25.6,25.6h120.96l23.467,17.067H141.807L167.407,170.663z
                                 M115.2,238.93h174.933v8.533c0,4.713-3.82,8.533-8.533,8.533H102.4L115.2,238.93z M379.733,358.397
                                c-20.425,0.5-40.539-5.072-57.796-16.009c-19.754-12.37-42.704-18.671-66.005-18.125c-23.288-0.546-46.224,5.756-65.963,18.125
                                c-17.227,10.926-37.308,16.498-57.702,16.009c-20.408,0.492-40.504-5.079-57.745-16.009c-11.792-7.123-24.65-12.309-38.084-15.36
                                l34.611-53.965H281.6c14.138,0,25.6-11.461,25.6-25.6v-8.533h25.6v25.6c0,4.713,3.821,8.533,8.533,8.533s8.533-3.82,8.533-8.533
                                v-25.6h17.067v25.6c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-25.6h17.067v25.6c0,4.713,3.82,8.533,8.533,8.533
                                s8.533-3.82,8.533-8.533v-25.549h76.723l-98.33,118.323C390.959,358.039,385.349,358.404,379.733,358.397z"
						/>
						<path
							d="M503.467,375.463c-23.301-0.547-46.251,5.755-66.005,18.125c-17.238,10.924-37.326,16.494-57.728,16.009
                                c-20.413,0.493-40.515-5.078-57.762-16.009c-19.764-12.376-42.727-18.678-66.039-18.125
                                c-23.288-0.546-46.224,5.756-65.963,18.125c-17.227,10.926-37.308,16.498-57.702,16.009
                                c-20.408,0.492-40.504-5.079-57.745-16.009c-19.747-12.371-42.692-18.674-65.988-18.125c-4.713,0-8.533,3.82-8.533,8.533
                                s3.82,8.533,8.533,8.533c20.405-0.491,40.498,5.08,57.737,16.009c19.75,12.373,42.698,18.675,65.997,18.125
                                c23.289,0.548,46.226-5.757,65.963-18.133c17.229-10.921,37.309-16.489,57.702-16c20.413-0.493,40.515,5.078,57.762,16.009
                                c19.764,12.376,42.727,18.678,66.039,18.125c23.301,0.547,46.251-5.755,66.005-18.125c17.238-10.924,37.326-16.494,57.728-16.009
                                c4.713,0,8.533-3.82,8.533-8.533S508.18,375.463,503.467,375.463z"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
