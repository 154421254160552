import React, {useState, useContext, useEffect} from 'react';
import {ProductCard} from 'components/product-card/product-card-default/product-card-default';
import styled from 'styled-components';
import css from '@styled-system/css';
import ErrorMessage from 'components/error-message/error-message';
import {ServiceContext} from '../../contexts/service/service.context';
import useCategory from '../../services/use-category';
import {LoaderContainer} from '../../assets/styles/pages.style';
import Loader from '../loader/loader';
import themeGet from '@styled-system/theme-get';

const Grid = styled.div(
	css({
		display: 'grid',
		gridGap: '10px',
		gridTemplateColumns: 'repeat(2, minmax(180px, 1fr))',

		'@media screen and (min-width: 630px)': {
			gridTemplateColumns: 'repeat(3, minmax(180px, 1fr))',
		},

		'@media screen and (min-width: 768px)': {
			gridTemplateColumns: 'repeat(3, minmax(180px, 1fr))',
		},

		'@media screen and (min-width: 991px)': {
			gridTemplateColumns: 'repeat(3, minmax(180px, 1fr))',
		},

		'@media screen and (min-width: 1200px)': {
			gridTemplateColumns: 'repeat(4, minmax(180px, 1fr))',
		},

		'@media screen and (min-width: 1700px)': {
			gridTemplateColumns: 'repeat(5, minmax(240px, 1fr))',
		},

		'@media screen and (min-width: 1900px)': {
			gridTemplateColumns: 'repeat(6, minmax(240px, 1fr))',
		},
	}),
);
const NotFoundWrapper = styled.div`
	display: flex;
	text-align: center;
	justify-content: center;
	color: ${themeGet('colors.black.regular', '#000')};
	font-weight: bold;
	font-size: 1rem;
	margin: 2rem auto;
	@media (min-width: 581px) {
		max-width: 50%;
	}
`;

export const ProductGrid = ({style, deviceType}) => {
	const categoryService = useCategory();
	const {serviceState, serviceDispatch} = useContext(ServiceContext);
	const [errorMessage, setErrorMessage] = useState(null);
	useEffect(() => {
		if (!serviceState.category || !serviceState.category.slug) {
			serviceDispatch({
				type: 'SET_CATEGORY_SERVICE_DATA',
				payload: [],
			});
			serviceDispatch({
				type: 'SET_CATEGORY_SERVICE_LOADING',
				payload: false,
			});
			return false;
		}
		if (!serviceState.neighbourhood) {
			serviceDispatch({
				type: 'SET_CATEGORY_SERVICE_DATA',
				payload: [],
			});
			serviceDispatch({
				type: 'SET_CATEGORY_SERVICE_LOADING',
				payload: false,
			});
			return false;
		}
		serviceDispatch({
			type: 'SET_CATEGORY_SERVICE_LOADING',
			payload: true,
		});
		categoryService
			.getCategoryServices(
				serviceState.category.slug,
				serviceState.neighbourhood,
			)
			.then(
				res => {
					if (res.success) {
						serviceDispatch({
							type: 'SET_CATEGORY_SERVICE_DATA',
							payload: res.data ?? [],
						});
					} else {
						setErrorMessage(res.message);
						serviceDispatch({
							type: 'SET_CATEGORY_SERVICE_DATA',
							payload: [],
						});
					}
				},
				err => {
					setErrorMessage(err.response.data.message);
					serviceDispatch({
						type: 'SET_CATEGORY_SERVICE_DATA',
						payload: [],
					});
				},
			)
			.then(() => {
				serviceDispatch({
					type: 'SET_CATEGORY_SERVICE_LOADING',
					payload: false,
				});
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceState.category]);

	if (errorMessage) return <ErrorMessage message={errorMessage} />;
	const NotFoundCard = () => {
		return (
			<NotFoundWrapper>
				Seçmiş olduğunuz lokasyonda şu anda hizmet vermemekteyiz. En yakın süre
				içerisinde seçmiş olduğunuz lokasyon için çalışma yapacağız.
			</NotFoundWrapper>
		);
	};
	return (
		<section>
			{serviceState.categoryService.loading ? (
				<LoaderContainer>
					<Loader />
				</LoaderContainer>
			) : serviceState.categoryService.data &&
			  serviceState.categoryService.data.length === 0 ? (
				<NotFoundCard />
			) : (
				<Grid style={style}>
					{serviceState.categoryService.data.map(service => (
						<ProductCard
							deviceType={deviceType}
							data={service}
							key={service.id}
						/>
					))}
				</Grid>
			)}
		</section>
	);
};
