// eslint-disable-next-line import/no-anonymous-default-export
export default [
	{
		id: 0,
		type: 'secondary',
		title: 'Kredi Kartı',
		value: 'CREDIT_CARD',
	},
	{
		id: 1,
		type: 'secondary',
		title: 'Kapıda Ödeme',
		value: 'PAY_AT_DELIVERY',
	},
];
