import React, {useContext, useState} from 'react';
// import { ProfileContext } from 'contexts/profile/profile.context';
import {
	SettingsForm,
	SettingsFormContent,
	HeadingSection,
	Title,
	Row,
	Col,
} from './settings.style';
import {Label} from '../../../components/forms/label';
import {Input} from '../../../components/forms/input';
import {Button} from '../../../components/button/button';
import Contact from '../../contact/contact';
import Address from 'features/address/address';
import {AuthContext} from '../../../contexts/auth/auth.context';
import {useDataHook} from 'model-react';
import useUser from '../../../services/use-user';

const SettingsContent = () => {
	const [h] = useDataHook();
	const {authState} = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [initialForm, setInitialForm] = useState({
		firstname: authState.user.firstname.get(h),
		lastname: authState.user.lastname.get(h),
		email: authState.user.email.get(h),
	});

	const handleChange = e => {
		const {value, name} = e.target;
		setInitialForm({...initialForm, [name]: value});
	};
	const userService = useUser();
	//todo: api implementation
	const handleSave = async () => {
		setLoading(true);
		userService
			.updateMe({
				firstname: initialForm.firstname,
				lastname: initialForm.lastname,
			})
			.then(res => {
				if (res.success) {
					authState.user.firstname.set(res.data.user.firstname);
					authState.user.lastname.set(res.data.user.lastname);
				} else {
				}
			})
			.then(() => setLoading(false));
	};

	return (
		<SettingsForm>
			<SettingsFormContent>
				<HeadingSection>
					<Title>Profilin</Title>
				</HeadingSection>
				<Row style={{alignItems: 'flex-end', marginBottom: '50px'}}>
					<Col xs={12} sm={5} md={5} lg={5}>
						<Label>Adınız</Label>
						<Input
							type="text"
							label="Adınız"
							name="firstname"
							value={initialForm.firstname}
							onChange={handleChange}
							backgroundColor="#F7F7F7"
							height="48px"
						/>
					</Col>
					<Col xs={12} sm={5} md={5} lg={5}>
						<Label>Soyadınız</Label>
						<Input
							type="text"
							label="Soyadınız"
							name="lastname"
							value={initialForm.lastname}
							onChange={handleChange}
							backgroundColor="#F7F7F7"
							height="48px"
						/>
					</Col>
					<Col xs={12} sm={5} md={5} lg={5}>
						<Label>Email Adresiniz</Label>
						<Input
							type="email"
							name="email"
							label="Email Address"
							value={initialForm.email}
							disabled={true}
							backgroundColor="#F7F7F7"
						/>
					</Col>
					<Col xs={12} sm={2} md={2} lg={2}>
						<Button
							size="big"
							style={{width: '100%'}}
							onClick={handleSave}
							loading={loading}
							disabled={loading}
						>
							{loading ? 'Kaydediliyor...' : 'Kaydet'}
						</Button>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
						<SettingsFormContent>
							<Contact />
						</SettingsFormContent>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} style={{position: 'relative'}}>
						<SettingsFormContent>
							<Address />
						</SettingsFormContent>
					</Col>
				</Row>
			</SettingsFormContent>
		</SettingsForm>
	);
};

export default SettingsContent;
