import React, {useReducer} from 'react';
import schedules from 'features/checkouts/data';
import payment_methods from 'features/payment-method/data';
import {ProfileContext} from './profile.context';

const INITIAL_STATE = {
	phone: {
		loading: false,
		data: [],
	},
	address: {
		loading: false,
		data: [],
	},
	address_provinces: {
		loading: false,
		data: [],
	},
	address_districts: {
		loading: false,
		data: [],
	},
	address_neighbourhoods: {
		loading: false,
		data: [],
	},
	payload: {},
};

function reducer(state, action) {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	switch (action.type) {
		case 'SET_PROVINCE_LOADING':
			return {
				...state,
				address_provinces: {
					loading: action.payload,
					data: state.address_provinces.data,
				},
			};
		case 'SET_PROVINCE_DATA':
			return {
				...state,
				address_provinces: {
					loading: state.address_provinces.loading,
					data: action.payload,
				},
			};
		case 'SET_DISTRICT_LOADING':
			return {
				...state,
				address_districts: {
					loading: action.payload,
					data: state.address_districts.data,
				},
			};
		case 'SET_DISTRICT_DATA':
			return {
				...state,
				address_districts: {
					loading: state.address_districts.loading,
					data: action.payload,
				},
			};
		case 'SET_NEIGHBOURHOOD_LOADING':
			return {
				...state,
				address_neighbourhoods: {
					loading: action.payload,
					data: state.address_neighbourhoods.data,
				},
			};
		case 'SET_NEIGHBOURHOOD_DATA':
			return {
				...state,
				address_neighbourhoods: {
					loading: state.address_neighbourhoods.loading,
					data: action.payload,
				},
			};
		case 'SET_PHONE_LOADING':
			if (action.payload.id) {
				return {
					...state,
					phone: {
						loading: state.phone.loading,
						data: state.phone.data.map(item =>
							item.id === action.payload.id
								? {...item, loading: action.payload.loading}
								: item,
						),
					},
				};
			} else {
				return {
					...state,
					phone: {
						loading: action.payload.loading,
						data: state.phone.data,
					},
				};
			}
		case 'DELETE_PHONE':
			return {
				...state,
				phone: {
					loading: state.phone.loading,
					data: state.phone.data.filter(
						item => parseInt(item.id) !== parseInt(action.payload),
					),
				},
			};
		case 'SET_PHONE_NUMBERS':
			return {
				...state,
				phone: {
					loading: state.phone.loading,
					data: action.payload,
				},
			};
		case 'ADD_PHONE':
			return {
				...state,
				phone: {
					loading: state.phone.loading,
					data: [...state.phone.data, action.payload],
				},
			};
		case 'UPDATE_PHONE':
			const payload_phone_id = parseInt(action.payload.id);
			return {
				...state,
				phone: {
					loading: state.phone.loading,
					data: state.phone.data.map(item =>
						parseInt(item.id) === payload_phone_id
							? {...item, ...action.payload}
							: item,
					),
				},
			};
		case 'SET_ADDRESSES':
			return {
				...state,
				address: {
					loading: state.address.loading,
					data: action.payload,
				},
			};
		case 'SET_ADDRESS_LOADING':
			if (action.payload.id) {
				return {
					...state,
					address: {
						loading: state.address.loading,
						data: state.address.data.map(item =>
							item.id === action.payload.id
								? {...item, loading: action.payload.loading}
								: item,
						),
					},
				};
			} else {
				return {
					...state,
					address: {
						loading: action.payload.loading,
						data: state.address.data,
					},
				};
			}
		case 'DELETE_ADDRESS':
			return {
				...state,
				address: {
					loading: state.address.loading,
					data: state.address.data.filter(
						item => parseInt(item.id) !== parseInt(action.payload),
					),
				},
			};
		case 'ADD_ADDRESS':
			return {
				...state,
				address: {
					loading: state.address.loading,
					data: [...state.address.data, action.payload],
				},
			};
		case 'UPDATE_ADDRESS':
			const pay_address_id = parseInt(action.payload.id);
			return {
				...state,
				address: {
					loading: state.address.loading,
					data: state.address.data.map(item =>
						parseInt(item.id) === pay_address_id
							? {...item, ...action.payload}
							: item,
					),
				},
			};
		case 'ADD_CARD':
			const newCard = {
				id: action.payload.id,
				type: state.card.length === 0 ? 'primary' : 'secondary',
				cardType: action.payload.brand.toLowerCase(),
				name: state.name,
				lastFourDigit: action.payload.last4,
			};
			return {
				...state,
				card: [newCard, ...state.card],
			};
		case 'DELETE_CARD':
			return {
				...state,
				card: state.card.filter(item => item.id !== action.payload),
			};
		case 'SET_PRIMARY_PHONE':
			const pay_id = parseInt(action.payload);
			return {
				...state,
				phone: {
					loading: false,
					data: state.phone.data.map(item =>
						item.id === pay_id
							? {...item, default: true}
							: {...item, default: false},
					),
				},
			};
		case 'SET_PHONE_VERIFICATION':
			const id = parseInt(action.payload.id);
			return {
				...state,
				phone: {
					loading: false,
					data: state.phone.data.map(item =>
						item.id === id
							? {...item, verified: action.payload.value}
							: {...item},
					),
				},
			};
		case 'SET_PRIMARY_ADDRESS':
			return {
				...state,
				address: {
					loading: false,
					data: state.address.data.map(item =>
						item.id === action.payload
							? {...item, default: true}
							: {...item, default: false},
					),
				},
			};
		case 'SET_PRIMARY_SCHEDULE':
			return {
				...state,
				schedules: state.schedules.map(item =>
					item.id === action.payload
						? {...item, type: 'primary'}
						: {...item, type: 'secondary'},
				),
			};
		case 'SET_PRIMARY_PAYMENT_METHOD':
			return {
				...state,
				payment_methods: state.payment_methods.map(item =>
					item.id === action.payload
						? {...item, type: 'primary'}
						: {...item, type: 'secondary'},
				),
			};
		case 'SET_PRIMARY_CARD':
			return {
				...state,
				card: state.card.map(item =>
					item.id === action.payload
						? {...item, type: 'primary'}
						: {...item, type: 'secondary'},
				),
			};
		default:
			return state;
	}
}

export const ProfileProvider = ({children, initData}) => {
	const [state, dispatch] = useReducer(reducer, {
		...INITIAL_STATE,
		...initData,
		schedules,
		payment_methods,
	});
	// console.log(state, 'profile provider state');

	return (
		<ProfileContext.Provider value={{state, dispatch}}>
			{children}
		</ProfileContext.Provider>
	);
};
