import React from 'react';
import {Box} from '../../box/box';
import {AddItemToCart} from '../../add-items-to-cart/add-items-to-cart';
import {
	Card,
	Discount,
	Image,
	ImageWrapper,
	Price,
	PriceWrapper,
	SalePrice,
	Title,
} from './product-card-default.style';
import QuickViewMobile from 'features/quick-view/quick-view-mobile';
import {useModal} from 'contexts/modal/use-modal';
import {CURRENCY} from '../../../utils/constant';

export const ProductCard = ({deviceType, data}) => {
	const {title, image, price, salePrice, discountInPercent, serviceType} = data;
	const [showModal, hideModal] = useModal(
		() => (
			<QuickViewMobile
				modalProps={data}
				hideModal={hideModal}
				deviceType={deviceType}
			/>
		),
		{
			onClose: () => {
				console.log('Closing...');
			},
		},
	);
	const handleQuickViewModal = () => {
		showModal();
	};
	return (
		<div onClick={handleQuickViewModal} className="yikamayeri-service-card">
			<Card>
				<ImageWrapper>
					<Image
						src={image ? image : '/assets/images/default/default_quote.jpg'}
						alt={title}
					/>
					{discountInPercent ? <Discount>{discountInPercent}%</Discount> : null}
				</ImageWrapper>
				<Box px={20} pb={20}>
					<PriceWrapper>
						<Price>
							{salePrice ? salePrice : price} {CURRENCY}
						</Price>
						{discountInPercent ? (
							<SalePrice>
								{price} {CURRENCY}
							</SalePrice>
						) : null}
					</PriceWrapper>
					<Title>{title}</Title>
					<AddItemToCart
						data={data}
						onClick={serviceType === 'size' ? handleQuickViewModal : null}
						variant="full"
						buttonText="Sepete Ekle"
					/>
				</Box>
			</Card>
		</div>
	);
};
