import api from 'utils/axios';

export default function usePayment() {
	const checkoutForm = (basket, options) => {
		return api.post('payment/checkout-form', {basket, ...options}).then(res => {
			return res.data;
		});
	};
	const getPaymentResult = token => {
		return api.post('payment/checkout-form/result', {token}).then(res => {
			return res.data;
		});
	};
	const getCoupon = coupon_code => {
		return api.get('coupon/' + coupon_code).then(res => {
			return res.data;
		});
	};
	const getCouponCodes = () => {
		return api.get('coupons').then(res => {
			return res.data;
		});
	};
	const getOrders = () => {
		return api.get('orders').then(res => {
			return res.data;
		});
	};

	return {
		checkoutForm,
		getPaymentResult,
		getCoupon,
		getCouponCodes,
		getOrders,
	};
}
