import React, {useContext} from 'react';
import {AuthContext} from 'contexts/auth/auth.context';
import {useHistory} from 'react-router-dom';
// import {openModal} from "@redq/reuse-modal";
import HeaderWrapper from './header.style';
import LogoImage from 'assets/images/logo.png';
import UserImage from 'assets/images/user.svg';
import LeftMenu from './menu/left-menu/left-menu';
import {RightMenu} from './menu/right-menu/right-menu';
// import AuthenticationForm from "features/authentication-form";
import {HOME_PAGE, LOGIN_PAGE} from '../../site-settings/site-navigation';
const Header = ({className}) => {
	const {
		authState: {isAuthenticated},
		authDispatch,
	} = useContext(AuthContext);
	const router = useHistory();
	const handleLogout = () => {
		localStorage.removeItem('access_token');
		authDispatch({type: 'SIGN_OUT'});
		router.push(HOME_PAGE);
	};
	const handleJoin = () => {
		router.push(LOGIN_PAGE);
		// authDispatch({
		//     type: 'SIGNIN'
		// });
		//
		// openModal({
		//     show: true,
		//     overlayClassName: 'quick-view-overlay',
		//     closeOnClickOutside: true,
		//     component: AuthenticationForm,
		//     closeComponent: '',
		//     config: {
		//         enableResizing: false,
		//         disableDragging: true,
		//         className: 'quick-view-modal',
		//         width: 458,
		//         height: 'auto',
		//     },
		// });
	};
	return (
		<HeaderWrapper className={className} id="layout-header">
			<LeftMenu logo={LogoImage} />
			{/*<Search minimal={true} className="headerSearch" />*/}
			<RightMenu
				isAuthenticated={isAuthenticated}
				onJoin={handleJoin}
				onLogout={handleLogout}
				avatar={UserImage}
			/>
		</HeaderWrapper>
	);
};

export default Header;
