import {
	Col12,
	ColLg6,
	HomeContainer,
	HomeRow,
	HomeSection,
} from '../../../features/homepage/homepage.style';
import {
	StepCardBox,
	StepCardBoxContent,
	StepCardBoxGfx,
	StepsCardLead,
	StepsCardSectionContent,
	StepsCardSectionHead,
	StepsCardSubtitle,
	StepsCardTitle,
} from './steps-card.style';

export const StepsCard = () => {
	return (
		<HomeSection>
			<HomeContainer>
				<StepsCardSectionHead>
					<StepsCardSubtitle>Adımlar</StepsCardSubtitle>
					<StepsCardTitle>Yıkama Yeri</StepsCardTitle>
					<StepsCardLead>
						The best thing you can do now is plan for how you can adapt your
						daily routine. Take few steps to protect yourself as Clean your
						hands often, Avoid close contact, Cover coughs and sneezes, clean
						daily used surfaces etc. The best way to prevent illness is to avoid
						being exposed to this virus.
					</StepsCardLead>
				</StepsCardSectionHead>
				<StepsCardSectionContent>
					<HomeRow>
						<ColLg6>
							<HomeRow>
								<Col12>
									<StepCardBox>
										<StepCardBoxGfx>
											<img
												src="http://demo.themenio.com/kovid19/images/gfx/dos-a.png"
												alt="Yıkamayeri"
											/>
										</StepCardBoxGfx>
										<StepCardBoxContent>
											<h6>Wash Your Hands</h6>
											<p>The best thing you can do now is plan for how.</p>
										</StepCardBoxContent>
									</StepCardBox>
								</Col12>
								<Col12>
									<StepCardBox>
										<StepCardBoxGfx>
											<img
												src="http://demo.themenio.com/kovid19/images/gfx/dos-b.png"
												alt="Yıkamayeri"
											/>
										</StepCardBoxGfx>
										<StepCardBoxContent>
											<h6>Drink Much Watar</h6>
											<p>The best thing you can do now is plan for how.</p>
										</StepCardBoxContent>
									</StepCardBox>
								</Col12>
								<Col12>
									<StepCardBox>
										<StepCardBoxGfx>
											<img
												src="http://demo.themenio.com/kovid19/images/gfx/dos-c.png"
												alt="Yıkamayeri"
											/>
										</StepCardBoxGfx>
										<StepCardBoxContent>
											<h6>Use Face Mask</h6>
											<p>The best thing you can do now is plan for how.</p>
										</StepCardBoxContent>
									</StepCardBox>
								</Col12>
							</HomeRow>
						</ColLg6>
						<ColLg6>
							<HomeRow>
								<Col12>
									<StepCardBox>
										<StepCardBoxGfx>
											<img
												src="http://demo.themenio.com/kovid19/images/gfx/donts-a.png"
												alt="Yıkamayeri"
											/>
										</StepCardBoxGfx>
										<StepCardBoxContent>
											<h6>Avoid Close Contact</h6>
											<p>The best thing you can do now is plan for how.</p>
										</StepCardBoxContent>
									</StepCardBox>
								</Col12>
								<Col12>
									<StepCardBox>
										<StepCardBoxGfx>
											<img
												src="http://demo.themenio.com/kovid19/images/gfx/donts-b.png"
												alt="Yıkamayeri"
											/>
										</StepCardBoxGfx>
										<StepCardBoxContent>
											<h6>Don’t Touch Face</h6>
											<p>The best thing you can do now is plan for how.</p>
										</StepCardBoxContent>
									</StepCardBox>
								</Col12>
								<Col12>
									<StepCardBox>
										<StepCardBoxGfx>
											<img
												src="http://demo.themenio.com/kovid19/images/gfx/donts-c.png"
												alt="Yıkamayeri"
											/>
										</StepCardBoxGfx>
										<StepCardBoxContent>
											<h6>Social Distancing</h6>
											<p>The best thing you can do now is plan for how.</p>
										</StepCardBoxContent>
									</StepCardBox>
								</Col12>
							</HomeRow>
						</ColLg6>
					</HomeRow>
				</StepsCardSectionContent>
			</HomeContainer>
		</HomeSection>
	);
};
