import React from 'react';
import Table from 'rc-table';
import Collapse, {Panel} from 'rc-collapse';
import Progress from 'components/progress-box/progress-box';

import {
	OrderListHeader,
	TrackID,
	Status,
	OrderMeta,
	Meta,
	CardWrapper,
	OrderDetail,
	DeliveryInfo,
	DeliveryAddress,
	Address,
	CostCalculation,
	PriceRow,
	Price,
	ProgressWrapper,
	OrderTable,
	OrderTableMobile,
} from './order-card.style';

import {CURRENCY} from 'utils/constant';

const components = {
	table: OrderTable,
};

const OrderCard = ({onClick, className, columns, progressData, orders}) => {
	return (
		<>
			<Collapse
				accordion={true}
				className={`accordion ${className}`}
				defaultActiveKey="active"
			>
				{orders.map(order => (
					<Panel
						header={
							<CardWrapper onClick={() => onClick(order)}>
								<OrderListHeader>
									<TrackID>
										Sipariş <span>#{order.id}</span>
									</TrackID>
									<Status>{progressData[order.order_status - 1]}</Status>
								</OrderListHeader>

								<OrderMeta>
									<Meta>
										Sipariş Tarihi: <span>{order.date}</span>
									</Meta>
									<Meta>
										Ziyaret Zamanı: <span>{order.delivery}</span>
									</Meta>
									<Meta className="price">
										Toplam Tutar:
										<span>
											{CURRENCY}
											{order.paid}
										</span>
									</Meta>
								</OrderMeta>
							</CardWrapper>
						}
						headerClass="accordion-title"
						key={order.id}
					>
						<OrderDetail>
							<DeliveryInfo>
								<DeliveryAddress>
									<h3>Teslimat Adresi</h3>
									<Address>
										{order?.address?.province +
											' ' +
											order?.address?.district +
											' ' +
											order?.address?.neighbourhood +
											' ' +
											order?.address?.address}
									</Address>
								</DeliveryAddress>

								<CostCalculation>
									<PriceRow>
										Ara Toplam
										<Price>
											{CURRENCY}
											{order.total}
										</Price>
									</PriceRow>
									<PriceRow>
										İndirim
										<Price>
											{CURRENCY}
											{parseFloat(order.paid) -
												parseFloat(order.total).toFixed(2)}
										</Price>
									</PriceRow>
									<PriceRow className="grandTotal">
										Toplam
										<Price>
											{CURRENCY}
											{order.paid}
										</Price>
									</PriceRow>
								</CostCalculation>
							</DeliveryInfo>

							<ProgressWrapper>
								<Progress data={progressData} status={order.order_status} />
							</ProgressWrapper>

							<OrderTableMobile>
								<Table
									columns={columns}
									data={order.items}
									rowKey={record => record.id}
									components={components}
									scroll={{x: 450}}
									// scroll={{ y: 250 }}
								/>
							</OrderTableMobile>
						</OrderDetail>
					</Panel>
				))}
			</Collapse>
		</>
	);
};

export default OrderCard;
