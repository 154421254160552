import {SEO} from '../../components/seo/seo';
import CheckoutResult from '../../features/checkouts/checkout-result/checkout-result';
import Footer from '../../layouts/footer/footer';

const CheckoutResultPage = () => {
	return (
		<>
			<SEO
				title="Ödeme Sonucu"
				description="Yıkama Yeri Hizmet Ödemesi Sonucu"
			/>
			<CheckoutResult />
			<Footer />
		</>
	);
};

export default CheckoutResultPage;
