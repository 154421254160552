import React from 'react';
import {Counter} from 'components/counter/counter';
import {CloseIcon} from 'assets/icons/CloseIcon';
import {CURRENCY} from 'utils/constant';
import {
	ItemBox,
	Image,
	Information,
	Name,
	Price,
	Weight,
	Total,
	RemoveButton,
} from './cart-item.style';

export const CartItem = ({data, onDecrement, onIncrement, onRemove}) => {
	const {title, image, price, salePrice, quantity, m2} = data;
	const displayPrice = salePrice
		? m2
			? m2.value * salePrice
			: salePrice
		: m2
		? m2.value * price
		: price;
	return (
		<ItemBox>
			<Counter
				value={quantity}
				onDecrement={onDecrement}
				onIncrement={onIncrement}
				variant="lightVertical"
			/>
			<Image src={image ? image : '/assets/images/default/default_quote.jpg'} />
			<Information>
				<Name>{title}</Name>
				<Price>
					{displayPrice} {CURRENCY}
				</Price>
				<Weight>
					{quantity} {m2 && m2.value ? ' x ' + m2.label + ' ' : ''}
				</Weight>
			</Information>
			<Total>
				{(quantity * displayPrice).toFixed(2)} {CURRENCY}
			</Total>
			<RemoveButton onClick={onRemove}>
				<CloseIcon />
			</RemoveButton>
		</ItemBox>
	);
};
