import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

const CartPopupBody = styled.div`
	height: auto;
	width: 385px;
	display: flex;
	flex-direction: column;
	border-radius: ${themeGet('radii.base', '6px')};
	background-color: ${themeGet('colors.white', '#ffffff')};
	box-sizing: content-box;

	@media (max-width: 767px) {
		width: 100%;
	}

	.cart-scrollbar {
		height: 100%;
		max-height: calc(100vh - 245px);

		@media (max-width: 767px) {
			max-height: 330px;
		}
	}
`;

const PopupHeader = styled.div`
	padding: 15px 25px;
	background-color: ${themeGet('colors.white', '#ffffff')};
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${themeGet('colors.gray.500', '#f1f1f1')};

	@media (max-width: 766px) {
		justify-content: center;
	}
`;

const PopupItemCount = styled.div`
	display: inline-flex;
	align-items: center;
	color: ${themeGet('colors.primary.regular', '#009e7f')};

	span {
		font-family: ${themeGet('fonts.body', 'Lato')};
		font-size: ${themeGet('fontSizes.base', '15')}px;
		font-weight: ${themeGet('fontWeights.bold', '700')};
		color: ${themeGet('colors.primary.regular', '#009e7f')};
		padding-left: 10px;

		@media (max-width: 767px) {
			font-size: ${themeGet('fontSizes.sm', '13')}px;
		}
	}
`;

const CloseButton = styled.button`
	width: 14px;
	height: 14px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	border: 0;
	outline: 0;
	flex-shrink: 0;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.25);
	transition: all 0.4s ease;
	background-color: transparent;

	&:hover {
		color: ${themeGet('colors.red.regular', '#ea4d4a')};
	}

	@media (max-width: 767px) {
		position: absolute;
		top: -45px;
		background-color: ${themeGet('colors.white', '#ffffff')};
		width: 35px;
		height: 35px;
		border-radius: 50%;
		color: rgba(0, 0, 0, 0.5);
	}

	&.fixedCartClose {
		@media (min-width: 991px) {
			display: none;
		}
	}
`;

const ItemWrapper = styled.div`
	width: 100%;
	height: auto;
`;

const ItemCards = styled.div`
	width: 100%;
	padding: 15px 25px;
	display: inline-flex;
	align-items: center;
	background-color: ${themeGet('colors.white', '#ffffff')};
	margin-bottom: 1px;
	box-sizing: border-box;
`;

const ItemImgWrapper = styled.div`
	width: 60px;
	height: 60px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	margin-right: 15px;
	flex-shrink: 0;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const ItemDetails = styled.div`
	display: inline-flex;
	flex-direction: column;
	width: 100%;
`;

const ItemTitle = styled.span`
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.base', '15')}px;
	font-weight: ${themeGet('fontWeights.regular', '400')};
	color: ${themeGet('colors.text.bold', '#0D1136')};
	margin-bottom: 10px;
`;

const ItemPrice = styled.span`
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.base', '15')}px;
	font-weight: ${themeGet('fontWeights.bold', '700')};
	color: ${themeGet('colors.primary.regular', '#009e7f')};
	margin-bottom: 10px;
`;

const ItemWeight = styled.span`
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.sm', '13')}px;
	font-weight: ${themeGet('fontWeights.regular', '400')};
	color: ${themeGet('colors.text.regular', '#77798c')};
`;

const TotalPrice = styled.span`
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.base', '15')}px;
	font-weight: ${themeGet('fontWeights.bold', '700')};
	color: ${themeGet('colors.text.bold', '#0D1136')};
	flex-shrink: 0;
	margin-left: auto;
`;

const DeleteButton = styled.button`
	width: 10px;
	height: 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	border: 0;
	outline: 0;
	margin-left: 15px;
	flex-shrink: 0;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.25);
	transition: all 0.4s ease;
	background-color: transparent;

	&:hover {
		color: #ea4d4a;
	}
`;

const PromoCode = styled.span`
	margin: 20px 0;
	display: flex;
	justify-content: center;

	> button {
		border: 0;
		outline: 0;
		box-shadow: none;
		background-color: transparent;
		display: inline-flex;
		cursor: pointer;
		font-family: ${themeGet('fonts.body', 'Lato')};
		font-size: ${themeGet('fontSizes.base', '15')}px;
		font-weight: ${themeGet('fontWeights.bold', '700')};
		color: ${themeGet('colors.primary.regular', '#009e7f')};
		transition: color 0.35s ease;
		&:hover {
			color: ${themeGet('colors.primary.hover', '#019376')};
		}
	}
`;

const CheckoutButton = styled.button`
	height: 48px;
	width: calc(100% - 30px);
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${themeGet('colors.primary.regular', '#009e7f')};
	padding: 0;
	border-radius: 48px;
	box-shadow: ${themeGet('shadows.base', '0 3px 6px rgba(0, 0, 0, 0.16)')};
	border: 0;
	outline: 0;
	cursor: pointer;
	/* margin-top: auto; */
	margin-bottom: 15px;
	margin-left: 15px;

	@media (max-width: 767px) {
		height: 45px;
	}

	> .cart-title-span {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 30px;
	}
`;

const CheckoutButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: auto;
	flex-shrink: 0;
`;

const Title = styled.span`
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.base', '15')}px;
	font-weight: ${themeGet('fontWeights.bold', '700')};
	color: ${themeGet('colors.white', '#ffffff')};
	padding-left: 20px;
	padding-right: 10px;
`;

const PriceBox = styled.span`
	width: auto;
	height: 44px;
	padding: 0 30px;
	overflow: hidden;
	border-radius: 28px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: ${themeGet('colors.white', '#ffffff')};
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.base', '15')}px;
	font-weight: ${themeGet('fontWeights.bold', '700')};
	color: ${themeGet('colors.primary.regular', '#009e7f')};
	margin-right: 2px;

	@media (max-width: 767px) {
		height: 41px;
	}
`;

const NoProductMsg = styled.span`
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.base', '15')}px;
	font-weight: ${themeGet('fontWeights.bold', '700')};
	color: ${themeGet('colors.text.regular', '#77798c')};
	display: block;
	width: 100%;
	padding: 40px 0;
	text-align: center;
`;

export const NoProductImg = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	margin-top: 50px;

	@media (max-width: 580px) {
		margin-top: 20px;
	}

	svg {
		width: 140px;
		height: auto;

		@media (max-width: 580px) {
			width: 110px;
		}
	}
`;

const CouponBoxWrapper = styled.div`
	width: 100%;
	padding: 0 15px;
	flex-direction: column;
	padding-right: 22px;
`;

const CouponCode = styled.p`
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.base', '15')}px;
	font-weight: ${themeGet('fontWeights.medium', '500')};
	color: ${themeGet('colors.text.regular', '#77798c')};

	width: 100%;
	display: flex;
	justify-content: center;

	span {
		font-weight: ${themeGet('fontWeights.bold', '700')};
		color: ${themeGet('colors.primary.regular', '#009e7f')};
		margin-left: 5px;
	}
`;

const ErrorMsg = styled.span`
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: calc(${themeGet('fontSizes.base', '15')}px - 1px);
	font-weight: ${themeGet('fontWeights.regular', '400')};
	color: ${themeGet('colors.secondary.hover', '#FF282F')};
	padding-top: 10px;
	display: flex;
	justify-content: center;
`;

const CartSlidePopup = styled.div`
	width: 420px;
	height: 100vh;
	background-color: ${themeGet('colors.white', '#ffffff')};
	position: fixed;
	bottom: 0;
	right: -450px;
	z-index: 1010;
	box-shadow: ${themeGet('shadows.big', '0 21px 36px rgba(0, 0, 0, 0.16)')};
	transition: all 0.35s ease-in-out;

	@media (max-width: 580px) {
		width: 100%;
		display: none;
	}

	@media (min-width: 581px) {
		display: block;
	}

	&.cartPopupFixed {
		right: 0;
	}

	${CartPopupBody} {
		height: 100%;
		width: 100%;
	}

	${ItemWrapper} {
		/* height: calc(100vh - 240px); */
		max-height: calc(100vh - 245px);
		background-color: ${themeGet('colors.white', '#ffffff')};
	}

	${ItemCards} {
		border-bottom: 1px solid ${themeGet('colors.gray.200', '#f7f7f7')};
		margin-bottom: 0;
	}

	@media (max-width: 767px) {
		${PopupHeader} {
			justify-content: space-between;
		}

		${CloseButton} {
			top: auto;
			position: relative;
			background-color: transparent;
		}
	}
`;

export {
	CartSlidePopup,
	CartPopupBody,
	PopupHeader,
	PopupItemCount,
	PromoCode,
	CloseButton,
	ItemCards,
	ItemImgWrapper,
	ItemDetails,
	ItemTitle,
	ItemPrice,
	ItemWeight,
	TotalPrice,
	DeleteButton,
	CheckoutButton,
	CheckoutButtonWrapper,
	Title,
	PriceBox,
	NoProductMsg,
	ItemWrapper,
	CouponBoxWrapper,
	CouponCode,
	ErrorMsg,
};
