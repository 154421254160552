import React, {useContext, useState} from 'react';
import {
	Wrapper,
	Container,
	Heading,
	SubHeading,
	Button,
	LinkButton,
	Offer,
} from './authentication-form.style';
import {AuthContext} from 'contexts/auth/auth.context';
import {Input} from 'components/forms/input';
import useAuth from '../../services/use-auth';
import {toast} from 'react-toastify';
import {closeModal} from '@redq/reuse-modal';
import {useHistory} from 'react-router-dom';
import {LOGIN_PAGE, REGISTER_PAGE} from '../../site-settings/site-navigation';

export default function ForgotPasswordModal() {
	const {authDispatch} = useContext(AuthContext);
	const history = useHistory();
	const toggleSignInForm = () => {
		if (history.location.pathname === LOGIN_PAGE) {
			closeModal();
		} else {
			authDispatch({
				type: 'SIGNIN',
			});
		}
	};
	const toggleSignUpForm = () => {
		if (history.location.pathname === REGISTER_PAGE) {
			closeModal();
		} else {
			authDispatch({
				type: 'SIGNUP',
			});
		}
	};
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const authService = useAuth();
	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		authService
			.resetPasswordLink(email)
			.then(
				res => {
					if (res.success) {
						toast(res.message, {type: 'info'});
						closeModal();
					} else {
						toast(res.message, {type: 'warning'});
					}
				},
				err => {
					if (err.response.data && err.response.data.message) {
						toast(err.response.data.message, {type: 'error'});
					}
				},
			)
			.then(() => setLoading(false));
	};
	return (
		<Wrapper>
			<Container style={{paddingBottom: 30}}>
				<Heading>Şifremi Unuttum</Heading>

				<SubHeading>
					Email adresinize şifrenizi sıfırlamanız için bir link göndereceğiz.
				</SubHeading>
				<form onSubmit={handleSubmit}>
					<Input
						type="email"
						placeholder="Email adresiniz"
						height="48px"
						value={email}
						onChange={e => {
							setEmail(e.target.value);
						}}
						backgroundColor="#F7F7F7"
						mb="10px"
					/>
					<Button
						variant="primary"
						size="big"
						style={{width: '100%'}}
						disabled={loading}
						loading={loading}
						type="submit"
					>
						{loading ? 'Yükleniyor...' : 'Sıfırla'}
					</Button>
				</form>
				<Offer style={{padding: '20px 0 0'}}>
					{history.location.pathname === REGISTER_PAGE ? (
						<LinkButton onClick={toggleSignUpForm}>Kayıt Ol</LinkButton>
					) : (
						<LinkButton onClick={toggleSignInForm}>Giriş yap</LinkButton>
					)}
				</Offer>
			</Container>
		</Wrapper>
	);
}
