import React from 'react';
import {Modal} from '@redq/reuse-modal';
import {SEO} from 'components/seo/seo';
import CheckoutPayment from 'features/checkouts/checkout-payment/checkout-payment';
import Footer from '../../layouts/footer/footer';

const CheckoutPaymentPage = ({deviceType}) => {
	return (
		<>
			<SEO title="Ödeme" description="Yıkama Yeri Hizmet Ödemesi" />
			<Modal>
				<CheckoutPayment deviceType={deviceType} />
				<Footer />
			</Modal>
		</>
	);
};

export default CheckoutPaymentPage;
