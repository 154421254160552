import {useEffect} from 'react';
import {useAppDispatch, useAppState} from 'contexts/app/app.provider';
import Sticky from 'react-stickynode';
import {LayoutWrapper} from './LayoutWrapper.style';
import MobileHeader from '../header/mobile-header';
import Header from '../header/header';
import {withRouter} from 'react-router-dom';
import useCategory from 'services/use-category';

const Layout = ({className, children, location, ...rest}) => {
	const isSticky = useAppState('isSticky');
	const appDispatch = useAppDispatch();
	const categoryService = useCategory();
	const checkFooterScroll = () => {
		const footer = document.getElementById('page-footer');
		let isSticky = true;
		if (footer) {
			if (
				document.documentElement.scrollTop + window.innerHeight >=
				footer.offsetTop
			) {
				isSticky = false;
			}
		}
		appDispatch({
			type: 'SET_CONTENT_STICKY',
			payload: isSticky,
		});
	};
	useEffect(() => {
		appDispatch({
			type: 'SET_CATEGORY_LOADING',
			payload: true,
		});
		categoryService
			.getCategories()
			.then(
				res => {
					if (res.success) {
						appDispatch({
							type: 'SET_CATEGORY_DATA',
							payload: res.data,
						});
					} else {
						appDispatch({
							type: 'SET_CATEGORY_DATA',
							payload: [],
						});
					}
				},
				err => {
					appDispatch({
						type: 'SET_CATEGORY_DATA',
						payload: [],
					});
				},
			)
			.then(() => {
				appDispatch({
					type: 'SET_CATEGORY_LOADING',
					payload: false,
				});
			});

		document.addEventListener('scroll', checkFooterScroll);
		return () => {
			document.removeEventListener('scroll', checkFooterScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<LayoutWrapper className={`layoutWrapper ${className ? className : ''}`}>
			<Sticky enabled={isSticky} innerZ={1001}>
				<MobileHeader
					className={`${isSticky ? 'sticky' : 'unSticky'} desktop`}
				/>
				<Header className={`${isSticky ? 'sticky' : 'unSticky'}`} />
			</Sticky>
			{children}
		</LayoutWrapper>
	);
};

export default withRouter(Layout);
