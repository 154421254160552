import {
	TripleCardBox,
	TripleCardBoxContent,
	TripleCardBoxTitle,
	TripleCardLead,
	TripleCardResponsiveColumn,
	TripleCardSection,
	TripleCardSectionActionLink,
	TripleCardSectionActions,
	TripleCardSectionContent,
	TripleCardSectionHead,
	TripleCardSubtitle,
	TripleCardTitle,
} from './triple-card.style';
import {
	HomeContainer,
	HomePageImage,
	HomeRow,
} from '../../../features/homepage/homepage.style';

export const TripleCard = () => {
	return (
		<TripleCardSection>
			<HomeContainer>
				<TripleCardSectionHead>
					<TripleCardSubtitle>Nasıl Çalışıyoruz</TripleCardSubtitle>
					<TripleCardTitle>Yıkama Yeri Hizmetleri</TripleCardTitle>
					<TripleCardLead>
						Neque proin fames et parturient laoreet condimentum aliquam
						suspendisse consectetur etiam a vehicula neque molestie eu massa
						parturient magna praesent ultricies feugiat.
					</TripleCardLead>
				</TripleCardSectionHead>
				<TripleCardSectionContent>
					<HomeRow>
						<TripleCardResponsiveColumn>
							<TripleCardBox>
								<div>
									<HomePageImage src="http://demo.themenio.com/kovid19/images/gfx/spread-azure-a.png" />
								</div>
								<TripleCardBoxContent>
									<TripleCardBoxTitle>
										Person-to-person spread as close contact with infected
									</TripleCardBoxTitle>
									<p>
										The coronavirus is thought to spread mainly from person to
										person. This can happen between people who are in close
										contact with one another.
									</p>
								</TripleCardBoxContent>
							</TripleCardBox>
						</TripleCardResponsiveColumn>
						<TripleCardResponsiveColumn>
							<TripleCardBox>
								<div>
									<HomePageImage src="http://demo.themenio.com/kovid19/images/gfx/spread-azure-b.png" />
								</div>
								<TripleCardBoxContent>
									<TripleCardBoxTitle>
										Touching or contact with infected surfaces or objects
									</TripleCardBoxTitle>
									<p>
										A person can get COVID-19 by touching a surface or object
										that has the virus on it and then touching their own mouth,
										nose, or possibly their eyes.
									</p>
								</TripleCardBoxContent>
							</TripleCardBox>
						</TripleCardResponsiveColumn>
						<TripleCardResponsiveColumn>
							<TripleCardBox>
								<div>
									<HomePageImage src="http://demo.themenio.com/kovid19/images/gfx/spread-azure-c.png" />
								</div>
								<TripleCardBoxContent>
									<TripleCardBoxTitle>
										Droplets that from infected person coughs or sneezes
									</TripleCardBoxTitle>
									<p>
										The coronavirus is thought to spread mainly from person to
										person. This can happen between people who are in close
										contact with one another.
									</p>
									<p></p>
								</TripleCardBoxContent>
							</TripleCardBox>
						</TripleCardResponsiveColumn>
					</HomeRow>
				</TripleCardSectionContent>
				<TripleCardSectionActions>
					<li>
						<TripleCardSectionActionLink to="/contact-us">
							Bizimle İletişime Geç
						</TripleCardSectionActionLink>
					</li>
				</TripleCardSectionActions>
			</HomeContainer>
		</TripleCardSection>
	);
};
