class KeyValuePair {
	constructor({key, value, slug}) {
		this.key = key;
		this.value = value;
		if (slug) {
			this.slug = slug;
		}
	}
}

export {KeyValuePair};
