import React from 'react';
import {
	DrawerWrapper,
	LogoWrapper,
	MobileHeaderInnerWrapper,
	MobileHeaderWrapper,
	SearchWrapper,
} from './header.style';
import LogoImage from 'assets/images/logo.png';
import {withRouter} from 'react-router-dom';
import useDimensions from '../../utils/useComponentSize';
import MobileDrawer from './mobile-drawer';
import Logo from '../logo/logo';
import {PhoneIcon} from '../../assets/icons/PhoneIcon';

const MobileHeader = ({className}) => {
	const [mobileHeaderRef] = useDimensions();

	const handleCallButton = () => {};

	return (
		<MobileHeaderWrapper>
			<MobileHeaderInnerWrapper className={className} ref={mobileHeaderRef}>
				<DrawerWrapper>
					<MobileDrawer />
				</DrawerWrapper>

				<LogoWrapper>
					<Logo imageUrl={LogoImage} alt="shop logo" />
				</LogoWrapper>

				<SearchWrapper
					href="tel:08508881470"
					onClick={handleCallButton}
					className="searchIconWrapper"
				>
					<PhoneIcon />
				</SearchWrapper>
			</MobileHeaderInnerWrapper>
		</MobileHeaderWrapper>
	);
};

export default withRouter(MobileHeader);
