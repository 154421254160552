import React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import * as icons from 'assets/icons/category-icons';
import {Box} from '../box/box';
import {Text} from '../text/text';

const CardBox = styled.div(props =>
	css({
		backgroundColor: ['gray.200', 'gray.200', '#fff'],
		textAlign: 'center',
		padding: '1rem 10px',
		borderRadius: [10, 10, 6],
		cursor: 'pointer',
		border: props.active ? '2px solid' : '2px solid',
		borderColor: props.active ? '#212121' : ['gray.200', 'gray.200', '#fff'],
	}),
);

const Icon = ({name, style}) => {
	const TagName = icons[name];
	return !!TagName ? <TagName style={style} /> : <p>Invalid icon {name}</p>;
};
export const CardMenu = ({data, onClick, active, style}) => {
	return (
		<>
			{data.map(({id, title, icon, slug}) => (
				<CardBox
					key={id}
					onClick={() => onClick(slug)}
					active={slug && active && slug === active}
					role="button"
					style={style}
				>
					<Box
						padding="1rem"
						height={80}
						alignItems="center"
						justifyContent="center"
						display="flex"
					>
						<Icon name={icon} style={{height: 40, width: 'auto'}} />
					</Box>
					<Text as="span" color="#212121" fontSize={14} fontWeight={600}>
						{title}
					</Text>
				</CardBox>
			))}
		</>
	);
};
