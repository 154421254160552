import React, {useContext} from 'react';
import {Route} from 'react-router-dom';
import {AuthContext} from '../../contexts/auth/auth.context';
import {Redirect} from 'react-router';
import {useMedia} from 'utils/use-media';

export const MakeRouteWithSubRoutes = route => {
	const {authState} = useContext(AuthContext);
	const mobile = useMedia('(max-width: 580px)');
	const tablet = useMedia('(max-width: 991px)');
	const desktop = useMedia('(min-width: 992px)');
	if (
		route.private &&
		route.private === true &&
		authState.isAuthenticated !== true
	) {
		return (
			<Route
				render={props => (
					<Redirect
						to={{
							pathname: '/login',
							state: {from: props.location},
						}}
					/>
				)}
			/>
		);
	} else {
		return (
			<Route
				path={route.path}
				render={props => (
					<route.component
						{...props}
						routes={route.routes}
						deviceType={{mobile, tablet, desktop}}
					/>
				)}
			/>
		);
	}
};
