import {Switch} from 'react-router';
import {MakeRouteWithSubRoutes} from './base/makeRouteWithSubRoutes';
import {
	ABOUT_US_PAGE,
	BLOG_PAGE,
	BLOG_POST_PAGE,
	// CATEGORY_BLOG_PAGE,
	CATEGORY_PROVINCE_BLOG_PAGE,
	CHANGE_PASSWORD_PAGE,
	CHECKOUT_PAGE,
	CHECKOUT_PAY_PAGE,
	CHECKOUT_RESULT_PAGE,
	CONTACT_US_PAGE,
	COUPONS_PAGE,
	HELP_PAGE,
	HOME_PAGE,
	LOGIN_PAGE,
	ORDER_PAGE,
	PROFILE_PAGE,
	REGISTER_PAGE,
	RESET_PASSWORD_PAGE,
	SERVICE_PAGE,
	TERMS_PAGE,
	VERIFY_EMAIL_PAGE,
} from 'site-settings/site-navigation';
import FAQPage from 'pages/faq';
// import CategoryBlogPage from "pages/category_blog/category-blog";
import IndexPage from 'pages/index';
import CategoryProvinceBlog from '../pages/category_blog/category-province-blog';
import ProfilePage from '../pages/profile';
import ServicePage from '../pages/service/service';
import CheckoutPage from '../pages/checkout';
import PrivacyPage from '../pages/privacy';
import CheckoutPaymentPage from '../pages/checkout/payment';
import CheckoutResultPage from '../pages/checkout/result';
import BlogPage from '../pages/blog';
import OrderPage from '../pages/order';
import {BlogPostPage} from '../pages/blog/post';
import {CouponsPage} from '../pages/coupons/coupons';
import {ResetPasswordPage} from '../pages/reset-password/reset-password';
import {EmailVerificationPage} from '../pages/email-verification/email-verification';
import {ChangePasswordPage} from '../pages/profile/change-password';
import {NotFoundPage} from '../pages/not-found/not-found';
import {AboutPage} from '../pages/about/about';
import {ContactUsPage} from '../pages/contact-us/contact-us';
import LoginPage from '../pages/login';
import RegisterPage from '../pages/register';

const routes = [
	{
		path: ABOUT_US_PAGE,
		component: AboutPage,
	},
	{
		path: CHANGE_PASSWORD_PAGE,
		component: ChangePasswordPage,
		private: true,
	},
	{
		path: VERIFY_EMAIL_PAGE,
		component: EmailVerificationPage,
	},
	{
		path: REGISTER_PAGE,
		component: RegisterPage,
		private: false,
	},
	{
		path: LOGIN_PAGE,
		component: LoginPage,
		private: false,
	},
	{
		path: RESET_PASSWORD_PAGE,
		component: ResetPasswordPage,
		private: false,
	},
	{
		path: COUPONS_PAGE,
		component: CouponsPage,
	},
	{
		path: ORDER_PAGE,
		component: OrderPage,
		private: true,
	},
	{
		path: BLOG_POST_PAGE,
		component: BlogPostPage,
	},
	{
		path: BLOG_PAGE,
		component: BlogPage,
	},
	{
		path: CHECKOUT_PAY_PAGE,
		component: CheckoutPaymentPage,
		private: true,
	},
	{
		path: CHECKOUT_RESULT_PAGE + '/:token',
		component: CheckoutResultPage,
		private: true,
	},
	{
		path: CHECKOUT_RESULT_PAGE,
		component: CheckoutResultPage,
		private: true,
	},
	{
		path: CHECKOUT_PAGE,
		component: CheckoutPage,
		private: true,
	},
	{
		path: TERMS_PAGE,
		component: PrivacyPage,
	},
	{
		path: PROFILE_PAGE,
		component: ProfilePage,
		private: true,
	},
	{
		path: HELP_PAGE,
		component: FAQPage,
	},
	{
		path: CONTACT_US_PAGE,
		component: ContactUsPage,
	},
	{
		path: SERVICE_PAGE,
		component: ServicePage,
	},
	{
		path: CATEGORY_PROVINCE_BLOG_PAGE,
		component: CategoryProvinceBlog,
	},
	{
		path: HOME_PAGE,
		component: IndexPage,
		exact: true,
	},
	{
		path: '/',
		component: NotFoundPage,
	},
];

export const Routes = props => {
	return (
		<Switch>
			{routes.map((route, index) => (
				<MakeRouteWithSubRoutes key={index} {...route} />
			))}
		</Switch>
	);
};
