import {
	Col,
	HeadingSection,
	Row,
	SettingsForm,
	SettingsFormContent,
	Title,
} from '../settings/settings.style';
import {Label} from 'components/forms/label';
import {Input} from 'components/forms/input';
import {Button} from 'components/button/button';
import React, {useState} from 'react';
import useUser from 'services/use-user';
import {toast} from 'react-toastify';

const INITIAL_STATE = {
	current_password: '',
	password: '',
	password_repeat: '',
};
export const ChangePassword = () => {
	const [loading, setLoading] = useState(false);
	const [initialForm, setInitialForm] = useState(INITIAL_STATE);
	const userService = useUser();
	const handleChange = e => {
		const {value, name} = e.target;
		setInitialForm({...initialForm, [name]: value});
	};
	const handleSave = e => {
		e.preventDefault();
		if (initialForm.current_password.length < 6) {
			toast('Lütfen şifrenizi 6 karakterden uzun giriniz.', {
				type: 'warning',
			});
			return false;
		} else if (initialForm.password.length < 6) {
			toast('Lütfen yeni şifrenizi 6 karakterden uzun giriniz.', {
				type: 'warning',
			});
			return false;
		} else if (initialForm.password_repeat.length < 6) {
			toast('Lütfen yeni şifre tekrarın 6 karakterden uzun giriniz', {
				type: 'warning',
			});
			return false;
		} else if (initialForm.password_repeat !== initialForm.password) {
			toast('Lütfen yeni şifrenizi ve tekrarını aynı giriniz.', {
				type: 'warning',
			});
			return false;
		}
		setLoading(true);
		userService
			.changePassword(initialForm)
			.then(
				res => {
					if (res.success) {
						toast(res.message, {type: 'info'});
						setInitialForm(INITIAL_STATE);
					} else {
						toast(res.message, {type: 'warning'});
					}
				},
				err => {
					if (err.response.data && err.response.data.message) {
						toast(err.response.data.message, {type: 'error'});
					}
				},
			)
			.then(() => setLoading(false));
	};
	return (
		<SettingsForm>
			<SettingsFormContent>
				<HeadingSection>
					<Title>Şifremi Değiştir</Title>
				</HeadingSection>
				<form onSubmit={handleSave}>
					<Row style={{alignItems: 'flex-end', marginBottom: '50px'}}>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Label>Şuanki Şifreniz</Label>
							<Input
								type="password"
								label="Şuanki Şifreniz"
								name="current_password"
								value={initialForm.current_password}
								onChange={handleChange}
								backgroundColor="#F7F7F7"
								height="48px"
							/>
						</Col>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Label>Yeni Şifreniz</Label>
							<Input
								type="password"
								label="Yeni Şifreniz"
								name="password"
								value={initialForm.password}
								onChange={handleChange}
								backgroundColor="#F7F7F7"
								height="48px"
							/>
						</Col>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Label>Yeni Şifreniz (Tekrar)</Label>
							<Input
								type="password"
								label="Yeni Şifreniz (Tekrar)"
								name="password_repeat"
								value={initialForm.password_repeat}
								onChange={handleChange}
								backgroundColor="#F7F7F7"
								height="48px"
							/>
						</Col>
						<Col xs={12} sm={5} md={5} lg={5}>
							<Button
								type="submit"
								size="big"
								style={{width: '100%'}}
								loading={loading}
								disabled={loading}
							>
								{loading ? 'Kaydediliyor...' : 'Kaydet'}
							</Button>
						</Col>
					</Row>
				</form>
			</SettingsFormContent>
		</SettingsForm>
	);
};
