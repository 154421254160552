import React, {useContext, useEffect} from 'react';
import {ProfileContext} from 'contexts/profile/profile.context';
import {CardHeader} from 'components/card-header/card-header';
import {ButtonGroup} from 'components/button-group/button-group';
import RadioGroup from 'components/radio-group/radio-group';
import RadioCard from 'components/radio-card/radio-card';
import {Box} from 'components/box/box';
import {Plus} from 'assets/icons/PlusMinus';
import {handleModal} from '../checkouts/checkout-modal';
import UpdateAddress from 'components/address-card/address-card';
import {Button} from 'components/button/button';
import useUser from '../../services/use-user';
import {parseAddresses} from '../../models/Address';
import {toast} from 'react-toastify';

const Address = ({
	increment = false,
	flexStart = false,
	icon = false,
	checkMissingAddress,
	buttonProps = {
		size: 'big',
		variant: 'outlined',
		type: 'button',
		className: 'add-button',
	},
}) => {
	const userService = useUser();
	const {
		state: {
			address: {data},
		},
		dispatch,
	} = useContext(ProfileContext);

	useEffect(() => {
		dispatch({
			type: 'SET_ADDRESS_LOADING',
			payload: {
				loading: true,
			},
		});
		userService
			.getAddresses()
			.then(res => {
				if (res.success) {
					dispatch({
						type: 'SET_ADDRESSES',
						payload: parseAddresses(res.data),
					});
				}
			})
			.then(() =>
				dispatch({
					type: 'SET_ADDRESS_LOADING',
					payload: {
						loading: false,
					},
				}),
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnDelete = async item => {
		const item_id = item.id;
		dispatch({
			type: 'SET_ADDRESS_LOADING',
			payload: {
				id: item_id,
				loading: true,
			},
		});
		userService.deleteAddress(item_id).then(
			res => {
				if (res.success) {
					dispatch({
						type: 'DELETE_ADDRESS',
						payload: item_id,
					});
					toast(res.message, {type: 'info'});
				} else {
					toast(res.message, {type: 'error'});
				}
			},
			err => {
				toast(err.response.data.message, {type: 'error'});
			},
		);
	};

	const handleOnSetDefault = item => {
		const item_id = item.id;
		dispatch({
			type: 'SET_ADDRESS_LOADING',
			payload: {
				id: item_id,
				loading: true,
			},
		});
		userService
			.setAddressDefault(item_id)
			.then(
				res => {
					if (res.success) {
						dispatch({
							type: 'SET_PRIMARY_ADDRESS',
							payload: item_id,
						});
						toast(res.message, {type: 'info'});
					} else {
						toast(res.message, {type: 'error'});
					}
				},
				err => {
					toast(err.response.data.message, {type: 'error'});
				},
			)
			.then(() => {
				dispatch({
					type: 'SET_ADDRESS_LOADING',
					payload: {
						id: item_id,
						loading: false,
					},
				});
			});
	};

	return (
		<>
			<CardHeader increment={increment}>Lütfen Adresinizi Seçiniz</CardHeader>
			<ButtonGroup flexStart={flexStart}>
				<RadioGroup
					items={data ?? []}
					component={item => (
						<RadioCard
							id={item.id}
							key={item.id}
							title={item.title}
							isDefault={item.default ?? false}
							content={item.address}
							name="address"
							checked={item.default}
							onChange={() => handleOnSetDefault(item)}
							onEdit={() => handleModal(UpdateAddress, item)}
							onDelete={() => handleOnDelete(item)}
						/>
					)}
					secondaryComponent={
						<Button
							{...buttonProps}
							onClick={() => handleModal(UpdateAddress, 'add-address-modal')}
							style={{borderStyle: 'dashed'}}
						>
							{icon && (
								<Box mr={2}>
									<Plus width="10px" />
								</Box>
							)}
							Adres Ekle
						</Button>
					}
				/>
			</ButtonGroup>
		</>
	);
};

export default Address;
