import React, {useState} from 'react';
import {CouponBoxWrapper, Error} from './coupon.style';
import {Input} from 'components/forms/input';
import {Button} from 'components/button/button';
import usePayment from '../../services/use-payment';
import {toast} from 'react-toastify';
import {useCart} from '../../contexts/cart/use-cart';
// import useCoupon from 'data/use-coupon';
// import { useCart } from 'contexts/cart/use-cart';

const Coupon = ({
	disabled,
	className,
	style,
	errorMsgFixed = false,
	...props
}) => {
	const {applyCoupon} = useCart();
	const [code, setCode] = useState('');
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const paymentService = usePayment();
	const handleApplyCoupon = async () => {
		setLoading(true);
		paymentService
			.getCoupon(code)
			.then(
				res => {
					if (res.success) {
						applyCoupon(res.data);
						setError(null);
						toast(res.message, {type: 'info'});
					} else {
						toast(res.message, {type: 'warning'});
						setError(res.message);
					}
				},
				err => {
					toast(err.response.data.message, {type: 'warning'});
					setError(err.response.data.message);
				},
			)
			.then(() => setLoading(false));
	};
	const handleOnChange = e => {
		setCode(e.currentTarget.value);
	};
	return (
		<>
			<CouponBoxWrapper
				className={className ? className : 'boxedCoupon'}
				style={style}
			>
				<Input
					onChange={handleOnChange}
					value={code}
					placeholder="Kupon kodunuzu giriniz"
					{...props}
				/>
				<Button
					type="button"
					onClick={handleApplyCoupon}
					disabled={disabled || loading}
					padding="0 30px"
				>
					{loading ? 'Kontrol Ediliyor' : 'Uygula'}
				</Button>
			</CouponBoxWrapper>
			{error && <Error errorMsgFixed={errorMsgFixed}>{error}</Error>}
		</>
	);
};

export default Coupon;
