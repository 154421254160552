import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const PhoneVerificationContainer = styled.div`
	text-align: center;
`;
export const Heading = styled.div`
	font-family: ${themeGet('fontFamily.heading', 'sans-serif')};
	font-size: ${themeGet('fontSizes.md', '19')}px;
	font-weight: ${themeGet('fontWeights.bold', '700')};
	color: ${themeGet('colors.text.bold', '#0D1136')};
	margin-bottom: 15px;
`;

export const SubHeading = styled.span`
	margin-bottom: 3rem;
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.base', '15')}px;
	font-weight: ${themeGet('fontWeights.regular', '400')};
	color: ${themeGet('colors.text.regular', '#77798c')};
	display: block;
`;
export const FieldWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
`;
