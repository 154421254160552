import React, {useState, useEffect} from 'react';
import {Button} from 'components/button/button';
import {
	QuickViewWrapper,
	ProductDetailsWrapper,
	ProductPreview,
	DiscountPercent,
	ProductInfoWrapper,
	ProductInfo,
	ProductTitlePriceWrapper,
	ProductTitle,
	ProductWeight,
	ProductDescription,
	ButtonText,
	ProductMeta,
	ProductCartWrapper,
	ProductPriceWrapper,
	ProductPrice,
	SalePrice,
	ProductCartBtn,
	MetaSingle,
	MetaItem,
	ExtendedProduct,
} from './quick-view.style';
import {useCart} from 'contexts/cart/use-cart';
import {useHistory} from 'react-router-dom';
import CarouselWithCustomDots from 'components/multi-carousel/multi-carousel';
import ReadMore from 'components/truncate/truncate';
import {CURRENCY} from 'utils/constant';
import {CartIcon} from 'assets/icons/CartIcon';
import {Counter} from 'components/counter/counter';
import Select from '../../components/select/select';
import Basket from '../../models/Basket';

const QuickViewMobile = ({modalProps, hideModal, deviceType}) => {
	const router = useHistory();
	const {addItem, removeItem, isInCart, getItem} = useCart();
	let {
		id,
		type,
		title,
		unit,
		price,
		discountInPercent,
		salePrice,
		description,
		gallery,
		image,
		serviceType,
		// categories,
		meta,
	} = modalProps;
	gallery = gallery ?? [
		{url: image ? image : '/assets/images/default/default_quote.jpg'},
	];
	const handleAddClick = e => {
		e.stopPropagation();
		modalProps.m2 = m2Option;
		addItem(new Basket(modalProps));
		hideModal();
	};
	const handleRemoveClick = e => {
		e.stopPropagation();
		removeItem(modalProps);
	};
	const onCategoryClick = slug => {
		router
			.push({
				pathname: `/${type.toLowerCase()}`,
				query: {category: slug},
			})
			.then(() => window.scrollTo(0, 0));
		hideModal();
	};

	const m2Options = [];
	for (let i = 1; i <= 100; i++) {
		m2Options.push({
			label: i + ' m2',
			value: i,
		});
		m2Options.push({
			label: i + '.5 m2',
			value: i * 0.5,
		});
	}
	const [m2Option, setm2Option] = useState(null);
	const [selectMenuPlacement, setSelectMenuPlacement] = useState('bottom');
	useEffect(() => {
		setSelectMenuPlacement(deviceType.desktop ? 'bottom' : 'top');
	}, [deviceType.desktop]);
	return (
		<>
			{/*<ModalClose onClick={hideModal}>*/}
			{/*    <CloseIcon />*/}
			{/*</ModalClose>*/}
			<QuickViewWrapper className="quick-view-mobile-wrapper">
				<ProductDetailsWrapper className="product-card" dir="ltr">
					{
						<ProductPreview>
							<CarouselWithCustomDots items={gallery} deviceType={deviceType} />
							{!!discountInPercent && (
								<DiscountPercent>{discountInPercent}%</DiscountPercent>
							)}
						</ProductPreview>
					}
					<ProductInfoWrapper dir="ltr">
						<ProductInfo>
							<ProductTitlePriceWrapper>
								<ProductTitle>{title}</ProductTitle>
							</ProductTitlePriceWrapper>
							<ProductWeight> {unit} </ProductWeight>
							<ProductDescription>
								<ReadMore character={600}>{description}</ReadMore>
							</ProductDescription>

							{serviceType === 'size' ? (
								<ExtendedProduct>
									<Select
										styles={{
											menuPortal: provided => ({
												...provided,
												zIndex: 999999,
											}),
										}}
										menuPortalTarget={deviceType.desktop ? document.body : null}
										menuPlacement={selectMenuPlacement}
										placeholder="Lütfen m2 seçimi yapınız"
										labelText="Halı boyunu ve enini çarparak m2 girişini yap"
										options={m2Options}
										value={m2Option}
										onChange={item => setm2Option(item)}
									/>
								</ExtendedProduct>
							) : null}
							<ProductMeta>
								<MetaSingle>
									{meta
										? meta.map(item => (
												<MetaItem
													onClick={() => onCategoryClick(item.slug)}
													key={item.id}
												>
													{item.title}
												</MetaItem>
										  ))
										: ''}
								</MetaSingle>
							</ProductMeta>
							<ProductCartWrapper>
								<ProductPriceWrapper>
									<ProductPrice>
										{CURRENCY}
										{salePrice
											? m2Option
												? parseFloat(salePrice) * parseFloat(m2Option.value)
												: salePrice
											: m2Option
											? parseFloat(price) * parseFloat(m2Option.value)
											: price}
									</ProductPrice>
									{discountInPercent ? (
										<SalePrice>
											{CURRENCY}
											{m2Option
												? parseFloat(m2Option.value) * parseFloat(price)
												: price}
										</SalePrice>
									) : null}
								</ProductPriceWrapper>
								<ProductCartBtn>
									{!isInCart(id) ? (
										<Button
											className="cart-button"
											variant="secondary"
											borderRadius={100}
											onClick={handleAddClick}
										>
											<CartIcon mr={2} />
											<ButtonText>Sepete Ekle</ButtonText>
										</Button>
									) : (
										<Counter
											value={getItem(id).quantity}
											onDecrement={handleRemoveClick}
											onIncrement={handleAddClick}
										/>
									)}
								</ProductCartBtn>
							</ProductCartWrapper>
						</ProductInfo>
					</ProductInfoWrapper>
				</ProductDetailsWrapper>
			</QuickViewWrapper>
		</>
	);
};

export default QuickViewMobile;
