import React from 'react';
import {SEO} from '../../components/seo/seo';
import Footer from '../../layouts/footer/footer';
import {ErrorMessage, Form, Formik} from 'formik';
import {
	ContactFormWrapper,
	ContactFromGrid,
	ContactInformationCard,
	ContactInformationImageContainer,
	ContactInformationWrapper,
	ContactUsSection,
	ContactUsStyledError,
	ContactUsWrapper,
} from '../../features/contact-us/contact-us.style';
import ContactUsImage from 'assets/images/contact-illustration.svg';
import SocialMediaConfig from 'site-settings/site-social-media';
import {Input} from '../../components/forms/input';
import {TextArea} from '../../components/textarea/textarea';
import {Button} from '../../components/button/button';
import * as Yup from 'yup';
import useContactUs from '../../services/use-contactus';
import {toast} from 'react-toastify';

const ContactUsFormValidationSchema = Yup.object().shape({
	name: Yup.string()
		.required('İsim gereklidir.')
		.min(2, 'Lütfen geçerli bir isim giriniz'),
	email: Yup.string()
		.required('Email adresi gereklidir.')
		.email('Lütfen geçerli bir email adresi giriniz.'),
	subject: Yup.string()
		.required('Konu gereklidir.')
		.min(2, 'Konu en az 2 karakter olabilir.'),
	content: Yup.string()
		.required('Açıklama gereklidir.')
		.min(3, 'İçerik en az 3 karakter olabilir.'),
});
export const ContactUsPage = () => {
	const initialValues = {
		name: '',
		email: '',
		subject: '',
		content: '',
	};
	const contactUsService = useContactUs();
	const onSubmit = (values, {setSubmitting, resetForm}) => {
		setSubmitting(true);
		contactUsService
			.contactUs({
				name: values.name,
				email: values.email,
				subject: values.subject,
				content: values.content,
			})
			.then(
				res => {
					if (res.success) {
						toast(res.message, {type: 'info'});
						resetForm();
					} else {
						toast(res.message, {type: 'error'});
					}
				},
				err => {
					if (err.response.data && err.response.data.message) {
						toast(err.response.data.message, {type: 'error'});
					}
				},
			)
			.then(() => setSubmitting(false));
	};
	return (
		<>
			<SEO
				title="İletişim"
				description="YıkamaYeri hakkında daha detaylı bilgiye sahip olabilmek için yıkamayeri.com'u ziyaret edebilirsiniz."
			/>
			<ContactUsSection>
				<ContactUsWrapper>
					<ContactInformationWrapper>
						<ContactInformationImageContainer>
							<img src={ContactUsImage} alt="Yıkama Yeri İletişim" />
						</ContactInformationImageContainer>
						<ContactInformationCard>
							<span>Telefon</span>
							<span>0850 888 1470</span>
						</ContactInformationCard>
						<ContactInformationCard>
							<span>Email</span>
							<span>destek@yikamayeri.com</span>
						</ContactInformationCard>
						<ContactInformationCard>
							<span>Sosyal Medya</span>
							<div className="social-links">
								<a
									href={SocialMediaConfig.facebook}
									target="_blank"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16px"
										height="16px"
										viewBox="0 0 12 12"
									>
										<path
											d="M11.338 0H.662A.663.663 0 000 .663v10.674a.663.663 0 00.662.662H6V7.25H4.566V5.5H6V4.206a2.28 2.28 0 012.459-2.394c.662 0 1.375.05 1.541.072V3.5H8.9c-.753 0-.9.356-.9.881V5.5h1.794L9.56 7.25H8V12h3.338a.663.663 0 00.662-.663V.662A.663.663 0 0011.338 0z"
											fill="currentColor"
										></path>
									</svg>
								</a>
								<a
									href={SocialMediaConfig.instagram}
									target="_blank"
									rel="noreferrer"
								>
									<svg
										data-name="Group 96"
										xmlns="http://www.w3.org/2000/svg"
										width="16px"
										height="16px"
										viewBox="0 0 12 12"
									>
										<path
											data-name="Path 1"
											d="M8.5 1A2.507 2.507 0 0111 3.5v5A2.507 2.507 0 018.5 11h-5A2.507 2.507 0 011 8.5v-5A2.507 2.507 0 013.5 1h5m0-1h-5A3.51 3.51 0 000 3.5v5A3.51 3.51 0 003.5 12h5A3.51 3.51 0 0012 8.5v-5A3.51 3.51 0 008.5 0z"
											fill="currentColor"
										></path>
										<path
											data-name="Path 2"
											d="M9.25 3.5a.75.75 0 11.75-.75.748.748 0 01-.75.75zM6 4a2 2 0 11-2 2 2 2 0 012-2m0-1a3 3 0 103 3 3 3 0 00-3-3z"
											fill="currentColor"
										></path>
									</svg>
								</a>
								<a
									href={SocialMediaConfig.youtube}
									target="_blank"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16px"
										height="16px"
										viewBox="0 0 15.997 12"
									>
										<path
											d="M15.893 2.65A2.429 2.429 0 0013.581.113c-1.731-.081-3.5-.112-5.3-.112h-.563c-1.8 0-3.569.031-5.3.112A2.434 2.434 0 00.106 2.656C.028 3.768-.006 4.881-.003 5.993s.031 2.225.106 3.34a2.437 2.437 0 002.309 2.547c1.822.085 3.688.12 5.584.12s3.759-.031 5.581-.119a2.438 2.438 0 002.312-2.547c.075-1.116.109-2.228.106-3.344s-.027-2.225-.102-3.34zM6.468 9.059v-6.14l4.531 3.069z"
											fill="currentColor"
										></path>
									</svg>
								</a>
							</div>
						</ContactInformationCard>
					</ContactInformationWrapper>
					<ContactFormWrapper>
						<h1>İletişim Formu</h1>
						<Formik
							initialValues={initialValues}
							onSubmit={onSubmit}
							validationSchema={ContactUsFormValidationSchema}
						>
							{({values, handleChange, handleBlur, isSubmitting}) => (
								<Form>
									<ContactFromGrid>
										<div className="field-wrapper">
											<Input
												type="text"
												placeholder="İsminiz"
												height="48px"
												// onChange={ (e) => { setEmail(e.target.value) } }
												backgroundColor="#FFF"
												mb="10px"
												name="name"
												value={values.name}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
											<ErrorMessage
												name="name"
												component={ContactUsStyledError}
											/>
										</div>
										<div className="field-wrapper">
											<Input
												type="email"
												placeholder="Email Adresiniz"
												height="48px"
												// onChange={ (e) => { setEmail(e.target.value) } }
												backgroundColor="#FFF"
												mb="10px"
												name="email"
												value={values.email}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
											<ErrorMessage
												name="email"
												component={ContactUsStyledError}
											/>
										</div>
									</ContactFromGrid>
									<div className="field-wrapper">
										<Input
											type="text"
											placeholder="Konu"
											height="48px"
											// onChange={ (e) => { setEmail(e.target.value) } }
											backgroundColor="#FFF"
											mb="10px"
											name="subject"
											value={values.subject}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<ErrorMessage
											name="subject"
											component={ContactUsStyledError}
										/>
									</div>
									<div className="field-wrapper">
										<TextArea
											placeholder="Açıklama"
											// onChange={ (e) => { setEmail(e.target.value) } }
											backgroundColor="#FFF"
											mb="10px"
											rows={6}
											name="content"
											value={values.content}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<ErrorMessage
											name="content"
											component={ContactUsStyledError}
										/>
									</div>
									<Button
										disabled={isSubmitting}
										loading={isSubmitting}
										type="submit"
									>
										Gönder
									</Button>
								</Form>
							)}
						</Formik>
					</ContactFormWrapper>
				</ContactUsWrapper>
			</ContactUsSection>
			<Footer />
		</>
	);
};
