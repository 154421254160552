import React, {useState, useEffect} from 'react';
import {SEO} from '../../components/seo/seo';
import {Modal} from '@redq/reuse-modal';
import Footer from '../../layouts/footer/footer';
import {useHistory, useLocation} from 'react-router-dom';
import parseQueryString from '../../utils/parseQueryString';
import {toast} from 'react-toastify';
import useAuth from '../../services/use-auth';
import {
	Col12,
	FormGroup,
	Heading,
	HomeContainer,
	HomeRow,
	HomeSection,
	SubHeading,
} from './reset-password.style';
import {Input} from '../../components/forms/input';
import {Label} from '../../components/forms/label';
import {Button} from '../../components/button/button';

export const ResetPasswordPage = () => {
	const router = useHistory();
	const location = useLocation();
	const params = parseQueryString(location.search);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [displayForm, setDisplayForm] = useState(false);
	const authService = useAuth();
	const [form, setForm] = useState({
		password: '',
		password_repeat: '',
	});
	useEffect(() => {
		if (!params.v || !params.s) {
			setError('Link Bulunamadı');
			toast(error, {type: 'error'});
		} else {
			setLoading(true);
			authService
				.validateResetPasswordLink({selector: params.s, validator: params.v})
				.then(
					res => {
						if (!res.success) {
							toast(res.message, {type: 'warning'});
							setError(res.message);
							setDisplayForm(false);
						} else {
							setError(null);
							setDisplayForm(true);
						}
					},
					err => {
						if (err.response.data && err.response.data.message) {
							toast(err.response.data.message, {type: 'error'});
							setError(err.response.data.message);
						} else {
							setError('Bir hata oluştu!');
						}
						setDisplayForm(false);
					},
				)
				.then(() => setLoading(false));
		}
		// eslint-disable-next-line
	}, []);
	const handleChange = e => {
		const {value, name} = e.target;
		setForm({...form, [name]: value});
	};
	const handeSubmit = e => {
		e.preventDefault();
		if ((error && error.length > 0) || displayForm === false) {
			return false;
		}
		if (form.password.length < 6) {
			toast('Lütfen en az 6 karakter uzunluğunda bir şifre belirleyiniz.', {
				type: 'warning',
			});
			return false;
		}
		if (form.password !== form.password_repeat) {
			toast('Girilen şifreler aynı değil. Lütfen tekrar deneyiniz!', {
				type: 'warning',
			});
			return false;
		}
		setLoading(true);
		authService
			.resetPassword({
				selector: params.s,
				validator: params.v,
				password: form.password,
			})
			.then(
				res => {
					if (res.success) {
						toast(res.message, {type: 'info'});
						router.push('/');
					} else {
						toast(res.message, {type: 'warning'});
					}
				},
				err => {
					if (err.response.data && err.response.data.message) {
						toast(err.response.data.message, {type: 'error'});
					}
				},
			)
			.then(() => setLoading(false));
	};
	return (
		<>
			<SEO title="Şifremi Sıfırla" description="Yıkama Yeri Şifremi Sıfırla" />
			<Modal>
				<HomeSection>
					<HomeContainer>
						<HomeRow>
							<Col12>
								<Heading>Şifremi Unuttum</Heading>
								<SubHeading>
									{error
										? error
										: 'Şifre sıfırlama linki doğrulandı. Lütfen şifrenizi güncelleyiniz.'}
								</SubHeading>
								{displayForm ? (
									<form onSubmit={handeSubmit}>
										<FormGroup>
											<Label>Şifrenizi Giriniz</Label>
											<Input
												type="password"
												placeholder="Yeni Şifreniz"
												name="password"
												height="48px"
												value={form.password}
												onChange={handleChange}
												backgroundColor="#F7F7F7"
												mb="10px"
											/>
										</FormGroup>
										<FormGroup>
											<Label>Şifrenizi Tekrar Giriniz</Label>
											<Input
												type="password"
												placeholder="Şifre Tekrarı"
												name="password_repeat"
												height="48px"
												value={form.password_repeat}
												onChange={handleChange}
												backgroundColor="#F7F7F7"
												mb="10px"
											/>
										</FormGroup>
										<FormGroup>
											<Button
												variant="primary"
												size="big"
												type="submit"
												loading={loading}
												disabled={loading}
											>
												{loading ? 'Şifre sıfırlanıyor...' : 'Şifremi Güncelle'}
											</Button>
										</FormGroup>
									</form>
								) : null}
							</Col12>
						</HomeRow>
					</HomeContainer>
				</HomeSection>
				<Footer />
			</Modal>
		</>
	);
};
