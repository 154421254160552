export const HOME_PAGE = '/';
export const CATEGORY_BLOG_PAGE = '/:category';
export const SERVICE_PAGE = '/:neighbourhood';
export const CATEGORY_PROVINCE_BLOG_PAGE = '/:category/:province';
export const PROFILE_PAGE = '/profile';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const CHECKOUT_PAGE = '/checkout';
export const CHECKOUT_PAY_PAGE = '/checkout/pay';
export const CHECKOUT_RESULT_PAGE = '/checkout/result';
export const TERMS_PAGE = '/sozlesme';
export const BLOG_PAGE = '/blog/:page?';
export const BLOG_POST_PAGE = '/post/:slug';
export const ORDER_PAGE = '/order';
export const HELP_PAGE = '/faq';
export const COUPONS_PAGE = '/coupons';
export const LOGIN_PAGE = '/login';
export const REGISTER_PAGE = '/register';
export const RESET_PASSWORD_PAGE = '/reset-password';
export const VERIFY_EMAIL_PAGE = '/verify/email';
export const ABOUT_US_PAGE = '/about';

//todo: implement these pages
export const CONTACT_US_PAGE = '/iletisim';

// Mobile Drawer Menus

export const HOME_MENU_ITEM = {
	id: 'nav.home',
	defaultMessage: 'Anasayfa',
	href: HOME_PAGE,
};

export const HELP_MENU_ITEM = {
	id: 'nav.help',
	defaultMessage: 'Sıkça Sorulan Sorular',
	href: HELP_PAGE,
};
export const COUPONS_MENU_ITEM = {
	id: 'nav.coupons',
	defaultMessage: 'İndirim Kuponları',
	href: COUPONS_PAGE,
};
export const ORDER_MENU_ITEM = {
	id: 'nav.order',
	href: ORDER_PAGE,
	defaultMessage: 'Siparişler',
};
export const PROFILE_MENU_ITEM = {
	id: 'nav.profile',
	defaultMessage: 'Profilim',
	href: PROFILE_PAGE,
};
export const AUTHORIZED_MENU_ITEMS = [
	PROFILE_MENU_ITEM,
	{
		id: 'nav.checkout',
		defaultMessage: 'Sepet',
		href: CHECKOUT_PAGE,
	},
	ORDER_MENU_ITEM,
	{
		id: 'nav.terms_and_services',
		defaultMessage: 'Sözleşmeler',
		href: TERMS_PAGE,
	},
];
// category menu items for header navigation

export const MOBILE_DRAWER_MENU = [
	HOME_MENU_ITEM,
	HELP_MENU_ITEM,
	{
		id: 'nav.blog',
		defaultMessage: 'Blog',
		href: '/blog',
	},
];
export const MOBILE_DRAWER_AUTHORIZED_MENU = [
	HOME_MENU_ITEM,
	...AUTHORIZED_MENU_ITEMS,
	HELP_MENU_ITEM,
];

export const PROFILE_SIDEBAR_TOP_MENU = [ORDER_MENU_ITEM, HELP_MENU_ITEM];
export const PROFILE_SIDEBAR_BOTTOM_MENU = [
	PROFILE_MENU_ITEM,
	{
		id: 'nav.change-password',
		defaultMessage: 'Şifremi Güncelle',
		href: CHANGE_PASSWORD_PAGE,
	},
];
