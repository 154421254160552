import {useState, useEffect} from 'react';
import {
	OfferPageWrapper,
	ProductsRow,
	MainContentArea,
	ProductsCol,
	LoaderContainer,
} from 'assets/styles/pages.style';
import {Modal} from '@redq/reuse-modal';
import {SEO} from '../../components/seo/seo';
import {GiftCard} from '../../components/gift-card/gift-card';
import usePayment from '../../services/use-payment';
import Loader from '../../components/loader/loader';
import Footer from '../../layouts/footer/footer';
export const CouponsPage = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const paymentService = usePayment();
	useEffect(() => {
		paymentService
			.getCouponCodes()
			.then(
				res => {
					if (res.success && res.data && res.data.length > 0) {
						setData(res.data);
					} else {
						setData([]);
					}
				},
				() => {
					setData([]);
				},
			)
			.then(() => setLoading(false));
		// eslint-disable-next-line
	}, []);
	return (
		<Modal>
			<SEO
				title="Kupon Kodlarımız"
				description="Yıkama Yeri Kupon Kodlarımız"
			/>
			<OfferPageWrapper>
				<MainContentArea>
					<div style={{width: '100%'}}>
						{loading ? (
							<LoaderContainer>
								<Loader />
							</LoaderContainer>
						) : (
							<ProductsRow>
								{data.map(coupon => (
									<ProductsCol key={coupon.id}>
										<GiftCard
											image={
												coupon.image
													? coupon.image
													: 'assets/images/default/default_coupon.jpg'
											}
											code={coupon.code}
										/>
									</ProductsCol>
								))}
							</ProductsRow>
						)}
					</div>
				</MainContentArea>
			</OfferPageWrapper>
			<Footer />
		</Modal>
	);
};
