import React from 'react';
import Order from 'features/user-profile/order/order';
import {
	PageWrapper,
	SidebarSection,
} from 'features/user-profile/user-profile.style';
import Sidebar from 'features/user-profile/sidebar/sidebar';
import {Modal} from '@redq/reuse-modal';
import {SEO} from '../../components/seo/seo';
import Footer from '../../layouts/footer/footer';

const OrderPage = () => {
	return (
		<>
			<SEO title="Sipariş Listesi" description="Sipariş Listesi" />
			<Modal>
				<PageWrapper>
					<SidebarSection>
						<Sidebar />
					</SidebarSection>
					<Order />
				</PageWrapper>
				<Footer />
			</Modal>
		</>
	);
};

export default OrderPage;
