import React, {useContext} from 'react';
import styled from 'styled-components';
import {CardMenu} from 'components/card-menu/card-menu';
import {useAppDispatch, useAppState} from 'contexts/app/app.provider';
import {ServiceContext} from '../../contexts/service/service.context';

const Aside = styled.aside({
	width: '300px',
});

const CardMenuWrapper = styled.div({
	display: 'grid',
	gridGap: '10px',
	gridTemplateColumns: '1fr 1fr',
	gridAutoRows: 'max-content',
	paddingBottom: 30,

	'@media (min-width: 550px) and (max-width: 990px)': {
		gridTemplateColumns: '1fr 1fr 1fr',
	},
});

const DesktopOnly = styled.div({
	display: 'none',
	'@media (min-width: 991px)': {
		display: 'block',
	},
});

const SidebarWithCardMenu = _ => {
	const categories = useAppState('categories');
	const appDispatch = useAppDispatch();
	const data = categories.data;
	const {serviceState, serviceDispatch} = useContext(ServiceContext);
	const onCategoryClick = slug => {
		const category = categories.data.find(item => item.slug === slug);
		appDispatch({
			type: 'SET_SEARCH_CATEGORY',
			payload: {
				label: category.title,
				value: category.slug,
			},
		});
		serviceDispatch({
			type: 'SET_CATEGORY',
			payload: category,
		});
	};
	return (
		<React.Fragment>
			<DesktopOnly>
				<Aside>
					<CardMenuWrapper>
						<CardMenu
							data={data ?? []}
							onClick={onCategoryClick}
							active={
								serviceState &&
								serviceState.category &&
								serviceState.category.slug
									? serviceState.category.slug
									: null
							}
						/>
					</CardMenuWrapper>
				</Aside>
			</DesktopOnly>
		</React.Fragment>
	);
};

export default SidebarWithCardMenu;
