import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';
export const ErrorWrapper = styled.div`
	width: 100%;
	height: auto;
	min-height: 60vh;
	display: flex;
	flex-wrap: wrap;
	background-color: ${themeGet('colors.gray', '#f7f7f7')};
	padding: 100px 70px 10px;

	@media only screen and (max-width: 990px) {
		padding: 60px 0 10px;
	}

	@media only screen and (min-width: 991px) and (max-width: 1280px) {
		padding: 90px 15px 10px;
	}
`;
export const ErrorContainer = styled.div`
	width: 100%;
	padding: 20px 15px;
	margin-right: auto;
	margin-left: auto;
	@media (min-width: 420px) {
		padding: 40px 15px;
		max-width: 414px;
	}
	@media (min-width: 576px) {
		max-width: 540px;
	}
	@media (min-width: 768px) {
		max-width: 720px;
	}
	@media (min-width: 992px) {
		max-width: 960px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
`;
export const ErrorRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	align-items: center;
`;
export const ErrorLogoImage = styled.img`
	width: 300px;
	margin-top: 30px;
	border-style: none;
	vertical-align: middle;
	margin-bottom: 25px;
`;
export const ErrorTitle = styled.h2`
	font-size: 2rem;
	line-height: 1.2;
	background: none;
	font-weight: bolder;
	position: relative;
	color: ${themeGet('colors.black.900')};
`;
export const ErrorCol12 = styled.div`
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	flex: 0 0 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
`;
