import React, {useContext, useState} from 'react';
import {SEO} from 'components/seo/seo';
import {Modal} from '@redq/reuse-modal';
import {
	ContentBox,
	PageWrapper,
	SidebarSection,
} from 'features/user-profile/user-profile.style';
import Sidebar from 'features/user-profile/sidebar/sidebar';
import Footer from 'layouts/footer/footer';
import SettingsContent from 'features/user-profile/settings/settings';
import {AuthContext} from '../../contexts/auth/auth.context';
import Alert from '../../components/alert/alert';
import useUser from '../../services/use-user';
import {toast} from 'react-toastify';
const ProfilePage = ({deviceType}) => {
	const {authState} = useContext(AuthContext);
	const [emailAlertBlur, setEmailAlertBlur] = useState(false);
	const [emailVerificationLoading, setEmailVerificationLoading] =
		useState(false);
	const userService = useUser();
	const sendMailVerificationCode = () => {
		setEmailAlertBlur(true);
		setEmailVerificationLoading(true);
		userService
			.sendEmailVerificationMail()
			.then(
				res => {
					if (res.success) {
						toast(res.message, {type: 'info', delay: 10000});
					} else {
						toast(res.message, {type: 'warning'});
					}
				},
				err => {
					if (err.response.data && err.response.data.message) {
						toast(err.response.data.message, {type: 'error'});
					}
				},
			)
			.then(() => setEmailVerificationLoading(false));
	};
	return (
		<>
			<SEO title="Profilim" description="Profilim" />
			<Modal>
				<PageWrapper>
					<SidebarSection>
						<Sidebar />
					</SidebarSection>
					<ContentBox>
						{authState.user?.email_verified.get() === false ? (
							<Alert
								loading={emailVerificationLoading}
								handleClick={sendMailVerificationCode}
								blur={emailAlertBlur}
								type="warning"
							>
								Henüz mail adresiniz doğrulanmamış olarak gözükmektedir. Lütfen
								buraya tıklayarak mail adresinizi doğrulayınız
							</Alert>
						) : null}
						<SettingsContent deviceType={deviceType} />
					</ContentBox>
				</PageWrapper>
				<Footer />
			</Modal>
		</>
	);
};

export default ProfilePage;
