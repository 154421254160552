import React from 'react';
export const CheckMark = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20.894"
			height="16"
			viewBox="0 0 20.894 16"
		>
			<path
				data-name="_ionicons_svg_ios-checkmark (3)"
				d="M169.184,175.473l-1.708-1.756a.367.367,0,0,0-.272-.116.352.352,0,0,0-.272.116l-11.837,11.925-4.308-4.308a.375.375,0,0,0-.543,0l-1.727,1.727a.387.387,0,0,0,0,.553l5.434,5.434a1.718,1.718,0,0,0,1.135.553,1.8,1.8,0,0,0,1.126-.534h.01l12.973-13.041A.415.415,0,0,0,169.184,175.473Z"
				transform="translate(-148.4 -173.6)"
				fill="currentColor"
			/>
		</svg>
	);
};
