import {SEO} from '../../components/seo/seo';
import Footer from '../../layouts/footer/footer';
import {NotFound} from '../../features/error/not-found';

export const NotFoundPage = () => {
	return (
		<>
			<SEO
				title="Sayfa Bulunamadı"
				description="Yıkama Yeri Sayfa Bulunamadı"
			/>
			<NotFound />
			<Footer />
		</>
	);
};
