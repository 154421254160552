// export const cartItemsTotalPrice = (items, { discountInPercent = 0 } = {}) => {
import {toast} from 'react-toastify';

export const cartItemsTotalPrice = (items, coupon = null) => {
	if (items === null || items.length === 0) return 0;
	const itemCost = items.reduce((total, item) => {
		if (item.salePrice) {
			if (item.m2 && item.m2.value && !isNaN(item.m2.value)) {
				return total + item.salePrice * item.quantity * item.m2.value;
			}
			return total + item.salePrice * item.quantity;
		}
		return total + item.price * item.quantity;
	}, 0);
	// const discountRate = 1 - discountInPercent;
	const discount = coupon ? (itemCost * Number(coupon.discount)) / 100 : 0;
	// itemCost * discountRate * TAX_RATE + shipping;
	// return itemCost * discountRate;
	return itemCost - discount;
};
// cartItems, cartItemToAdd
const addItemToCart = (state, action) => {
	const existingCartItemIndex = state.items.findIndex(
		item =>
			item.id === action.payload.id &&
			((!action.payload.m2 && !item.m2) ||
				(action.payload.m2 &&
					item.m2 &&
					action.payload.m2.value === item.m2.value)),
	);
	if (existingCartItemIndex > -1) {
		const newState = [...state.items];
		newState[existingCartItemIndex].quantity += action.payload.quantity;
		return newState;
	}
	toast('Sepete Eklendi', {type: 'info'});
	return [...state.items, action.payload];
};

// cartItems, cartItemToRemove
const removeItemFromCart = (state, action) => {
	return state.items.reduce((acc, item) => {
		if (
			item.id === action.payload.id &&
			((!action.payload.m2 && !item.m2) ||
				(action.payload.m2 &&
					item.m2 &&
					action.payload.m2.value === item.m2.value))
		) {
			const newQuantity = item.quantity - action.payload.quantity;

			return newQuantity > 0
				? [...acc, {...item, quantity: newQuantity}]
				: [...acc];
		}
		return [...acc, item];
	}, []);
};

const clearItemFromCart = (state, action) => {
	toast('Sepetten çıkarıldı', {type: 'info'});
	return state.items.filter(
		item =>
			item.id !== action.payload.id ||
			(item.id === action.payload.id &&
				action.payload.m2?.value !== item.m2?.value),
	);
};

export const reducer = (state, action) => {
	switch (action.type) {
		case 'REHYDRATE':
			return {...state, ...action.payload};
		case 'TOGGLE_CART':
			return {...state, isOpen: !state.isOpen};
		case 'ADD_ITEM':
			return {...state, items: addItemToCart(state, action)};
		case 'REMOVE_ITEM':
			return {...state, items: removeItemFromCart(state, action)};
		case 'CLEAR_ITEM_FROM_CART':
			return {...state, items: clearItemFromCart(state, action)};
		case 'CLEAR_CART':
			return {...state, items: []};
		case 'APPLY_COUPON':
			return {...state, coupon: action.payload};
		case 'REMOVE_COUPON':
			return {...state, coupon: null};
		default:
			throw new Error(`Unknown action: ${action.type}`);
	}
};
