import React from 'react';
import {
	BlogCardContainer,
	BlogCardContent,
	BlogCardDateSection,
	BlogCardDescription,
	BlogCardImage,
	BlogCardImageLink,
	BlogCardImageOverlay,
	BlogCardReadMore,
	BlogCardTitle,
	BlogCol,
	BlogRow,
	BlogWrapper,
	PageTitle,
} from './blog.style';

const Blog = ({posts}) => {
	return (
		<BlogWrapper>
			<BlogRow>
				<BlogCol>
					<PageTitle>Blog</PageTitle>
				</BlogCol>
				<BlogCol>
					{posts
						? posts.map(item => {
								const link = '/post/' + item.slug;
								return (
									<BlogCardContainer key={item.id}>
										<BlogCardImageLink to={link}>
											<BlogCardImage src={item.image} />
											<BlogCardImageOverlay />
										</BlogCardImageLink>
										<BlogCardContent>
											<BlogCardDateSection>
												{/*<BlogCardDateSectionItem>*/}
												{/*    109k views*/}
												{/*</BlogCardDateSectionItem>*/}
												<span>{item.published_at}</span>
											</BlogCardDateSection>
											<BlogCardTitle to={link}>{item.title}</BlogCardTitle>
											<BlogCardDescription>
												{item.desctiption}
											</BlogCardDescription>
											<BlogCardReadMore to={link}>Daha Fazla</BlogCardReadMore>
										</BlogCardContent>
									</BlogCardContainer>
								);
						  })
						: null}
				</BlogCol>
			</BlogRow>
		</BlogWrapper>
	);
};

export default Blog;
