import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';
import {Link} from 'react-router-dom';

export const FooterSection = styled.div`
	background-color: ${themeGet('colors.background.light')};
	position: relative;
	display: block;
	box-sizing: border-box;
	overflow-x: hidden;
	padding: 10px 0;
	@media (min-width: 576px) {
		padding: 30px 0;
	}
	@media (min-width: 768px) {
		padding: 70px 0;
	}
	@media (min-width: 992px) {
		padding: 80px 0;
	}
`;

export const FooterContainer = styled.div`
	width: 100%;
	padding-right: 30px;
	padding-left: 30px;
	margin-right: auto;
	margin-left: auto;
	z-index: 5;
	position: relative;
	@media (min-width: 420px) {
		max-width: 414px;
	}
	@media (min-width: 576px) {
		max-width: 540px;
	}
	@media (min-width: 768px) {
		max-width: 720px;
	}
	@media (min-width: 992px) {
		max-width: 960px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
`;
export const FooterRow = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
export const FooterColLarge = styled.div`
	position: relative;
	width: 100%;
	@media (min-width: 768px) {
		flex: 0 0 75%;
		max-width: 75%;
	}
	@media (min-width: 992px) {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	margin-right: auto;
`;
export const FooterColSmall = styled.div`
	position: relative;
	width: 100%;
	@media (min-width: 576px) {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	@media (min-width: 992px) {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
`;
export const FooterColMedium = styled.div`
	position: relative;
	width: 100%;
	@media (min-width: 576px) {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	@media (min-width: 992px) {
		flex: 0 0 25%;
		max-width: 25%;
	}
`;
export const FooterHeadLogoLink = styled(Link)`
	text-decoration: none;
	outline: 0 none;
	transition: all 0.5s;
	color: ${themeGet('colors.primary.regular')};
	:hover {
		color: ${themeGet('colors.primary.hover')};
	}
	img {
		max-width: 100%;
		height: auto;
		vertical-align: middle;
		border-style: none;
		padding: 0 40px;
		@media (min-width: 576px) {
			padding: 0;
		}
	}
`;
export const FooterLogoDescription = styled.div`
	p {
		font-size: 0.875rem;
		line-height: 1.67;
		margin-top: 1.875rem;
		margin-bottom: 0.3rem;
		color: ${themeGet('colors.text.medium')};
		@media (min-width: 576px) {
			margin-bottom: 1rem;
			margin-top: 0.5rem;
		}
	}
`;

export const FooterSocialMediaList = styled.ul`
	display: flex;
	align-items: center;
	margin: -5px;
	list-style: none;
	color: #2d313d;
	padding: 1.5rem 0;
	li {
		padding: 5px;
	}
	@media (min-width: 576px) {
		padding: 0;
	}
`;
export const FooterSocialMediaLink = styled.a`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	background: ${themeGet('colors.text.medium')};
	color: ${themeGet('colors.white')};
	text-decoration: none;
	:hover {
		color: ${themeGet('colors.primary.regular')};
	}
`;
export const FooterMenuTitle = styled.h6`
	font-size: 0.875rem;
	letter-spacing: 0.075em;
	text-transform: uppercase;
	margin-bottom: 0.875rem;
	color: ${themeGet('colors.text.medium')};
	font-weight: 700;
	line-height: 1.2;
`;

export const FooterMenuList = styled.ul`
	margin: 0;
	padding-bottom: 1rem;
	list-style: none;
	color: ${themeGet('colors.black.900')};

	li {
		:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}
`;
export const FooterMenuLink = styled(Link)`
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: ${themeGet('colors.text.regular')};
	text-decoration: none;
	outline: 0 none;
	transition: all 0.5s;
	:hover {
		color: ${themeGet('colors.primary.regular')};
	}
`;

export const FooterCopyrightSection = styled.div`
	background-color: ${themeGet('colors.white')};
	position: relative;
	display: block;
	box-sizing: border-box;
	overflow-x: hidden;
	padding-bottom: 70px;
	@media (min-width: 768px) {
		padding-bottom: 0;
	}
`;
export const FooterCopyrightRow = styled(FooterRow)`
	align-items: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
`;
export const FooterColMd6 = styled.div`
	position: relative;
	width: 100%;
	@media (min-width: 768px) {
		flex: 0 0 50%;
		max-width: 50%;
	}
`;
export const FooterCopyright = styled.p`
	font-size: 0.875rem;
	color: ${themeGet('colors.text.medium')};
	:last-child {
		margin-bottom: 0;
	}
`;
export const FooterCopyrightLinkContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;
	@media (min-width: 768px) {
		justify-content: flex-end !important;
		margin-top: 0;
	}
`;
