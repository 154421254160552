import React, {useContext} from 'react';
import {AuthContext} from 'contexts/auth/auth.context';

import {
	HamburgerIcon,
	DrawerContentWrapper,
	DrawerClose,
	DrawerProfile,
	LogoutView,
	LoginView,
	UserAvatar,
	UserDetails,
	DrawerMenu,
	DrawerMenuItem,
	UserOptionMenu,
} from './header.style';
import {CloseIcon} from '../../assets/icons/CloseIcon';
import UserImage from 'assets/images/user.svg';
import {useAppState, useAppDispatch} from 'contexts/app/app.provider';
import {Scrollbars} from 'react-custom-scrollbars';
import {useHistory} from 'react-router-dom';
import {openModal} from '@redq/reuse-modal';
import Drawer from 'components/drawer/drawer';
import {Button} from 'components/button/button';
import {
	MOBILE_DRAWER_AUTHORIZED_MENU,
	MOBILE_DRAWER_MENU,
} from 'site-settings/site-navigation';
import NavLink from 'components/nav-link/nav-link';
import AuthenticationForm from 'features/authentication-form';
import {useDataHook} from 'model-react';

const MobileDrawer = () => {
	const isDrawerOpen = useAppState('isDrawerOpen');
	const dispatch = useAppDispatch();

	const {
		authState: {isAuthenticated, user},
		authDispatch,
	} = useContext(AuthContext);

	const toggleHandler = React.useCallback(() => {
		dispatch({
			type: 'TOGGLE_DRAWER',
		});
	}, [dispatch]);
	const router = useHistory();
	const handleLogout = () => {
		if (typeof window !== 'undefined') {
			localStorage.removeItem('access_token');
			authDispatch({type: 'SIGN_OUT'});
			router.push('/');
		}
	};

	const signInOutForm = () => {
		dispatch({
			type: 'TOGGLE_DRAWER',
		});

		authDispatch({
			type: 'SIGNIN',
		});
		openModal({
			show: true,
			overlayClassName: 'quick-view-overlay',
			closeOnClickOutside: true,
			component: AuthenticationForm,
			closeComponent: '',
			config: {
				enableResizing: false,
				disableDragging: true,
				className: 'quick-view-modal',
				width: 458,
				height: 'auto',
			},
		});
	};

	const [h] = useDataHook();
	return (
		<Drawer
			width="316px"
			drawerHandler={
				<HamburgerIcon>
					<span />
					<span />
					<span />
				</HamburgerIcon>
			}
			open={isDrawerOpen}
			toggleHandler={toggleHandler}
			closeButton={
				<DrawerClose>
					<CloseIcon />
				</DrawerClose>
			}
		>
			<Scrollbars autoHide>
				<DrawerContentWrapper>
					<DrawerProfile>
						{isAuthenticated ? (
							<LoginView>
								<UserAvatar>
									<img src={UserImage} alt="user_avatar" />
								</UserAvatar>
								<UserDetails>
									<h3>
										{user && user.firstname
											? user.firstname.get(h) + ' ' + user.lastname.get(h)
											: ''}
									</h3>
									<span>{user && user.email ? user.email.get(h) : ''}</span>
								</UserDetails>
							</LoginView>
						) : (
							<LogoutView>
								<Button variant="primary" onClick={signInOutForm}>
									Giriş Yap
								</Button>
							</LogoutView>
						)}
					</DrawerProfile>
					<DrawerMenu>
						{isAuthenticated
							? MOBILE_DRAWER_AUTHORIZED_MENU.map(item => (
									<DrawerMenuItem key={item.id}>
										<NavLink
											onClick={toggleHandler}
											href={item.href}
											label={item.defaultMessage}
											intlId={item.id}
											className="drawer_menu_item"
										/>
									</DrawerMenuItem>
							  ))
							: MOBILE_DRAWER_MENU.map(item => (
									<DrawerMenuItem key={item.id}>
										<NavLink
											onClick={toggleHandler}
											href={item.href}
											label={item.defaultMessage}
											intlId={item.id}
											className="drawer_menu_item"
										/>
									</DrawerMenuItem>
							  ))}
					</DrawerMenu>
					{isAuthenticated && (
						<UserOptionMenu>
							<DrawerMenuItem>
								<div onClick={handleLogout} className="drawer_menu_item">
									<span className="logoutBtn">Çıkış Yap</span>
								</div>
							</DrawerMenuItem>
						</UserOptionMenu>
					)}
				</DrawerContentWrapper>
			</Scrollbars>
		</Drawer>
	);
};

export default MobileDrawer;
