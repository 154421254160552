import React from 'react';
import postscribe from 'postscribe';
class PaymentForm extends React.Component {
	componentDidMount() {
		if (this.props.htmlContent) {
			postscribe('#iyzipay-script', this.props.htmlContent);
		}
	}

	render() {
		return (
			<div>
				<div id="iyzipay-checkout-form" className="responsive"></div>
				<div id="iyzipay-script"></div>
			</div>
		);
	}
}
export default PaymentForm;
