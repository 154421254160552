import React, { useContext } from "react"
import { useAppDispatch, useAppState } from "contexts/app/app.provider"
import {useHistory} from 'react-router-dom';
import {SearchBox} from 'components/search-box/search-box';
import {toast} from 'react-toastify';
import {handleModal} from '../checkouts/checkout-modal';
import UpdateAddress from '../../components/address-card/address-card';
import useAddress from '../../services/use-address';
import { Address, parseAddresses } from "../../models/Address"
import { AuthContext } from "../../contexts/auth/auth.context"
import { LOGIN_PAGE } from "../../site-settings/site-navigation"
import useUser from "../../services/use-user"

const Search = ({onSubmit, ...props}) => {
	const selectedNeighbourhood = useAppState('searchNeighbourhood');
	const myAddresses = useAppState('userAddresses');
	const userServices = useUser();

	const appDispatch = useAppDispatch();

	const { authState } = useContext(AuthContext)

	const addressModel = useAddress();

	const history = useHistory();

	const loadAddresses = () => {
		if (authState.isAuthenticated === false) return;

		appDispatch({
			type: 'SET_USER_ADDRESSES_LOADING',
			payload: true,
		});
		return userServices
			.getAddresses()
			.then(res => {
				if (res.success) {
					appDispatch({
						type: 'SET_USER_ADDRESSES_DATA',
						payload: parseAddresses(res.data),
					});
				} else {
					appDispatch({
						type: 'SET_USER_ADDRESSES_DATA',
						payload: [],
					});
				}
			})
			.then(() => {
				appDispatch({
					type: 'SET_USER_ADDRESSES_LOADING',
					payload: false,
				});
			});
	}

	const isInMyAddresses = address => {
		if (myAddresses.data) {
			for (let i = 0; i < myAddresses.data.length; i++) {
				if (myAddresses.data[i].neighbourhood.slug === address) return true;
			}
		}
		return false;
	};

	const onSearch = e => {
		e.preventDefault();
		if (!selectedNeighbourhood || !selectedNeighbourhood.value) {
			toast('Lütfen il, ilçe ve mahallenizi seçiniz', {type: 'info'});
			return false;
		}

		if (authState.isAuthenticated === false) {
			history.push(LOGIN_PAGE);
			return false;
		}

		if (isInMyAddresses(selectedNeighbourhood.value) === false) {
			addressModel.getNeighbourhood(selectedNeighbourhood.value).then(res => {
				res.data.neighbourhood_slug = res.data.slug;
				const address = new Address(res.data);
				address.onDone = async () => {
					await loadAddresses();
				}
				handleModal(UpdateAddress, address, 'add-address-modal');
			});
			return false;
		}

		history.push(selectedNeighbourhood.value);
		if (onSubmit) {
			onSubmit();
		}
	};

	return (
		<SearchBox
			onEnter={onSearch}
			name="search"
			placeholder="İl, ilçe ve mahallenizi giriniz"
			buttonText="Ara"
			isClearable={true}
			{...props}
		/>
	);
};

export default Search;
