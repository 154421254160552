import {
	ErrorCol12,
	ErrorContainer,
	ErrorLogoImage,
	ErrorRow,
	ErrorTitle,
	ErrorWrapper,
} from './error.style';
import LogoImage from 'assets/images/logo.png';

export const NotFound = () => {
	return (
		<ErrorWrapper>
			<ErrorContainer>
				<ErrorRow>
					<ErrorCol12>
						<ErrorLogoImage src={LogoImage} alt="Yıkama Yeri" />
					</ErrorCol12>
					<ErrorCol12>
						<ErrorTitle>Aradığınız Sayfa Bulunamadı</ErrorTitle>
					</ErrorCol12>
				</ErrorRow>
			</ErrorContainer>
		</ErrorWrapper>
	);
};
