import React from 'react';
import {
	CartPopupButtonStyled,
	ButtonImgBox,
	ItemCount,
	PriceBox,
	CartPopupBoxButton,
	PriceBoxAlt,
	TotalItems,
} from './cart-popup.style';
import {ShoppingBag} from 'assets/icons/ShoppingBag';

const CartPopupButton = ({
	itemCount,
	itemPostfix = 'items',
	price,
	pricePrefix = '$',
	style,
	onClick,
	className,
}) => (
	<CartPopupButtonStyled style={style} onClick={onClick} className={className}>
		<ButtonImgBox>
			<ShoppingBag />
		</ButtonImgBox>
		<ItemCount>
			{itemCount} {itemPostfix}
		</ItemCount>
		<PriceBox>
			{parseFloat(`${price}`).toFixed(2)} {pricePrefix}
		</PriceBox>
	</CartPopupButtonStyled>
);

export const BoxedCartButton = ({
	itemCount,
	itemPostfix = 'ürün',
	price,
	pricePrefix = '$',
	style,
	onClick,
	className,
}) => (
	<CartPopupBoxButton style={style} onClick={onClick} className={className}>
		<TotalItems>
			<ShoppingBag />
			{itemCount} {itemPostfix}
		</TotalItems>
		<PriceBoxAlt>
			{parseFloat(`${price}`).toFixed(2)}
			{pricePrefix}
		</PriceBoxAlt>
	</CartPopupBoxButton>
);

export default CartPopupButton;
