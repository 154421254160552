import React, {useContext} from 'react';
import {
	LinkButton,
	Button,
	// IconWrapper,
	Wrapper,
	Container,
	Heading,
	SubHeading,
	OfferSection,
	Offer,
	Divider,
} from './authentication-form.style';

// import { Facebook } from 'assets/icons/Facebook';
// import { Google } from 'assets/icons/Google';
import {AuthContext} from 'contexts/auth/auth.context';
import {closeModal} from '@redq/reuse-modal';
import {Input} from 'components/forms/input';
import useAuth from '../../services/use-auth';
import {toast} from 'react-toastify';

export default function LoginModal() {
	const {authDispatch} = useContext(AuthContext);
	const [loading, setLoading] = React.useState(false);
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');

	const toggleSignUpForm = () => {
		authDispatch({
			type: 'SIGNUP',
		});
	};
	const toggleForgotPassForm = () => {
		authDispatch({
			type: 'FORGOTPASS',
		});
	};

	const authModel = useAuth();
	const loginCallback = e => {
		e.preventDefault();
		setLoading(true);
		authModel
			.login(email, password)
			.then(
				res => {
					if (res.data.access_token) {
						localStorage.setItem('access_token', res.data.access_token);
						authDispatch({type: 'SIGNIN_SUCCESS'});
						closeModal();
					} else {
						toast(res.message, {type: 'info'});
					}
				},
				err => {
					if (err.response.data && err.response.data.message) {
						toast(err.response.data.message, {type: 'warning'});
					}
				},
			)
			.then(() => setLoading(false));
	};
	return (
		<Wrapper>
			<Container>
				<Heading>Hoşgeldiniz</Heading>
				<SubHeading>Email ve Şifreniz ile giriş yapınız</SubHeading>
				<form onSubmit={loginCallback}>
					<Input
						type="email"
						placeholder="Email Adresiniz"
						value={email}
						onChange={e => setEmail(e.target.value)}
						required
						height="48px"
						backgroundColor="#F7F7F7"
						mb="10px"
					/>
					<Input
						type="password"
						placeholder="Şifreniz"
						value={password}
						onChange={e => setPassword(e.target.value)}
						required
						height="48px"
						backgroundColor="#F7F7F7"
						mb="10px"
					/>
					<Button
						variant="primary"
						size="big"
						style={{width: '100%'}}
						type="submit"
						disabled={loading}
						loading={loading}
					>
						{loading ? 'Giriş Yapılıyor...' : 'Devam Et'}
					</Button>
				</form>
				<Divider>Veya</Divider>
				{/*<Button*/}
				{/*    type="submit"*/}
				{/*    variant='primary'*/}
				{/*    size='big'*/}
				{/*    disabled={loading}*/}
				{/*    style={{*/}
				{/*        width: '100%',*/}
				{/*        backgroundColor: '#4267b2',*/}
				{/*        marginBottom: 10,*/}
				{/*    }}*/}
				{/*>*/}
				{/*    <IconWrapper>*/}
				{/*        <Facebook />*/}
				{/*    </IconWrapper>*/}
				{/*    Facebook ile Giriş Yap*/}
				{/*</Button>*/}
				{/*<Button*/}
				{/*    variant='primary'*/}
				{/*    size='big'*/}
				{/*    style={{ width: '100%', backgroundColor: '#4285f4' }}*/}
				{/*    disabled={loading}*/}
				{/*>*/}
				{/*    <IconWrapper>*/}
				{/*        <Google />*/}
				{/*    </IconWrapper>*/}
				{/*    Google ile Giriş Yap*/}
				{/*</Button>*/}

				<Offer style={{padding: '20px 0'}}>
					Henüz hesabınız yok mu?{' '}
					<LinkButton onClick={toggleSignUpForm}>Kayıt Ol</LinkButton>
				</Offer>
			</Container>

			<OfferSection>
				<Offer>
					Şifreni mi unuttun?{' '}
					<LinkButton onClick={toggleForgotPassForm}>
						Şifreni sıfırla
					</LinkButton>
				</Offer>
			</OfferSection>
		</Wrapper>
	);
}
