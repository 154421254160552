import React, {useContext, useEffect, useState} from 'react';
import {ProfileContext} from 'contexts/profile/profile.context';
import {useCart} from 'contexts/cart/use-cart';
import {useHistory} from 'react-router-dom';
import CheckoutWrapper, {
	CheckoutContainer,
	CheckoutInformation,
	InformationBox,
} from '../checkout.style';
import CheckoutCartWrapper from '../checkout-card-wrapper';
import usePayment from 'services/use-payment';
import {toast} from 'react-toastify';
import PaymentForm from 'components/payment/PaymentForm';
import Loader from 'components/loader/loader';
import {LoaderContainer} from '../../../assets/styles/pages.style';

const CheckoutPayment = ({deviceType}) => {
	const {state} = useContext(ProfileContext);
	const [loading, setLoading] = useState(true);
	const {schedules, payment_methods} = state;
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(undefined);
	const [selectedSchedule, setSelectedSchedule] = useState(undefined);
	useEffect(() => {
		const item = schedules.find(item => item.type === 'primary');
		setSelectedSchedule(item ?? null);
	}, [schedules]);
	useEffect(() => {
		const item = payment_methods.find(item => item.type === 'primary');
		setSelectedPaymentMethod(item ?? null);
	}, [payment_methods]);
	const {items, cartItemsCount, calculatePrice, coupon} = useCart();

	// eslint-disable-next-line no-unused-vars
	const [isValid, setIsValid] = useState(false);
	const [payment, setPayment] = useState(null);
	const history = useHistory();
	const paymentService = usePayment();
	const fetchPaymentData = () => {
		paymentService
			.checkoutForm(
				items.map(item => ({
					service_id: item.id,
					quantity: item.quantity,
					m2: item.m2 && item.m2.value ? item.m2.value : null,
				})),
				{
					coupon: coupon && coupon.code ? coupon.code : null,
					payment_method: selectedPaymentMethod
						? selectedPaymentMethod.value
						: null,
					delivery: selectedSchedule ? selectedSchedule.value : null,
				},
			)
			.then(
				res => {
					if (res.success && res.data) {
						if (res.data.payment_method === 'CREDIT_CART') {
							setPayment(res.data);
						} else {
							setPayment(null);
							history.push('/checkout/result/' + res.data.payment_id);
						}
					} else {
						setPayment(null);
						toast(res.message, {type: 'warning'});
						history.push('/checkout');
					}
				},
				err => {
					setPayment(null);
					toast(err.response.data.message, {type: 'error'});
					history.push('/checkout');
				},
			)
			.then(() => {
				setLoading(false);
			});
	};
	useEffect(() => {
		if (
			calculatePrice() > 0 &&
			cartItemsCount > 0 &&
			selectedSchedule &&
			selectedPaymentMethod
		) {
			setIsValid(true);
			fetchPaymentData();
		} else {
			if (
				schedules !== undefined &&
				payment_methods !== undefined &&
				selectedPaymentMethod !== undefined &&
				selectedSchedule !== undefined
			) {
				history.push('/checkout');
			}
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, cartItemsCount, selectedSchedule, selectedPaymentMethod]);
	return (
		<>
			<CheckoutWrapper>
				<CheckoutContainer>
					<CheckoutInformation>
						<InformationBox>
							{loading ? (
								<LoaderContainer>
									<Loader />
								</LoaderContainer>
							) : payment ? (
								<PaymentForm htmlContent={payment.html} />
							) : (
								<LoaderContainer>
									<Loader />
								</LoaderContainer>
							)}
						</InformationBox>
					</CheckoutInformation>
					<CheckoutCartWrapper />
				</CheckoutContainer>
			</CheckoutWrapper>
		</>
	);
};

export default CheckoutPayment;
