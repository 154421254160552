import React from 'react';
// import {useRefScroll} from "utils/use-ref-scroll";
import {SEO} from '../../components/seo/seo';
import {Modal} from '@redq/reuse-modal';
import {Banner} from '../../components/banner/banner';
import CartPopUp from '../../features/carts/cart-popup';
import {HomePage} from '../../features/homepage/homepage';
import {MobileBanner} from '../../components/banner/mobile-banner';
import Footer from '../../layouts/footer/footer';
const IndexPage = ({deviceType}) => {
	// const { elRef: targetRef, scroll } = useRefScroll({
	//     percentOfElement: 0,
	//     percentOfContainer: 0,
	//     offsetPX: -110,
	// });

	return (
		<>
			<SEO
				title="Anasayfa"
				description="Türkiye'nin En Kapsamlı ve Online Yıkama Hizmetleri kategorisinde; alanında anlaşmalı en iyi firmalar ile müşterileri buluşturan online hizmet platformudur."
			/>
			<Modal>
				<MobileBanner
					title="Yıkama Hizmetleri"
					description="Türkiye'nin En Kapsamlı ve Online"
					imageUrl="/assets/images/background/2.jpg"
				/>
				<Banner
					title={'Yıkama Hizmetleri'}
					description={"Türkiye'nin En Kapsamlı ve Online"}
					imageUrl="/assets/images/background/2.jpg"
				/>
				<HomePage deviceType={deviceType} />
				<CartPopUp deviceType={deviceType} />
				<Footer />
			</Modal>
		</>
	);
};

export default IndexPage;
