import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';
import {Col} from '../../features/homepage/homepage.style';

export const HomeContainer = styled.div`
	width: 100%;
	padding: 20px 15px;
	margin-right: auto;
	margin-left: auto;
	@media (min-width: 420px) {
		padding: 0 15px;
		max-width: 414px;
	}
	@media (min-width: 576px) {
		max-width: 540px;
	}
	@media (min-width: 768px) {
		max-width: 720px;
	}
	@media (min-width: 992px) {
		max-width: 960px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
`;
export const HomeSection = styled.section`
	background-color: #fff;
	overflow-x: hidden;
	@media (max-width: 992px) {
		margin-bottom: 30px;
	}
	@media (min-width: 768px) {
		padding: 90px 0;
	}
	@media (min-width: 992px) {
		padding: 120px 0;
	}
`;
export const HomeRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;
export const Col12 = styled(Col)`
	flex: 0 0 100%;
	max-width: 100%;
	padding: 10px;
`;
export const Heading = styled.h3`
	margin-bottom: 10px;
	font-family: ${themeGet('fonts.heading', 'sans-serif')};
	font-size: ${themeGet('fontSizes.lg', '21')}px;
	font-weight: ${themeGet('fontWeights.bold', '700')};
	color: ${themeGet('colors.primary.regular', '#009e7f')};
`;

export const SubHeading = styled.span`
	margin-bottom: 3rem;
	font-family: ${themeGet('fonts.body', 'Lato')};
	font-size: ${themeGet('fontSizes.base', '15')}px;
	font-weight: ${themeGet('fontWeights.regular', '400')};
	color: ${themeGet('colors.text.regular', '#77798c')};
	display: block;
`;
export const FormGroup = styled.div`
	margin-bottom: 2rem;
`;
