import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

const FieldWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
`;

const Heading = styled.div`
	font-family: ${themeGet('fontFamily.heading', 'sans-serif')};
	font-size: ${themeGet('fontSizes.md', '19')}px;
	font-weight: ${themeGet('fontWeights.bold', '700')};
	color: ${themeGet('colors.text.bold', '#0D1136')};
	margin-bottom: 15px;
`;

const StyledInput = styled.input`
	width: 100%;
	height: 54px;
	border-radius: ${themeGet('radii.base', '6px')};
	font-family: ${themeGet('fonts.body', 'Lato, sans-serif')};
	border: 1px solid ${themeGet('colors.gray.700', '#e6e6e6')};
	color: ${themeGet('colors.text.bold', '#0D1136')};
	font-size: 16px;
	line-height: 19px;
	font-weight: ${themeGet('fontWeights.regular', '400')};
	padding: 0 18px;
	box-sizing: border-box;
	transition: border-color 0.25s ease;

	&:hover,
	&:focus {
		outline: 0;
	}

	&:focus {
		border-color: ${themeGet('colors.primary.regular', '#009e7f')};
	}

	&::placeholder {
		color: ${themeGet('colors.text.regular', '#77798C')};
	}
`;

const StyledError = styled.div`
	color: red;
	padding-bottom: 10px;
	margin-top: -5px;
`;

export {FieldWrapper, Heading, StyledInput, StyledError};
