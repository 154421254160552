import React, {useCallback} from 'react';
import {
	Box,
	Image,
	Content,
	Title,
	Description,
	SearchWrapper,
} from './banner.style';

import {Waypoint} from 'react-waypoint';
import {useAppDispatch} from 'contexts/app/app.provider';
import Search from 'features/search/search';

export const Banner = ({style, imageUrl, title, description}) => {
	const dispatch = useAppDispatch();
	const setSticky = useCallback(
		() => dispatch({type: 'SET_STICKY'}),
		[dispatch],
	);
	const removeSticky = useCallback(
		() => dispatch({type: 'REMOVE_STICKY'}),
		[dispatch],
	);
	const onWaypointPositionChange = ({currentPosition}) => {
		if (!currentPosition || currentPosition === 'above') {
			setSticky();
		}
	};
	return (
		<Box display={['none', 'none', 'flex']} style={style}>
			<Image backgroundImage={`url(${imageUrl})`} />
			<Content>
				<Description>{description}</Description>
				<Title>{title}</Title>
				<SearchWrapper>
					<Search
						className="yikamayeri-banner-search"
						shadow="0 21px 36px rgba(0,0,0,0.05)"
					/>
				</SearchWrapper>
				<Waypoint
					onEnter={removeSticky}
					onLeave={setSticky}
					onPositionChange={onWaypointPositionChange}
				/>
			</Content>
		</Box>
	);
};
