import React, {useContext, useEffect} from 'react';
import {ProfileContext} from 'contexts/profile/profile.context';
import {CardHeader} from 'components/card-header/card-header';
import {ButtonGroup} from 'components/button-group/button-group';
import RadioGroup from 'components/radio-group/radio-group';
import RadioCard from 'components/radio-card/radio-card';
import {Button} from 'components/button/button';
import {Box} from 'components/box/box';
import {Plus} from 'assets/icons/PlusMinus';
import {handleModal} from '../checkouts/checkout-modal';
import CreateOrUpdateContact from 'components/contact-card/contact-card';
import useUser from 'services/use-user';
import {parsePhoneNumbers} from '../../models/Phone';
import Loader from '../../components/loader/loader';
import {toast} from 'react-toastify';
import {PhoneVerification} from '../phone-verification/phone-verification';
import {conformToMask} from 'react-text-mask';
import {PHONE_NUMBER_MASK} from '../../utils/constant';
import {LoaderContainer} from '../../assets/styles/pages.style';

const Contact = ({
	increment = false,
	flexStart = false,
	icon = false,
	buttonProps = {
		size: 'big',
		variant: 'outlined',
		type: 'button',
		className: 'add-button',
	},
}) => {
	const userService = useUser();
	const {
		state: {
			phone: {loading, data},
		},
		dispatch,
	} = useContext(ProfileContext);

	useEffect(() => {
		dispatch({
			type: 'SET_PHONE_LOADING',
			payload: {
				loading: true,
			},
		});
		userService
			.getPhoneNumbers()
			.then(res => {
				if (res.success) {
					dispatch({
						type: 'SET_PHONE_NUMBERS',
						payload: parsePhoneNumbers(res.data),
					});
				}
			})
			.then(() =>
				dispatch({
					type: 'SET_PHONE_LOADING',
					payload: {
						loading: false,
					},
				}),
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleOnDelete = item => {
		const item_id = item.id;
		dispatch({
			type: 'SET_PHONE_LOADING',
			payload: {
				id: item_id,
				loading: true,
			},
		});
		userService.deletePhone(item_id).then(
			res => {
				if (res.success) {
					dispatch({
						type: 'DELETE_PHONE',
						payload: item_id,
					});
					toast(res.message, {type: 'info'});
				} else {
					toast(res.message, {type: 'error'});
				}
			},
			err => {
				toast(err.response.data.message, {type: 'error'});
			},
		);
	};
	const handleOnSetDefault = item => {
		const item_id = item.id;
		dispatch({
			type: 'SET_PHONE_LOADING',
			payload: {
				id: item_id,
				loading: true,
			},
		});
		userService
			.setPhoneDefault(item_id)
			.then(
				res => {
					if (res.success) {
						dispatch({
							type: 'SET_PRIMARY_PHONE',
							payload: item_id,
						});
						toast(res.message, {type: 'info'});
					} else {
						toast(res.message, {type: 'error'});
					}
				},
				err => {
					toast(err.response.data.message, {type: 'error'});
				},
			)
			.then(() => {
				dispatch({
					type: 'SET_PHONE_LOADING',
					payload: {
						id: item_id,
						loading: false,
					},
				});
			});
	};
	const phoneMask = phone => {
		const mask = conformToMask(phone, PHONE_NUMBER_MASK, {guide: false});
		return mask.conformedValue;
	};
	return (
		<>
			<CardHeader increment={increment}>
				Lütfen Telefon Numarası Seçiniz
			</CardHeader>
			<ButtonGroup>
				{loading ? (
					<LoaderContainer>
						<Loader />
					</LoaderContainer>
				) : (
					<RadioGroup
						items={data ?? []}
						component={item =>
							item.loading ? (
								<LoaderContainer key={'loader-' + item.id}>
									<Loader />
								</LoaderContainer>
							) : (
								<RadioCard
									id={item.id}
									key={item.id}
									title={item.title}
									isDefault={item.default ?? false}
									content={phoneMask(item.phone)}
									checked={item.default}
									onChange={() => handleOnSetDefault(item)}
									name="phone"
									onEdit={() => handleModal(CreateOrUpdateContact, item)}
									onDelete={() => handleOnDelete(item)}
									hasVerified={item.verified === false}
									onVerify={() =>
										handleModal(PhoneVerification, {
											phone: item.phone,
											id: item.id,
										})
									}
								/>
							)
						}
						secondaryComponent={
							<Button
								{...buttonProps}
								onClick={() =>
									handleModal(CreateOrUpdateContact, 'add-contact-modal')
								}
							>
								{icon && (
									<Box mr={2}>
										<Plus width="10px" />
									</Box>
								)}
								Telefon Numarası Ekle
							</Button>
						}
					/>
				)}
			</ButtonGroup>
		</>
	);
};

export default Contact;
