import React from 'react';

export const Accessories = ({
	color = 'currentColor',
	width = '18px',
	height = '18px',
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 18 18"
		>
			<g data-name="Group 15" fill={color}>
				<path
					data-name="Path 53"
					d="M17.356 0H.643A.643.643 0 000 .643v10.925a.643.643 0 00.643.643h16.714a.643.643 0 00.643-.643V.643A.644.644 0 0017.356 0zM2.431 11.27a1.306 1.306 0 111.306-1.306 1.306 1.306 0 01-1.306 1.306zm0-3.857a1.305 1.305 0 11.924-.382 1.306 1.306 0 01-.924.382zm0-3.857a1.306 1.306 0 11.925-.384 1.306 1.306 0 01-.925.384zm3.218 7.714a1.307 1.307 0 11.922-.382 1.306 1.306 0 01-.922.382zm0-3.857a1.307 1.307 0 11.922-.381 1.306 1.306 0 01-.922.381zm0-3.857a1.307 1.307 0 11.92-.385 1.306 1.306 0 01-.92.385zm3.218 7.714a1.09 1.09 0 10-.008 0zm0-3.857a1.091 1.091 0 10-.008 0zm0-3.857a1.091 1.091 0 10-.008 0zm3.218 7.714a1.1 1.1 0 10-.012 0zm-1.306-5.166a.525.525 0 11-.012 0zm1.306-2.551a1.571 1.571 0 10-.012 0zm4.8 7c0 .355-.412.643-.921.643H15.5c-.508 0-.921-.288-.921-.643V1.768c0-.355.412-.643.921-.643h.461c.508 0 .921.288.921.643z"
				/>
				<path
					data-name="Path 54"
					d="M16.348 2.78c-.318-1.052-.434-.893-.476-1.186-.013-.1-.346-.095-.351 0-.017.261-.15.378-.365.888-.236.562-.193 1 0 1.186h-.069v2.52a.274.274 0 00-.085.2v4.2h1.445v-4.2a.273.273 0 00-.085-.2V3.684c.106-.13.143-.393-.014-.904zm-.29 3.134h-.672V3.971h.67z"
				/>
				<path data-name="Path 55" d="M2.43 1.246a1 1 0 101 1 1 1 0 00-1-1z" />
				<path data-name="Path 56" d="M12.073 1.246a1 1 0 101 1 1 1 0 00-1-1z" />
				<path data-name="Path 57" d="M8.859 5.103a1 1 0 101 1 1 1 0 00-1-1z" />
				<path data-name="Path 58" d="M5.645 8.96a1 1 0 101 1 1 1 0 00-1-1z" />
				<path data-name="Path 59" d="M12.073 8.96a1 1 0 101 1 1 1 0 00-1-1z" />
			</g>
		</svg>
	);
};
