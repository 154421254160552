import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';
import {Link} from 'react-router-dom';
export const PageTitle = styled.h1`
	text-align: center;
	color: ${themeGet('colors.black.regular')};
`;
export const BlogWrapper = styled.div`
	width: 100%;
	height: auto;
	min-height: 100vh;
	display: flex;
	flex-wrap: wrap;
	background-color: ${themeGet('colors.gray', '#f7f7f7')};
	padding: 140px 70px 40px;

	@media only screen and (max-width: 990px) {
		padding: 100px 0 60px;
	}

	@media only screen and (min-width: 991px) and (max-width: 1280px) {
		padding: 130px 15px 60px;
	}
`;
export const BlogRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
`;
export const BlogCol = styled.div`
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	flex: 0 0 100%;
	max-width: 100%;
	@media (min-width: 768px) {
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
`;
export const BlogCardContainer = styled.div`
	background-color: ${themeGet('colors.white', '#ffffff')};
	width: 100%;
	float: left;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid ${themeGet('colors.gray.500', '#efefef')};
	box-shadow: none;
	transition: all 0.2s ease-in-out;
	margin-top: 10px;
	margin-bottom: 10px;
`;
export const BlogPostTitle = styled.div`
	float: left;
	width: 100%;
	padding-bottom: 20px;
	h2 {
		font-size: 24px;
		font-weight: 500;
		margin-top: 30px !important;
		color: #333;
		text-align: left;
		line-height: 30px;
	}
	h2:last-child {
		margin-bottom: auto;
	}
	h2:first-child {
		margin-top: auto;
	}
`;
export const BlogCardImageLink = styled(Link)`
	width: 40% !important;
	float: left;
	position: relative;
	@media (max-width: 575.98px) {
		width: 100% !important;
		float: left;
		position: relative;
	}
`;
export const BlogCardImage = styled.img`
	width: 100%;
	border-radius: 5px;
	border-style: none;
	vertical-align: middle;
`;
export const BlogCardImageOverlay = styled.div`
	position: absolute;
	top: 0;
	padding: 10px;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: linear-gradient(#0000, #3333334d 90%);
	:hover {
		color: #fff !important;
		opacity: 1;
	}
`;
export const BlogCardContent = styled.div`
	padding: 0 15px 0 20px;
	float: left;
	position: relative;
	width: 70%;
	@media only screen and (min-width: 540px) {
		width: 60% !important;
	}

	@media (max-width: 575.98px) {
		padding: 10px 5px !important;
		float: left;
		width: 100% !important;
		position: relative;
	}
`;
export const BlogCardDateSection = styled.div`
	margin-bottom: 10px;
	font-size: 12px;
	margin-top: 5px;
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
	color: #686f7a;
	text-align: left;
`;
export const BlogCardDateSectionItem = styled.span`
	:after {
		content: '•';
		margin: 0 4px;
	}
`;
export const BlogCardTitle = styled(Link)`
	display: block;
	font-size: 16px;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
	color: #333;
	text-align: left;
	margin-bottom: 7px;
	line-height: 24px;
	@media (min-width: 992px) and (max-width: 1199.98px) {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	:hover {
		text-decoration: none !important;
	}
`;
export const BlogCardDescription = styled.p`
	font-size: 14px;
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
	line-height: 26px;
	color: #686f7a;
	text-align: left;
	margin-top: 15px;
	display: -webkit-box !important;
	display: -moz-box !important;
	display: box !important;
	-webkit-line-clamp: 3;
	-moz-line-clamp: 3;
	-ms-line-clamp: 3;
	-o-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-ms-box-orient: vertical;
	-o-box-orient: vertical;
	box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const BlogCardReadMore = styled(Link)`
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	color: #333;
	text-align: left;
`;
