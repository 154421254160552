import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const ButtonGroup = styled.div`
	display: flex;
	flex-direction: column;

	.radioGroup {
		flex-grow: 1;
		justify-content: ${props =>
			props.flexStart === true ? 'flex-start' : 'space-between'};

		label {
			margin-top: 0;
			flex: calc(33.333333333% - 10px);
			max-width: calc(33.333333333% - 10px);
			margin-bottom: 15px;

			&:nth-child(3n) {
				margin-right: 0;
			}

			@media (max-width: 700px) {
				flex: calc(50% - 10px);
				max-width: calc(50% - 10px);

				&:nth-child(3n) {
					margin-right: 15px;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}
			}

			@media (max-width: 480px) {
				flex: 100%;
				max-width: 100%;
				margin-right: 0;

				&:nth-child(3n) {
					margin-right: 0;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}

	.add-button {
		flex: calc(33.3333333333% - 10px);
		max-width: calc(33.3333333333% - 10px);
		flex-shrink: 0;
		height: auto;
		min-height: 77px;
		border: 1px dashed ${themeGet('colors.gray.500', '#f1f1f1')};
		margin-bottom: 15px;
		margin-left: 0;
		margin-right: auto;
		&:hover {
			border-color: ${themeGet('colors.primary.regular', '#009e7f')};
		}

		@media (max-width: 700px) {
			flex: calc(50% - 10px);
			max-width: calc(50% - 10px);
		}

		@media (max-width: 480px) {
			flex: 100%;
			max-width: 100%;
		}
	}
	.addButton {
		width: auto;
		height: auto;
		display: flex;
		align-items: center;
		background-color: transparent;
		border: 0;
		outline: 0;
		border-radius: 0;
		padding: 0;
		font-family: ${themeGet('fonts.body', 'Lato')};
		font-size: ${themeGet('fontSizes.sm', '13')}px;
		font-weight: ${themeGet('fontWeights.bold', '700')};
		color: ${themeGet('colors.primary.regular', '#009e7f')};
		position: absolute;
		top: 40px;
		right: 30px;

		@media (max-width: 600px) {
			top: 27px;
			right: 20px;
		}
	}
`;
