export const GlassIcon = props => {
	return (
		<svg width="14.4" height="12" viewBox="0 0 480 480" {...props}>
			<g>
				<g>
					<path
						d="M472,296c-13.255,0-24-10.745-24-24c0-4.418-3.582-8-8-8s-8,3.582-8,8c-0.042,10.134-6.445,19.15-16,22.528V56
							c0-4.418-3.582-8-8-8H72c-4.418,0-8,3.582-8,8v118.528C54.445,171.15,48.042,162.134,48,152c0-4.418-3.582-8-8-8s-8,3.582-8,8
							c0,13.255-10.745,24-24,24c-4.418,0-8,3.582-8,8s3.582,8,8,8c13.255,0,24,10.745,24,24c0,4.418,3.582,8,8,8s8-3.582,8-8
							c0.042-10.134,6.445-19.15,16-22.528V424c0,4.418,3.582,8,8,8h336c4.418,0,8-3.582,8-8V313.472
							c9.555,3.378,15.958,12.394,16,22.528c0,4.418,3.582,8,8,8s8-3.582,8-8c0-13.255,10.745-24,24-24c4.418,0,8-3.582,8-8
							S476.418,296,472,296z M40,192c-2.276-3.031-4.969-5.724-8-8c3.031-2.276,5.724-4.969,8-8c2.276,3.031,4.969,5.724,8,8
							C44.969,186.276,42.276,188.969,40,192z M400,368v48H235.312l58.344-58.344c3.07-3.178,2.982-8.242-0.196-11.312
							c-3.1-2.995-8.016-2.995-11.116,0L212.688,416h-25.376l106.344-106.344c3.07-3.178,2.982-8.242-0.196-11.312
							c-3.1-2.995-8.016-2.995-11.116,0L164.688,416H80V64h236.688l-58.344,58.344c-3.178,3.07-3.266,8.134-0.196,11.312
							c3.07,3.178,8.134,3.266,11.312,0.196c0.067-0.064,0.132-0.13,0.196-0.196L339.312,64h25.376L258.344,170.344
							c-3.178,3.07-3.266,8.134-0.196,11.312c3.07,3.178,8.134,3.266,11.312,0.196c0.067-0.064,0.132-0.13,0.196-0.196L387.312,64H400
							V368z M440,312c-2.276-3.031-4.969-5.724-8-8c3.031-2.276,5.724-4.969,8-8c2.276,3.031,4.969,5.724,8,8
							C444.969,306.276,442.276,308.969,440,312z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M392,360c-13.255,0-24-10.745-24-24c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,13.255-10.745,24-24,24c-4.418,0-8,3.582-8,8
							s3.582,8,8,8c13.255,0,24,10.745,24,24c0,4.418,3.582,8,8,8s8-3.582,8-8c0-13.255,10.745-24,24-24c4.418,0,8-3.582,8-8
							S396.418,360,392,360z M360,376c-2.276-3.031-4.969-5.724-8-8c3.031-2.276,5.724-4.969,8-8c2.276,3.031,4.969,5.724,8,8
							C364.969,370.276,362.276,372.969,360,376z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M152,112c-13.255,0-24-10.745-24-24c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,13.255-10.745,24-24,24c-4.418,0-8,3.582-8,8
							s3.582,8,8,8c13.255,0,24,10.745,24,24c0,4.418,3.582,8,8,8s8-3.582,8-8c0-13.255,10.745-24,24-24c4.418,0,8-3.582,8-8
							S156.418,112,152,112z M120,128c-2.276-3.031-4.969-5.724-8-8c3.031-2.276,5.724-4.969,8-8c2.276,3.031,4.969,5.724,8,8
							C124.969,122.276,122.276,124.969,120,128z"
					/>
				</g>
			</g>
		</svg>
	);
};
