import React, {useContext, useState} from 'react';
import {Modal} from '@redq/reuse-modal';
import {SEO} from 'components/seo/seo';
import css from '@styled-system/css';
import styled from 'styled-components';
import {useHistory, useParams} from 'react-router-dom';
import {useRefScroll} from 'utils/use-ref-scroll';
import {ModalProvider} from 'contexts/modal/modal.provider';
import SidebarWithCardMenu from 'layouts/sidebar/sidebar-with-card-menu';
import {ProductGrid} from 'components/product-grid/product-grid-three';
import CartPopUp from 'features/carts/cart-popup';
import {ServiceProvider} from 'contexts/service/service.provider';
import {ServiceContext} from 'contexts/service/service.context';
import {useAppState} from 'contexts/app/app.provider';
import Footer from 'layouts/footer/footer';
import {Button} from '../../components/button/button';
import SpringModal from '../../components/spring-modal/spring-modal';
import CategoryIconNav from '../../components/type-nav/type-nav';
import {HOME_PAGE} from '../../site-settings/site-navigation';
import useAddress from '../../services/use-address';
import Loader from '../../components/loader/loader';

const LoadingWrapper = styled.div`
	width: 100%;
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const AddressDetailText = styled.div`
	width: 100%;
	text-align: left;
	margin-top: 5px;
	margin-bottom: 5px;
	font-weight: bold;
	font-size: 1rem;
	@media (max-width: 768px) {
		text-align: center;
	}
`;
const CategoryButtonContainer = styled.div`
	padding-bottom: 1rem;
	display: none;
	@media (max-width: 768px) {
		display: block;
	}
`;

const ServiceContent = ({deviceType}) => {
	const [pageLoading, setPageLoading] = useState(true);
	const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
	const [addressText, setAddressText] = useState('');

	const params = useParams();
	const router = useHistory();
	const addressService = useAddress();

	const {serviceState, serviceDispatch} = useContext(ServiceContext);
	const onCategoryChanged = category => {
		serviceDispatch({
			type: 'SET_CATEGORY',
			payload: category ?? null,
		});
	};

	const categories = useAppState('categories');
	// eslint-disable-next-line no-unused-vars
	const {elRef: targetRef, scroll} = useRefScroll({
		percentOfElement: 0,
		percentOfContainer: 0,
		offsetPX: -110,
	});
	React.useEffect(() => {
		if (params.neighbourhood) {
			let category = params.category
				? categories.data.find(item => item.slug === params.category)
				: categories.data[0];
			serviceDispatch({
				type: 'SET_CATEGORY',
				payload: category ?? null,
			});
			setPageLoading(true);
			addressService.getNeighbourhood(params.neighbourhood).then(
				res => {
					if (res.data) {
						setAddressText(
							res.data.province +
								' ' +
								res.data.district +
								' ' +
								res.data.neighbourhood,
						);
						serviceDispatch({
							type: 'SET_NEIGHBOURHOOD',
							payload: params.neighbourhood ?? null,
						});
						setPageLoading(false);
					} else {
						router.push(HOME_PAGE);
						setPageLoading(false);
					}
				},
				() => {
					router.push(HOME_PAGE);
					setPageLoading(false);
				},
			);
			scroll();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.neighbourhood, params.category, categories.data]);
	return (
		<>
			<SEO
				title={
					serviceState && serviceState.category
						? serviceState.category.title
						: ''
				}
				description={
					serviceState && serviceState.category
						? serviceState.category.description
						: ''
				}
			/>
			{pageLoading ? (
				<LoadingWrapper>
					<Loader size="72px" />
				</LoadingWrapper>
			) : (
				<ModalProvider>
					<Modal>
						<ContentArea>
							<SidebarWithCardMenu />
							<main>
								<AddressDetailText>{addressText}</AddressDetailText>
								<CategoryButtonContainer>
									<Button
										variant="outlined"
										width={{width: '100%'}}
										onClick={() => setCategoryModalOpen(true)}
										style={{textTransform: 'capitalize', color: '#0D1136'}}
									>
										{serviceState.category
											? serviceState.category.title
											: 'Lütfen kategori seçiniz'}
									</Button>
								</CategoryButtonContainer>
								<ProductGrid deviceType={deviceType} />
							</main>
						</ContentArea>
						<CartPopUp deviceType={deviceType} />
						<SpringModal
							isOpen={isCategoryModalOpen}
							onRequestClose={() => setCategoryModalOpen(false)}
						>
							<CategoryIconNav
								onSelected={onCategoryChanged}
								closeRequest={() => setCategoryModalOpen(false)}
							/>
						</SpringModal>
					</Modal>
				</ModalProvider>
			)}
		</>
	);
};

const ServicePage = ({deviceType}) => {
	return (
		<ServiceProvider>
			<ServiceContent deviceType={deviceType} />
			<Footer />
		</ServiceProvider>
	);
};

const ContentArea = styled.div(
	css({
		overflow: 'hidden',
		padding: ['68px 0 100px', '68px 0 50px', '110px 2rem 50px'],
		display: 'grid',
		minHeight: '100vh',
		gridColumnGap: '30px',
		gridRowGap: ['15px', '20px', '0'],
		gridTemplateColumns: [
			'minmax(0, 1fr)',
			'minmax(0, 1fr)',
			'300px minmax(0, 1fr)',
		],
		backgroundColor: '#f9f9f9',
	}),
);

export default ServicePage;
