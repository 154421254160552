import React, {useState} from 'react';
import {
	Content,
	MinimalHeader,
	MobileBannerBox,
	SearchWrapper,
} from './banner.style';
import Search from 'features/search/search';
import SpringModal from '../spring-modal/spring-modal';
import CategoryIconNav from '../type-nav/type-nav';

export const MobileBanner = ({title, description, imageUrl}) => {
	const [isOpen, setOpen] = useState(false);

	return (
		<MobileBannerBox
			display={['flex', 'flex', 'none']}
			style={{backgroundImage: `url(${imageUrl})`}}
		>
			<Content>
				<MinimalHeader>
					<p>{description}</p>
					<h1>{title}</h1>
				</MinimalHeader>
				<SearchWrapper>
					<Search minimal={true} />
				</SearchWrapper>
			</Content>
			<SpringModal isOpen={isOpen} onRequestClose={() => setOpen(false)}>
				<CategoryIconNav closeRequest={() => setOpen(false)} />
			</SpringModal>
		</MobileBannerBox>
	);
};
