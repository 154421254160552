import React, {useContext} from 'react';
import RadioGroup from 'components/radio-group/radio-group';
import RadioCard from 'components/radio-card/radio-card';

import {ProfileContext} from 'contexts/profile/profile.context';
import {CardHeader} from 'components/card-header/card-header';

const Schedules = ({increment = false}) => {
	const {
		state: {schedules},
		dispatch,
	} = useContext(ProfileContext);

	return (
		<>
			<CardHeader increment={increment}>
				Lütfen teslimat zamanını seçiniz
			</CardHeader>
			<RadioGroup
				items={schedules}
				component={item => (
					<RadioCard
						id={item.id}
						key={item.id}
						title={item.title}
						content={item.time_slot}
						name="schedule"
						checked={item.type === 'primary'}
						withActionButtons={false}
						onChange={() =>
							dispatch({
								type: 'SET_PRIMARY_SCHEDULE',
								payload: parseInt(item.id),
							})
						}
					/>
				)}
			/>
		</>
	);
};

export default Schedules;
