import React from 'react';

import {LeftMenuBox} from './left-menu.style';
import {useHistory} from 'react-router-dom';
import Logo from '../../../logo/logo';

const LeftMenu = ({logo}) => {
	const router = useHistory();
	return (
		<LeftMenuBox>
			<Logo
				imageUrl={logo}
				alt={'Yıkama Yeri Logo'}
				onClick={() => router.push('/')}
			/>
		</LeftMenuBox>
	);
};

export default LeftMenu;
