import api from 'utils/axios';

export default function useAddress() {
	const searchAddress = term => {
		return api
			.post('address/search', {
				term,
			})
			.then(res => {
				return res.data;
			});
	};

	const provinces = () => {
		return api.get('province').then(res => {
			return res.data;
		});
	};
	const districts = province_id => {
		return api.get('province/' + province_id + '/districts').then(res => {
			return res.data;
		});
	};
	const neighbourhoods = district_id => {
		return api.get('district/' + district_id + '/neighbourhoods').then(res => {
			return res.data;
		});
	};

	const getNeighbourhood = slug => {
		return api.get('neighbourhood/' + slug).then(res => res.data);
	};

	return {
		searchAddress,
		provinces,
		districts,
		neighbourhoods,
		getNeighbourhood,
	};
}
