import React, {useState, useEffect} from 'react';
import {Scrollbar} from 'components/scrollbar/scrollbar';
import {
	DesktopView,
	MobileView,
	OrderBox,
	OrderListWrapper,
	OrderList,
	OrderDetailsWrapper,
	Title,
	ImageWrapper,
	ItemWrapper,
	ItemDetails,
	ItemName,
	ItemSize,
	ItemPrice,
	NoOrderFound,
} from './order.style';

import OrderDetails from './order-details/order-details';
import OrderCard from './order-card/order-card';
import OrderCardMobile from './order-card/order-card-mobile';
import useComponentSize from 'utils/useComponentSize';
import usePayment from 'services/use-payment';
import {toast} from 'react-toastify';
import Loader from '../../../components/loader/loader';
import {LoaderContainer} from '../../../assets/styles/pages.style';
const progressData = [
	'Sipariş Alındı',
	'Yıkama Yapılıyor',
	'Sipariş Tamamlandı',
];
const orderTableColumns = [
	{
		title: 'Siparişler',
		dataIndex: '',
		key: 'items',
		width: 250,
		ellipsis: true,
		render: (text, record) => {
			return (
				<ItemWrapper>
					<ImageWrapper>
						<img
							src={record.image ?? '/assets/images/default/default_quote.jpg'}
							alt={record.category + ' ' + record.service}
						/>
					</ImageWrapper>

					<ItemDetails>
						<ItemName>{record.service}</ItemName>
						<ItemSize>{record.m2 ? record.m2 + 'm2' : 1}</ItemSize>
						<ItemPrice>${record.paid_price}</ItemPrice>
					</ItemDetails>
				</ItemWrapper>
			);
		},
	},
	{
		title: 'Adet',
		dataIndex: 'quantity',
		key: 'quantity',
		align: 'center',
		width: 100,
	},
	{
		title: 'Fiyat',
		dataIndex: '',
		key: 'paid_price',
		align: 'right',
		width: 100,
		render: (text, record) => {
			return <p>${record.paid_price}</p>;
		},
	},
];

const OrdersContent = () => {
	const [targetRef, size] = useComponentSize();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [selection, setSelection] = useState(null);
	const paymentService = usePayment();
	useEffect(() => {
		paymentService
			.getOrders()
			.then(
				res => {
					if (res.success) {
						setData(res.data ?? []);
					} else {
						toast(res.message, {type: 'warning'});
						setData([]);
					}
				},
				err => {
					toast(err.response.data.message, {type: 'warning'});
					setData([]);
				},
			)
			.then(() => setLoading(false));
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (data?.length) {
			setSelection(data[0]);
		}
	}, [data, data?.length]);

	// if (error) return <ErrorMessage message={error.message} />;

	return (
		<OrderBox>
			{loading ? (
				<LoaderContainer>
					<Loader size="30px" />
				</LoaderContainer>
			) : (
				<>
					<DesktopView
						style={{
							height: size.height,
							width: data.length > 0 ? null : '100%',
						}}
					>
						<OrderListWrapper
							style={{
								height: size.height,
								width: data.length > 0 ? null : '100%',
							}}
						>
							<Title style={{padding: '0 20px'}}>Siparişlerim</Title>

							<Scrollbar className="order-scrollbar">
								<OrderList>
									{data.length > 0 ? (
										data.map(current => (
											<OrderCard
												key={current.id}
												orderId={current.id}
												className={current.id === selection?.id ? 'active' : ''}
												status={progressData[current.order_status - 1]}
												date={current.date}
												deliveryTime={current.delivery}
												amount={current.paid}
												onClick={() => setSelection(current)}
											/>
										))
									) : (
										<NoOrderFound>Sipariş Bulunamadı</NoOrderFound>
									)}
								</OrderList>
							</Scrollbar>
						</OrderListWrapper>

						<OrderDetailsWrapper
							ref={targetRef}
							style={{border: data.length > 0 ? '' : 'none'}}
						>
							{data.length > 0 ? (
								<>
									<Title style={{padding: '0 20px'}}>Sipariş Detayı</Title>
									{selection && (
										<OrderDetails
											progressStatus={parseInt(selection.order_status)}
											progressData={progressData}
											address={
												selection?.address?.province +
												' ' +
												selection?.address?.district +
												' ' +
												selection?.address?.neighbourhood +
												' ' +
												selection?.address?.address
											}
											subtotal={selection.total}
											discount={
												parseFloat(selection.paid) -
												parseFloat(selection.total).toFixed(2)
											}
											grandTotal={selection.total}
											tableData={selection.items}
											columns={orderTableColumns}
										/>
									)}
								</>
							) : null}
						</OrderDetailsWrapper>
					</DesktopView>
					<MobileView>
						<OrderList>
							{data.length > 0 ? (
								<OrderCardMobile
									orders={data}
									// className={order && order.id === active ? 'active' : ''}
									progressData={progressData}
									columns={orderTableColumns}
									onClick={setSelection}
								/>
							) : (
								<>
									<Title style={{textAlign: 'center'}}>Siparişlerim</Title>
									<NoOrderFound>Siparişiniz bulunamadı</NoOrderFound>
								</>
							)}
						</OrderList>
					</MobileView>
				</>
			)}
		</OrderBox>
	);
};

export default OrdersContent;
