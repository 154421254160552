import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {Input} from 'components/forms/input';
import {
	Button,
	// IconWrapper,
	Wrapper,
	Container,
	Heading,
	SubHeading,
	HelperText,
	Offer,
	Divider,
	LinkButton,
} from './authentication-form.style';

// import { Facebook } from 'assets/icons/Facebook';
// import { Google } from 'assets/icons/Google';
import {AuthContext} from 'contexts/auth/auth.context';
import {TERMS_PAGE} from '../../site-settings/site-navigation';
import useAuth from '../../services/use-auth';
import {toast} from 'react-toastify';
import {closeModal} from '@redq/reuse-modal';

export default function RegisterModal() {
	const {authDispatch} = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		firstname: '',
		lastname: '',
		email: '',
		password: '',
	});
	const authService = useAuth();
	const handleChange = e => {
		const {value, name} = e.target;
		setForm({...form, [name]: value});
	};
	const registerCallback = e => {
		e.preventDefault();
		setLoading(true);
		authService
			.register(form)
			.then(
				res => {
					if (res.success) {
						if (res.data.access_token) {
							localStorage.setItem('access_token', res.data.access_token);
							authDispatch({type: 'SIGNIN_SUCCESS'});
						}
						toast(res.message, {type: 'info'});
						closeModal();
					} else {
						toast(res.message, {type: 'warning'});
					}
				},
				err => {
					if (err.response.data && err.response.data.message) {
						toast(err.response.data.message, {type: 'error'});
					}
				},
			)
			.then(() => setLoading(false));
	};
	const toggleSignInForm = () => {
		authDispatch({
			type: 'SIGNIN',
		});
	};

	return (
		<Wrapper>
			<Container>
				<Heading>Kayıt Ol</Heading>
				<SubHeading>Lütfen tüm alanları doldurunuz</SubHeading>
				<form onSubmit={registerCallback}>
					<Input
						type="text"
						placeholder="Adınız"
						name="firstname"
						height="48px"
						value={form.firstname}
						onChange={handleChange}
						backgroundColor="#F7F7F7"
						mb="10px"
					/>
					<Input
						type="text"
						placeholder="Soyadınız"
						name="lastname"
						value={form.lastname}
						onChange={handleChange}
						height="48px"
						backgroundColor="#F7F7F7"
						mb="10px"
					/>
					<Input
						type="email"
						placeholder="Email adresiniz"
						name="email"
						onChange={handleChange}
						value={form.email}
						height="48px"
						backgroundColor="#F7F7F7"
						mb="10px"
					/>
					<Input
						type="password"
						placeholder="Şifreniz"
						value={form.password}
						onChange={handleChange}
						name="password"
						height="48px"
						backgroundColor="#F7F7F7"
						mb="10px"
					/>
					<HelperText style={{padding: '20px 0 30px'}}>
						Kayıt olarak yıkamayeri.com'un tüm{' '}
						<Link to={TERMS_PAGE}>şartlarını</Link> kabul etmiş olursunuz.
					</HelperText>
					<Button
						variant="primary"
						size="big"
						width="100%"
						type="submit"
						loading={loading}
						disabled={loading}
					>
						{loading ? 'Kayıt olunuyor...' : 'Devam Et'}
					</Button>
				</form>
				<Divider>Veya</Divider>
				{/*<Button*/}
				{/*    variant='primary'*/}
				{/*    size='big'*/}
				{/*    style={{*/}
				{/*        width: '100%',*/}
				{/*        backgroundColor: '#4267b2',*/}
				{/*        marginBottom: 10,*/}
				{/*    }}*/}
				{/*>*/}
				{/*    <IconWrapper>*/}
				{/*        <Facebook />*/}
				{/*    </IconWrapper>*/}
				{/*    Facebook ile Kayıt Ol*/}
				{/*</Button>*/}
				{/*<Button*/}
				{/*    variant='primary'*/}
				{/*    size='big'*/}
				{/*    style={{ width: '100%', backgroundColor: '#4285f4' }}*/}
				{/*>*/}
				{/*    <IconWrapper>*/}
				{/*        <Google />*/}
				{/*    </IconWrapper>*/}
				{/*    Google ile Kayıt Ol*/}
				{/*</Button>*/}
				<Offer style={{padding: '20px 0'}}>
					Çoktan Hesabım var.
					<LinkButton onClick={toggleSignInForm}>Giriş Yap</LinkButton>
				</Offer>
			</Container>
		</Wrapper>
	);
}
