import {useReducer} from 'react';
import {BlogContext} from './blog.context';

const INITIAL_STATE = {};

function reducer(state, action) {
	switch (action.type) {
		case 'SET_INITIAL':
			return {
				...state,
				initial: action.payload.initial,
			};
		default:
			return state;
	}
}

export const BlogProvider = ({children}) => {
	const [blogState, blogDispatch] = useReducer(reducer, INITIAL_STATE);
	return (
		<BlogContext.Provider value={{blogState, blogDispatch}}>
			{children}
		</BlogContext.Provider>
	);
};
