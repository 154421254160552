import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';
import Loader from '../loader/loader';

const AlertContainer = styled.div`
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid ${themeGet('colors.transparent')};
	border-radius: 0.25rem;
`;
const types = {
	primary: {
		color: '#004085',
		backgroundColor: '#cce5ff',
		borderColor: '#cce5ff',
	},
	warning: {
		color: '#856404',
		backgroundColor: '#fff3cd',
		borderColor: '#ffeeba',
	},
};
const BlurredArea = styled.div`
	opacity: 0.5;
`;
const LoadingContainer = styled.div`
	display: flex;
	width: 100%;
	position: absolute;
	top: 0;
	height: 100%;
	justify-content: center;
	align-items: center;
`;
const Alert = ({
	type,
	children,
	classList,
	styleList,
	handleClick,
	blur,
	loading,
}) => {
	return (
		<AlertContainer
			onClick={handleClick}
			className={classList}
			style={{
				...types[type],
				...styleList,
				cursor: handleClick ? 'pointer' : '',
				pointerEvents: blur ? 'none' : '',
			}}
		>
			{loading ? (
				<LoadingContainer>
					<Loader />
				</LoadingContainer>
			) : null}
			{blur ? <BlurredArea>{children}</BlurredArea> : children}
		</AlertContainer>
	);
};

export default Alert;
