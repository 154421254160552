import React from 'react';
import {
	SingleOrderList,
	OrderListHeader,
	TrackID,
	Status,
	OrderMeta,
	Meta,
} from './order-card.style';

import {CURRENCY} from 'utils/constant';

const OrderCard = ({
	orderId,
	onClick,
	className,
	status,
	date,
	deliveryTime,
	amount,
}) => {
	return (
		<>
			<SingleOrderList onClick={onClick} className={className}>
				<OrderListHeader>
					<TrackID>
						Sipariş
						<span>#{orderId}</span>
					</TrackID>
					<Status>{status}</Status>
				</OrderListHeader>

				<OrderMeta>
					<Meta>
						Sipariş Tarihi : <span>{date}</span>
					</Meta>
					<Meta>
						Ziyaret Zamanı : <span>{deliveryTime}</span>
					</Meta>
					<Meta className="price">
						Toplam Tutar :{' '}
						<span>
							{CURRENCY}
							{amount}
						</span>
					</Meta>
				</OrderMeta>
			</SingleOrderList>
		</>
	);
};

export default OrderCard;
