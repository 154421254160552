import api from 'utils/axios';

export default function useAuth() {
	const login = (username, password) => {
		return api.post(
			'token',
			{
				username,
				password,
			},
			{
				skipAuthRefresh: true,
			},
		);
	};
	const register = ({firstname, lastname, email, password}) => {
		return api
			.post('register', {firstname, lastname, email, password})
			.then(res => {
				return res.data;
			});
	};
	const refresh = () => {
		const access_token = localStorage.getItem('access_token');
		return api.post('refresh', {access_token});
	};
	const logout = () => {
		return api.get('logout', {
			skipAuthRefresh: true,
		});
	};
	const resetPasswordLink = email => {
		return api.post('reset-password', {email}).then(res => {
			return res.data;
		});
	};
	const resetPassword = ({selector, validator, password}) => {
		return api
			.put('reset-password', {selector, validator, password})
			.then(res => {
				return res.data;
			});
	};
	const validateResetPasswordLink = ({selector, validator}) => {
		return api
			.post('reset-password/verify', {selector, validator})
			.then(res => {
				return res.data;
			});
	};
	return {
		login,
		register,
		logout,
		refresh,
		resetPasswordLink,
		resetPassword,
		validateResetPasswordLink,
	};
}
