import React, {useEffect, useState} from 'react';
import OrderReceivedWrapper, {
	OrderReceivedContainer,
	OrderInfo,
	OrderDetails,
	TotalAmount,
	BlockTitle,
	Text,
	InfoBlockWrapper,
	InfoBlock,
	ListItem,
	ListTitle,
	ListDes,
} from './checkout-result.style';
import {Link, useParams} from 'react-router-dom';
import usePayment from 'services/use-payment';
import Loader from 'components/loader/loader';
import {useCart} from '../../../contexts/cart/use-cart';
import {CURRENCY} from '../../../utils/constant';
import {LoaderContainer} from '../../../assets/styles/pages.style';
const CheckoutSuccessful = ({paymentData}) => {
	const [discount, setDiscount] = useState(null);
	useEffect(() => {
		let d = (
			parseFloat(paymentData.price) - parseFloat(paymentData.paidPrice)
		).toFixed(2);
		if (d > 0) {
			setDiscount(d);
		} else {
			setDiscount(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<OrderReceivedWrapper>
			<OrderReceivedContainer>
				<Link to="/">Anasayfaya geri dön</Link>

				<OrderInfo>
					<BlockTitle>Siparişiniz Alındı</BlockTitle>

					<Text>
						Siparişiniz için teşekkürler. İlgili ekibimiz belirlediğiniz zaman
						içerisinde sizi ziyaret edecektir.
					</Text>

					<InfoBlockWrapper>
						<InfoBlock>
							<Text bold className="title">
								Sipariş Numaranız
							</Text>
							<Text>{paymentData.payment_id}</Text>
						</InfoBlock>

						<InfoBlock>
							<Text bold className="title">
								Sipariş Tarihiniz
							</Text>
							<Text>{paymentData.created_at}</Text>
						</InfoBlock>

						<InfoBlock>
							<Text bold className="title">
								Toplam Tutar
							</Text>
							<Text>
								{paymentData.paidPrice} {CURRENCY}
							</Text>
						</InfoBlock>

						<InfoBlock>
							<Text bold className="title">
								Ödeme Methodunuz
							</Text>
							<Text>{paymentData.paymentType}</Text>
						</InfoBlock>
						<InfoBlock>
							<Text bold className="title">
								Ödeme Durumu
							</Text>
							<Text>{paymentData.status}</Text>
						</InfoBlock>
					</InfoBlockWrapper>
				</OrderInfo>

				<OrderDetails>
					<BlockTitle>Sipariş Detaylarınız</BlockTitle>

					<ListItem>
						<ListTitle>
							<Text bold>Toplam Hizmet Sayısı</Text>
						</ListTitle>
						<ListDes>
							<Text>{paymentData.items.length} Hizmet</Text>
						</ListDes>
					</ListItem>

					<ListItem>
						<ListTitle>
							<Text bold>Ziyaret Zamanı</Text>
						</ListTitle>
						<ListDes>
							<Text>
								{paymentData.delivery ? paymentData.delivery : 'Belirlenmemiş'}
							</Text>
						</ListDes>
					</ListItem>

					<ListItem>
						<ListTitle>
							<Text bold>Ziyaret Edilecek Adres</Text>
						</ListTitle>
						<ListDes>
							<Text>
								{paymentData.address.address} - {paymentData.address.province},{' '}
								{paymentData.address.district},{' '}
								{paymentData.address.neighbourhood}
							</Text>
						</ListDes>
					</ListItem>
					<ListItem>
						<ListTitle>
							<Text bold>İletişim Kurulacak Telefon Numarası</Text>
						</ListTitle>
						<ListDes>
							<Text>{paymentData.phone.phone}</Text>
						</ListDes>
					</ListItem>
				</OrderDetails>

				<TotalAmount>
					<BlockTitle>Toplam Tutar</BlockTitle>

					<ListItem>
						<ListTitle>
							<Text bold>Ara Toplam</Text>
						</ListTitle>
						<ListDes>
							<Text>
								{paymentData.price} {CURRENCY}
							</Text>
						</ListDes>
					</ListItem>
					{discount ? (
						<ListItem>
							<ListTitle>
								<Text bold>İndirim Tutarı</Text>
							</ListTitle>
							<ListDes>
								<Text>
									{discount} {CURRENCY}
								</Text>
							</ListDes>
						</ListItem>
					) : null}

					<ListItem>
						<ListTitle>
							<Text bold>Toplam</Text>
						</ListTitle>
						<ListDes>
							<Text>
								{paymentData.paidPrice} {CURRENCY}
							</Text>
						</ListDes>
					</ListItem>
				</TotalAmount>
			</OrderReceivedContainer>
		</OrderReceivedWrapper>
	);
};
const CheckoutError = props => {
	return (
		<OrderReceivedWrapper>
			<OrderReceivedContainer>
				<Link to="/">Anasayfaya geri dön</Link>
				<OrderInfo>
					<BlockTitle>{props.paymentError.message}</BlockTitle>

					<Text>{props.paymentError.errorCode ?? null}</Text>
				</OrderInfo>
			</OrderReceivedContainer>
		</OrderReceivedWrapper>
	);
};
const CheckoutResult = props => {
	const params = useParams();
	const paymentService = usePayment();

	const [loading, setLoading] = useState(true);
	const [paymentError, setPaymentError] = useState(null);
	const [paymentData, setPaymentData] = useState(null);

	const {clearCart, removeCoupon} = useCart();

	useEffect(() => {
		paymentService
			.getPaymentResult(params.token)
			.then(
				res => {
					if (res.success) {
						setPaymentData(res.data);
						setPaymentError(null);
						if (res.data.created) {
							clearCart();
							removeCoupon();
						}
					} else {
						setPaymentData(null);
						if (res.error_code) {
							setPaymentError({
								message: res.message,
								errorCode: res.data.error_code,
								groupCode: res.data.group_code,
							});
						} else {
							setPaymentError({
								message: res.message,
							});
						}
					}
				},
				err => {
					setPaymentData(null);
					setPaymentError({
						message: err.response.data.message,
					});
				},
			)
			.then(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			{loading ? (
				<LoaderContainer>
					<Loader />
				</LoaderContainer>
			) : paymentError ? (
				<CheckoutError paymentError={paymentError} />
			) : (
				<CheckoutSuccessful paymentData={paymentData} />
			)}
		</>
	);
};
export default CheckoutResult;
