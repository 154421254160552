import {SEO} from '../../components/seo/seo';
import Footer from '../../layouts/footer/footer';
import {
	Col12,
	HomeContainer,
	HomeRow,
	HomeSection,
} from '../../features/homepage/homepage.style';
import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';

const AboutPageTitle = styled.h2`
	text-align: center;
	padding: 2rem 0;
	color: ${themeGet('colors.black.900')};
`;

export const AboutPage = ({deviceType}) => {
	return (
		<>
			<SEO
				title="Hakkımızda"
				description="YıkamaYeri hakkında daha detaylı bilgiye sahip olabilmek için yıkamayeri.com'u ziyaret edebilirsiniz."
			/>
			<HomeSection>
				<HomeContainer>
					<HomeRow>
						<Col12>
							<AboutPageTitle>Yıkama Yeri - Hakkımızda</AboutPageTitle>
						</Col12>
						<Col12>
							<p>
								Yikamayeri , siz değerli müşterilerimize yıkama alanında en iyi
								hizmeti sunmak için faaliyete geçen bir kuruluştur. Türkiye’nin
								farklı illerinde halı yıkama ve birçok kategoride hizmet veren
								kurumları sitemizde toplamış bulunmaktayız. Bulunduğunuz konuma
								göre; fiyat karşılaştırması yaparak ve kullanıcı yorumlarına
								bakarak sizin için en uygun halı yıkamacıyı seçebilirsiniz.
								Böylelikle sitemiz size istediğiniz yıkamacıyı seçme fırsatı
								sunduğundan dolayı onlarca sitede vakit harcamanızı önlüyor. Ve
								kullanıcıların yorumları ile dilediğiniz halı yıkamacı hakkında
								deneyimlenmiş en doğru bilgilere ulaşabiliyorsunuz.
								Yikamayeri.com; halı temizleme, koltuk yıkama, perde yıkama ve
								daha birçok hizmet veren kurumların tüm hizmetlerin tam
								zamanında teslim prensibiyle yerine getirmesini sağlıyor.
							</p>
						</Col12>
					</HomeRow>
				</HomeContainer>
			</HomeSection>
			<Footer />
		</>
	);
};
