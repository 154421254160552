import {useState, useEffect} from 'react';
import {SEO} from 'components/seo/seo';
import {useParams} from 'react-router-dom';
import Blog from 'features/blog/blog';
import {BlogProvider} from 'contexts/blog/blog.provider';
import Loader from '../../components/loader/loader';
import useBlog from '../../services/use-blog';
import Footer from '../../layouts/footer/footer';
import {LoaderContainer} from '../../assets/styles/pages.style';

const BlogPage = () => {
	const {page} = useParams();
	const [loading, setLoading] = useState(true);
	const [posts, setPosts] = useState([]);
	const blogService = useBlog();
	useEffect(() => {
		blogService
			.posts(page ? page : 1)
			.then(
				res => {
					if (res.success && res.data && res.data.posts) {
						setPosts(res.data.posts);
					} else {
						setPosts([]);
					}
				},
				() => {
					setPosts([]);
				},
			)
			.then(() => setLoading(false));
	}, [blogService, page]);
	return (
		<>
			<SEO
				title={'Blog' + (page ? ' - Sayfa ' + page : '')}
				description="Türkiye'nin en güncel blog sitesi yıkamayeri.com. Güncel yıkama, temizleme alanındaki bloglara ulaşabilrmek için yıkamayeri.com'u ziyaret edebilirsiniz."
			/>
			<BlogProvider>
				{loading ? (
					<LoaderContainer>
						<Loader />
					</LoaderContainer>
				) : (
					<Blog posts={posts} />
				)}
				<Footer />
			</BlogProvider>
		</>
	);
};

export default BlogPage;
