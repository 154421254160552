import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';
import {Row as Rows, Col as Cols} from 'react-styled-flexboxgrid';

const SettingsForm = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const HeadingSection = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
`;

const Title = styled.h3`
	font-family: ${themeGet('fonts.heading', 'sans-serif')};
	font-size: ${themeGet('fontSizes.lg', '21')}px;
	font-weight: ${themeGet('fontWeights.semiBold', '600')};
	color: ${themeGet('colors.text.bold', '#0D1136')};
`;

const SettingsFormContent = styled.div`
	margin-bottom: 50px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const Row = styled(Rows)`
	margin-bottom: 40px;

	@media only screen and (min-width: 0em) and (max-width: 47.99em) {
		margin-bottom: 30px;
	}
`;

const Col = styled(Cols)`
	margin-top: 10px;
	@media only screen and (min-width: 0em) and (max-width: 47.99em) {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const ButtonGroup = styled.div`
	display: flex;
	flex-direction: column;

	.radioGroup {
		flex-grow: 1;
		justify-content: space-between;

		label {
			margin-top: 0;
			flex: calc(33.333333333% - 10px);
			max-width: calc(33.333333333% - 10px);
			margin-bottom: 15px;

			&:nth-child(3n) {
				margin-right: 0;
			}

			@media (max-width: 700px) {
				flex: calc(50% - 10px);
				max-width: calc(50% - 10px);

				&:nth-child(3n) {
					margin-right: 15px;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}
			}

			@media (max-width: 480px) {
				flex: 100%;
				max-width: 100%;
				margin-right: 0;

				&:nth-child(3n) {
					margin-right: 0;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}

	.add-button {
		flex: calc(33.3333333333% - 10px);
		max-width: calc(33.3333333333% - 10px);
		flex-shrink: 0;
		height: auto;
		min-height: 77px;
		border: 1px dashed ${themeGet('colors.gray.500', '#f1f1f1')};
		margin-bottom: 15px;
		margin-left: 0;
		margin-right: auto;
		&:hover {
			border-color: ${themeGet('colors.primary.regular', '#009e7f')};
		}

		@media (max-width: 700px) {
			flex: calc(50% - 10px);
			max-width: calc(50% - 10px);
		}

		@media (max-width: 480px) {
			flex: 100%;
			max-width: 100%;
		}
	}
`;
export {
	SettingsForm,
	HeadingSection,
	Title,
	SettingsFormContent,
	Col,
	Row,
	ButtonGroup,
};
